import React, { useEffect, useRef, useState } from "react";
import minusPVR from "../../../assets/food/minus.svg";
import plusPVR from "../../../assets/food/plus.svg";
import plusINOX from "../../../assets/food/inoxPlus.svg";
import minusINOX from "../../../assets/food/inoxMinus.svg";
import SweetAlertUtility from "./SweetAlertUtility";


const AddButton = ({
  isCart,
  data,
  addedItems,
  cartIndex,
  cartItemIds,
  updateCount,
  handleCustomizfoShow,
  handleUpgradeModalShow,
  upgradeModal,
  setTempCustomizations,
  removeFromCart,
  setAddedItems,
  cartStyle,
  cartBtn,
  totalItems,
}) => {
  const theme = localStorage.getItem("theme");
  const [btnQuantity, setBtnQuantity] = useState(0);
  const [alertDetails, setAlertDetails] = useState({ show: false, type: '', title: '', message: '' });
  const itemInCart = useRef(0);


  useEffect(() => {
    itemInCart.current = addedItems?.length
      ? addedItems?.reduce((quantity, item) => {
        if (item?.ItemID === data.id) {
          return quantity + item.quantity;
        }
        return quantity + 0;
      }, 0)
      : 0;

    const quantity = isCart ? data.quantity : itemInCart.current;
    setBtnQuantity(quantity);
  }, [addedItems]);

  const handleIncrement = () => {
    if (upgradeModal && location.pathname.indexOf("/fnb-checkout") !== -1) {
      handleUpgradeModalShow(data)
    }
    if (data.addOn) {
      handleCustomizfoShow(data);
    } else {
      if (isCart && data?.AddOnItem?.length > 0) {
        setAddedItems((prevItems) => {
          let stateCopy = [...prevItems];
          stateCopy[cartIndex].quantity++;
          stateCopy[cartIndex].AddOnItem.forEach((item) => item.quantity++);

          let totalQty = stateCopy.reduce((total, item) => {
            let addOnTotal = item.AddOnItem
              ? item.AddOnItem.reduce((sum, addOn) => sum + addOn.quantity, 0)
              : 0;
            return total + item.quantity + addOnTotal;
          }, 0);

          if (totalQty > 10) {
            stateCopy[cartIndex].quantity--;
            stateCopy[cartIndex].AddOnItem.forEach((item) => item.quantity--);

            const messages = JSON.parse(localStorage.getItem("messages"));
            sweetAlertError(messages?.itemQuantityLimit || "Sorry, you cannot add more than 10 items in one session!");
            return prevItems;
          }
          return stateCopy;
        });
        return;
      }

      if (totalItems >= 10) {
        const messages = JSON.parse(localStorage.getItem("messages"));
        sweetAlertError(messages.itemQuantityLimit || "Sorry, you cannot add more than 10 items in one session!");
        return;
      }

      if (btnQuantity < 10) {
        setBtnQuantity((prev) => prev + 1);

        if (isCart) {
          setAddedItems((prevItems) => {
            let stateCopy = [...prevItems];
            stateCopy[cartIndex].quantity++;
            stateCopy[cartIndex].AddOnItem.forEach((item) => item.quantity++);
            return stateCopy;
          });
        } else if (data.addOn) {
          handleCustomizfoShow(data);
        } else {
          updateCount(data);
        }
      } else {
        sweetAlertError("You cannot add more than 10 of the same item");
      }
    }
  };


  const handleDecrement = () => {
    if (isCart) {
      setBtnQuantity((prev) => {
        return prev - 1;
      });
      setAddedItems((prevItem) => {
        let stateCopy = [...prevItem];
        stateCopy[cartIndex].quantity--;
        let addOns = [...stateCopy[cartIndex].AddOnItem];
        addOns.map((item) => {
          item.quantity--;
        });
        stateCopy[cartIndex].AddOnItem = addOns;
        if (stateCopy[cartIndex].quantity <= 0) {
          stateCopy.splice(cartIndex, 1);
        }
        return stateCopy;
      });
      return;
    }

    removeFromCart(data.id || data.ItemID);
  };

  const sweetAlertSuccess = (msg) => {
    setAlertDetails({
      show: true,
      type: "success",
      title: "Success!",
      message: msg,
    });
  };

  const sweetAlertError = (msg) => {
    setAlertDetails({
      show: true,
      type: "error",
      title: "Error!",
      message: msg,
    });
  };

  const hideAlert = () => {
    setAlertDetails({ show: false });
  };

  return (
    <>
      {btnQuantity > 0 ? (
        <div style={cartStyle} className="fb-c-div">
          <button className="fb-c-btn" onClick={handleDecrement}>
            <img
              src={
                cartBtn ? minusPVR : theme === "pvrTheme" ? minusPVR : minusINOX
              }
              alt="decrement"
            />
          </button>
          <span className="fb-c-span">{btnQuantity}</span>
          <button className="fb-c-btn" onClick={handleIncrement}>
            <img
              src={
                cartBtn ? plusPVR : theme === "pvrTheme" ? plusPVR : plusINOX
              }
              alt="increment"
            />
          </button>
        </div>
      ) : (
        <button className="fndAddBtn" onClick={handleIncrement}>
          Add
        </button>
      )}

      <SweetAlertUtility type={alertDetails.type} title={alertDetails.title} message={alertDetails.message} show={alertDetails.show} onConfirm={hideAlert} />
    </>
  );
};

export default AddButton;
