import React, { useEffect, useRef, useState } from 'react'

import RecommendeCard from './component/RecommendeCard';
import popcornImg from "../../assets/food/popcorn2.svg";
import vegIcon from "../../assets/food/veg.svg";
import traDicPVR from '../../assets/food/tras-dis.svg'
import traDicINOX from '../../assets/food/checkoutcoupen.svg'
import rightArr from '../../assets/food/bigright-arrow.svg'
import closeIcon from "../../assets/food/close.svg";
import dassadLine from "../../assets/food/dass-line.svg";
import rightArrow from "../../assets/food/rightArr.svg";
import { json, Link, useLocation, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import FbHeader from './component/FbHeader';
// import UpgradeCart from './component/UpgradeCart';
import { Modal } from 'react-bootstrap';
import Seat from '../../assets/food/aircraft.svg'
import SeatInox from '../../assets/food/aircraftinox.svg'
import scrollIcon from "../../assets/food/scrollarrow.svg";
import seactNotFound from '../../assets/food/seatnotfound.svg'
import expandIcon from "../../assets/food/expandSmall.svg";
import checkPVR from '../../assets/food/check.svg'
import checkINOX from '../../assets/food/checkInox.svg'
import noImg from "../../assets/food/noimage250.png";
import nonvegIcon from "../../assets/food/nonveg.svg";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import MobilePayment from '../mobile-views/mobile-payment/MobilePayment';
import leftLinePVR from "../../assets/food/leftLine.svg";
import rightLinePVR from "../../assets/food/rightLine.svg";
import rightLineINOX from "../../assets/food/leftLine-INOX.svg";
import leftLineINOX from "../../assets/food/rightLine-INOX.svg";
import { setGrandTotalamount } from 'src/redux/actions/gradTotalAction';
import * as appConstants from "../../constants/index";
import SweetAlert from 'react-bootstrap-sweetalert';
import SweetAlertUtility from './component/SweetAlertUtility';
import FnbCheckoutSkeleton from 'src/skeletons/FnbCheckoutSkeleton';
import Navbar from '../common/navbar/Navbar';
import { add, format, isValid, parse } from 'date-fns';
import { getResponsiveClasses } from './component/ResponsiveClasses';
import { setLoginViewMode } from 'src/redux/actions/loginViewAction';
import { Dialog } from 'primereact/dialog';
import Login from '../common/login/Login';
import Footer from '../common/footer/Footer';
import AddButton from './component/AddButton';
import UpgradeCart from './component/UpgradeCart';
import "swiper/css";

const Checkout = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	// const [addedItems, setAddedItems] = useState({});
	const [selected, setSelected] = useState(() => {
		return localStorage.getItem("selectRadioClick") || undefined;
	});
	const [loading, setLoading] = useState(false);
	const [loadingSeat, setLoadingSeat] = useState(false);
	const [paymentLoading, setPaymentLoading] = useState(false);
	const [payDetailLoading, setPayDetailLoading] = useState(false);
	const [deliverySeat, setDeliverySeat] = useState();
	const [showYourSeatModal, setShowYourSeatModal] = useState(false);
	const [showYourSeatManual, setShowYourSeatManual] = useState(false);
	const [showUpgradeModal, setShowUpgradeModal] = useState(false);
	const [expand, setExpand] = useState(false);
	const [removeApplied, setRemoveApplied] = useState(true);
	const [selectedSeat, setSelectedSeat] = useState(null);
	// const [confirmedSeats, setConfirmedSeats] = useState([]);
	const [apiCalled, setApiCalled] = useState(false);
	// const [currentTime, setCurrentTime] = useState(new Date());
	const [seatDetailData, setSeatDetailData] = useState(null);
	const [errMsg, setErrMsg] = useState(false);
	const [userSelectedFood, setUserSelectedFood] = useState();
	// const [seatDetailsState, setSeatDetailsState] = useState(location?.state?.seatdetails || null);
	const [deliveryConfirmSeat, setDeliveryConfirmSeat] = useState(null);
	const [paymentOptions, setpaymentOptions] = useState(1);
	const [fnbPaymentDetailsRes, setFnbPaymentDetailsAPIRes] = useState([]);
	const [showSeatInfo, setShowSeatInfo] = useState(true);
	const [alertDetails, setAlertDetails] = useState({ show: false, type: '', title: '', message: '' });
	const [finalTicket, setFinalTicket] = useState(null);
	const [isBadTransaction, setIsBadTransaction] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [errorMsg, setErrorMsg] = useState(null);
	const [formattedShowTime, setFormattedShowTime] = useState(null);
	const [formattedEndTime, setFormattedEndTime] = useState(null);
	const [upgradeData, setUpgradeData] = useState([]);
	const [selectedItem, setSelectedItem] = useState(null);
	const [upgradeItem, setUpgradeItem] = useState(null);
	const [activeIndex, setActiveIndex] = useState(0);

	const appliedVocher = JSON.parse(localStorage.getItem("AppliedOfferData"));
	const selectedTheater = JSON.parse(localStorage.getItem("selectedTheater"));
	const seatdetails = JSON.parse(localStorage.getItem("seatdetails"));
	const screenType = localStorage.getItem("Experience") || "";
	// const whereIsUser = localStorage.getItem("TransactionType");
	const discAmt = parseFloat(appliedVocher?.resOffer?.output?.di);
	const finalAmount = parseFloat(appliedVocher?.resOffer?.output?.dp);

	const theme = localStorage.getItem("theme");
	const userInfoData = JSON.parse(localStorage.getItem("loginInfo")) || "";
	const authToken = "Bearer " + (localStorage.getItem("token") || " ");
	const theatreId = localStorage.getItem("TheatreId");
	const bookType = localStorage.getItem("booking_type") || "FOOD";
	const transId = localStorage.getItem("transid") || 0;
	const bookingId = localStorage.getItem("bookingid") || 0;
	const loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
	const FoodPickupDateTime = localStorage.getItem("FoodPickupDateTime")
	const TransactionType = localStorage.getItem("TransactionType");
	// const storedDataString = localStorage.getItem("getAllFnbData");
	const storedConfirmSeat = localStorage.getItem("DeliverytoSeatData");
	const seatsArray = seatDetailData?.[0]?.seats ? seatDetailData?.[0]?.seats.split(",") : [];

	const [addedItems, setAddedItems] = useState([]);
	const isInitialLoad = useRef(true);
	const cartItemIds = useRef([]);
	const scrollUpgradeRef = useRef(null);
	const loginVewMode = useSelector((state) => state.loginViewMode.value);


	// Extract year, month, and day
	const todayDate = new Date();
	const year = todayDate.getFullYear();
	const month = String(todayDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
	const day = String(todayDate.getDate()).padStart(2, "0");
	const formattedDate = `${year}-${month}-${day}`;

	// Format the time as "hh:mm:ss"
	const currentTime = new Date();
	const hours = String(currentTime.getHours()).padStart(2, "0");
	const minutes = String(currentTime.getMinutes()).padStart(2, "0");
	const seconds = String(currentTime.getSeconds()).padStart(2, "0");
	const formattedTime = `${hours}:${minutes}:00`;

	const OnlyFBDate = FoodPickupDateTime ? FoodPickupDateTime.split(",") : null;

	const FinalBookingDate = OnlyFBDate ? format(parse(OnlyFBDate[0], "dd MMM yy", new Date()), "yyyy-MM-dd") : formattedDate.toString();
	const FinalBookingTime = OnlyFBDate ? format(parse(OnlyFBDate[1].trim(), "hh:mm a", new Date()), "HH:mm:ss") : formattedTime.toString();
	const PickupBookingDate = OnlyFBDate ? format(parse(OnlyFBDate[0], "dd MMM yy", new Date()), "dd-MMM-yyyy") : format(new Date(), "dd-MMM-yyyy");
	const PickupBookingTime = OnlyFBDate ? format(parse(OnlyFBDate[1].trim(), "hh:mm a", new Date()), "hh:mm a") : format(new Date(), "hh:mm a");

	localStorage.removeItem('targetTime');
	// localStorage.removeItem("cartItems");

	const { currentClasses, getWindowWidth } = getResponsiveClasses();

	const handleYourSeatModalShow = () => {
		setShowYourSeatModal(true)
	};


	const [isLeftArrowVisibleUpgrade, setIsLeftArrowVisibleUpgrade] = useState(false);
	const [isRightArrowVisibleUpgrade, setIsRightArrowVisibleUpgrade] = useState(true);

	const handleYourSeatManualShow = () => setShowYourSeatManual(true);

	const handleUpgradeModalShow = (item) => {
		setSelectedItem(item);
		const cartItems = JSON.parse(localStorage.getItem("cartItems") || "[]");
		const upgradeItem = cartItems.find((cartItem) => cartItem.ItemID === item.upgradeParentId);
		setUpgradeItem(upgradeItem || null);
		setShowUpgradeModal(true);
	};
	const handleYourSeatModalClose = () => {
		// if (!renderSeatInfo()) {
		// 	setSelected("radio2");
		// }
		setShowYourSeatModal(false);
	}

	const handleUpgradeModalClose = () => setShowUpgradeModal(false);

	const handleYourSeatManualClose = () => {
		// if (renderSeatInfo() === "") setSelected("radio2");
		setShowYourSeatManual(false);
	}
	const handleConfirm = (data) => {
		if (selectedSeat !== null && data.length > 0) {
			const confirmedSeatData = [{ filmId: data?.[0]?.filmId, audi: data?.[0]?.audi, seatno: selectedSeat }];
			localStorage.setItem(
				"DeliverytoSeatData",
				JSON.stringify(confirmedSeatData)
			);
		} else {
			setErrMsg(true);
			setShowYourSeatModal(true);
			return;
		}
		setShowYourSeatModal(false);
		localStorage.removeItem("seatdetails");
	};

	useEffect(() => {
		if (!loginInfo?.access?.token) {
			dispatch(setLoginViewMode({ value: true }));
		}
	}, [loginInfo?.access?.token]);

	const updateArrowVisibility = (container, type) => {
		const scrollLeft = container.scrollLeft;
		const maxScrollLeft = container.scrollWidth - container.clientWidth;

		const isLeftVisible = scrollLeft > 0;
		const isRightVisible = scrollLeft < maxScrollLeft;

		setIsLeftArrowVisibleUpgrade(isLeftVisible);
		setIsRightArrowVisibleUpgrade(isRightVisible);
	};

	const handleUpgradeScroll = (type) => {
		const container = scrollUpgradeRef?.current;
		if (container) {
			container.scrollBy({
				left: type === "left" ? -560 : type === "right" ? 560 : 0,
				behavior: "smooth",
			});

			setTimeout(() => {
				updateArrowVisibility(container, "scroll");
			}, 500);
		}
	};

	// useEffect(() => {
	// 	const savedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
	// 	if (Array.isArray(savedCartItems)) {
	// 		setAddedItems(savedCartItems);

	// 	} else {
	// 		setAddedItems([]);
	// 	}
	// }, []);

	useEffect(() => {
		const savedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
		if (Array.isArray(savedCartItems) && savedCartItems.length > 0) {
			setAddedItems(savedCartItems);
		} else {
			setAddedItems([]);
		}
	}, []);

	useEffect(() => {
		if (isInitialLoad.current) {
			isInitialLoad.current = false;
			return;
		}

		cartItemIds.current = addedItems.map((item) => item.ItemID);
		const combinedUpgrade = addedItems.flatMap((item) => item.upgradeItems || []);
		const hasUpgradeItems = addedItems.some((item) => Array.isArray(item.upgradeItems) && item.upgradeItems.length > 0);

		setUpgradeData(hasUpgradeItems ? combinedUpgrade : []);
		localStorage.setItem("cartItems", JSON.stringify(addedItems));

		if (addedItems.length === 0) {
			navigate("/select-food");
		}
	}, [addedItems, navigate]);


	useEffect(() => {
		if (storedConfirmSeat) {
			setDeliveryConfirmSeat(JSON.parse(storedConfirmSeat));
		}
	}, [storedConfirmSeat]);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	const handleSelected = () => {
		if (screenType && screenType !== "N" && TransactionType === "OnlyFB" && seatdetails?.seatrow) {
			setSelected("radio1");
			return;
		} else if (TransactionType === "OnlyFB") {
			if (seatdetails?.screenName === undefined) {
				localStorage.removeItem("seatdetails");
			}
			setSelected("radio2");
			return;
		}

		// Check seat availability in different sources (seatdetails, seatsArray, deliveryConfirmSeat)
		const seatna = seatdetails?.seatrow && seatdetails?.seatno
			? `${seatdetails.seatrow}${seatdetails.seatno}`
			: seatsArray.length > 0 && seatDetailData?.[0]?.seats
				? seatDetailData?.[0]?.seats
				: deliveryConfirmSeat?.[0]?.seatno || "";

		// Set radio button selection based on the seat availability
		if (seatna && !selectedTheater?.fbDeliveryOnSeat) {
			setSelected("radio1");
		}
		if (selected === "radio2") {
			localStorage.removeItem("seatdetails");
			setSelected("radio2");
		}
	};

	useEffect(() => {
		const seatInfo = renderSeatInfo() || "";
		const selectRadioClick = localStorage.getItem("selectRadioClick");
		setSelected(selectRadioClick) || setSelected();
		if (window.matchMedia("(max-width: 1024px)").matches) {
			if (!getWindowWidth && (!selectedTheater?.fbDeliveryOnSeat && seatInfo === "" && TransactionType !== "OnlyFB" && selected === undefined)) {
				setSelected();
				localStorage.removeItem("selectRadioClick");
			} else if (TransactionType) {
				if (!selectedTheater?.fbDeliveryOnSeat === false) { setSelected("radio2"); }
				handleSelected();
			}
		} else {
			setSelected("radio2");
		}

	}, [seatDetailData, seatdetails, deliveryConfirmSeat, TransactionType]);

	const fnbPaymentDetailsAPI = async (type, totalPrice, fnAllData) => {
		if (type === "offers") setPayDetailLoading(true);
		const seatna = selected === "radio2"
			? ""
			: (seatdetails?.seatrow && seatdetails?.seatno)
				? `${seatdetails.seatrow}${seatdetails.seatno}`
				: (deliveryConfirmSeat?.length > 0 ? deliveryConfirmSeat[0]?.seatno : "") || "";

		const filmId = selected === "radio2"
			? ""
			: seatdetails
				? seatdetails?.filmId
				: (deliveryConfirmSeat?.length > 0 ? deliveryConfirmSeat[0]?.filmId : "") || "";

		const formatDate = seatDetailData?.[0]?.showTime ? format(new Date(seatDetailData?.[0]?.showTime), "yyyy-MM-dd") : "";
		const formatTime = seatDetailData?.[0]?.showTime ? format(new Date(seatDetailData?.[0]?.showTime), "hh:mm a") : "";
		const pickUpDate = seatdetails
			? seatdetails?.pickUpDate
			: (seatDetailData?.length > 0 ? `${formatDate}T${formatTime}` : "") || "";


		const screenname = selected === "radio2"
			? ""
			: seatdetails?.screenName
				? `${seatdetails.screenName}`
				: (deliveryConfirmSeat?.length > 0 ? deliveryConfirmSeat[0]?.audi : "") || "";

		const movieImageUrl = seatdetails?.seatrow
			? seatdetails?.movieImageUrl
			: seatDetailData
				? seatDetailData?.[0]?.filmUrl
				: "";

		const currentDateTime = `${FinalBookingDate} ${FinalBookingTime}`;
		const maxPreparationTime = addedItems.reduce((max, item) => {
			return item.preparationTime > max ? item.preparationTime : max;
		}, 0);

		try {
			const response = await axios.post(
				`${appConstants.base_url}v1/fnb/fnbSaveFoodDetails`,
				{
					qr: seatdetails?.fnb_qr === "Yes" ? true : false || false,
					bookType: bookType,
					ccode: theatreId,
					screenType: seatdetails?.screenType || "N",
					audi: screenname || "",
					deliveryMode: selected === "radio1" ? "ONSEAT" : "PICKUP" || "PICKUP",
					showTime: currentDateTime,
					pickUpDate: pickUpDate || "",
					seatNumber: seatna || "",
					screenId: seatdetails?.screenId || "",
					filmId: filmId || "",
					transId: transId,
					foodType: TransactionType === "InCinemaFB" ? "ONSEAT" : "ADDFOOD",
					food: fnAllData,
					preparationTime: maxPreparationTime > 0 ? maxPreparationTime : 10,
					bookingId: bookingId
				},
				{
					headers: {
						Authorization: authToken,
						"Content-Type": "application/json",
						chain: theme === "inoxTheme" ? "INOX" : "PVR",
						appVersion: appConstants.av,
						platform: appConstants.pt,
						country: appConstants.cname,
					},
				}
			);
			// setCheckoutLoading(false);
			if (response.data.result === "success") {
				localStorage.setItem("transid", response.data.output.transId);
				localStorage.setItem("bookingid", response.data.output.bookingId);
				localStorage.setItem("en_bid", response.data.output.en_bid);
				localStorage.removeItem("fnbSaveFoodDetailsError");
				if (type === "offers") navigate("/offers");
				else {
					if (appliedVocher?.resOffer?.output?.p && finalAmount === 0 && !isNaN(finalAmount)) {
						await getFinalTicket();
					} else {
						dispatch(
							setGrandTotalamount({
								cutPrice: finalAmount || finalAmount === 0 && !isNaN(finalAmount)
									? totalPrice
									: 0,
								finalPrice: finalAmount || finalAmount === 0 && !isNaN(finalAmount)
									? finalAmount
									: totalPrice,
							})
						);

						if (appConstants.pt == "MSITE") {
							navigate(`/m.payment/:cinemaName`, {
								state: {
									bookingId: bookingId,
									transId: transId,
									bookType: "FOOD",
									paymentOptions: paymentOptions,
								},
							});
						} else {
							navigate(`/payment/qr`, {
								state: {
									bookingId: bookingId,
									transId: transId,
									bookType: "FOOD",
									paymentOptions: paymentOptions,
								},
							});
						}
					}
				}


			} else {
				sweetAlertError(response.data.msg);
				localStorage.setItem("fnbSaveFoodDetailsError", response.data.msg);
			}
		} catch (error) {
			console.error("Error fetching FNB payment details:", error);
			localStorage.setItem("fnbSaveFoodDetailsError", error);
		} finally {
			setPayDetailLoading(false);
		}
	};

	const seatDetailsAPI = async () => {
		setLoadingSeat(true);
		// const formData = {
		// 	upcomingMovies: false  //`2024-09-16 19:45:00.000000`
		// };

		try {
			const response = await axios.post(
				`${appConstants.base_url}v1/fnb/getExistingBookings?upcomingMovies=false`,
				{},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: authToken,
						chain: localStorage.getItem("theme") === "inoxTheme" ? "INOX" : "PVR",
						appVersion: appConstants.av,
						platform: appConstants.pt,
						country: appConstants.cname,
					},
				}
			);
			if (response.data.result === "success") {
				setSeatDetailData(response.data.output);
				formattedShowEndTime(response.data.output);

				if (response.data.output.length > 0) {
					const matchingTheater = response.data.output.find((item) => item?.theaterId === theatreId);
					if (matchingTheater) {
						handleYourSeatModalShow();
					} else {
						handleYourSeatManualShow();
					}
				} else {
					handleYourSeatManualShow();
				}
			} else {
				handleYourSeatManualShow();
			}
		} catch (error) {
			sweetAlertError("Something went wrong!");
			console.error("Failed to fetch seat details:", error);
		} finally {
			setLoadingSeat(false);
		}
	};

	const handleImageError = (e) => {
		e.target.src = noImg; // Set fallback image if the original image fails
	};

	const handleRadioTypeClick = (id) => {
		if (id === "radio2") {
			localStorage.removeItem("seatdetails");
			setShowSeatInfo(false);
			setSelected("radio2");
		} else {
			setShowSeatInfo(true);
		}
		localStorage.setItem("selectRadioClick", id);
		setSelected(id);
	};
	const handleExpand = () => {
		setExpand(!expand);
	};

	const handleRemoveOfferApplied = async () => {
		try {
			setLoading(true);

			const voutypedata = appliedVocher?.vouPayType?.toLowerCase() === "auto" ? "AUTOVOUCHER" : appliedVocher?.vouPayType?.toLowerCase() === "binbased" ? "BANKOFFER" : appliedVocher?.vouPayType?.toLowerCase() === "promocode" ? "PROMOCODE" : "";
			const unlockData = appliedVocher?.vouPayType?.toLowerCase() === "auto" ? false : "";
			const cardData = appliedVocher?.vouPayType?.toLowerCase() === "binbased" ? cardDetailsData?.cardNumber : "";
			const cardTypeData = appliedVocher?.vouPayType?.toLowerCase() === "binbased" ? cardDetailsData?.cardType : "";
			const vouIdData = appliedVocher?.vouPayType?.toLowerCase() === "promocode" ? 0 : appliedVocher?.vouId;
			const qrsData = TransactionType === "InCinemaFB" ? true : false;
			const fnb_qrData = seatdetails?.fnb_qr === "Yes" ? "YES" : TransactionType === "InCinemaFB" ? "YES" : "NO";

			const formData = {
				ccode: theatreId || "",
				bookingId: bookingId,
				transId: transId,
				bookType: "FOOD",
				couponId: vouIdData,
				coupon: appliedVocher?.vouId,
				couponType: voutypedata || "",
				qr: fnb_qrData,
				card: cardData || "",
				cardType: cardTypeData || "",
				mobile: userInfoData?.data?.mob || '',
				otp: "",
				qrs: qrsData
			};
			const response = await axios.post(
				`${appConstants.base_url}v1/payment/coupon/remove`,
				formData,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: authToken,
						chain: localStorage.getItem("theme") === "inoxTheme" ? "INOX" : "PVR",
						appVersion: appConstants.av,
						platform: appConstants.pt,//"WEBSITE", //,
						country: appConstants.cname
					},
				}
			);

			if (response.data?.result === "success") {
				setRemoveApplied(false);
				localStorage.removeItem("AppliedOfferData");

				const existGetAllFnbData = JSON.parse(localStorage.getItem("getAllFnbData"));

				delete existGetAllFnbData.AppliedOfferDataNew;

				localStorage.setItem("getAllFnbData", JSON.stringify(existGetAllFnbData));

				// sweetAlertSuccess(response?.data?.msg);
			} else {
				sweetAlertError(response?.data?.msg || "Failed to remove offer.");
			}
		} catch (error) {
			const errorMsg =
				error.response?.data?.message ||
				error.message ||
				"Something went wrong!";
			sweetAlertError(errorMsg);
			console.error("Failed to remove offer:", errorMsg);
		} finally {
			setLoading(false);
		}
	};

	const handleSeatClick = (seat) => {
		setSelectedSeat(seat);
		setErrMsg(false);
	};

	const renderSeatInfo = () => {

		if (seatsArray.length > 0 || storedConfirmSeat) {
			if (seatdetails) {
				return `${seatdetails.screenName}, ${seatdetails.seatrow}${seatdetails.seatno}`;
			} else {
				return (deliveryConfirmSeat && deliveryConfirmSeat.length > 0) && deliveryConfirmSeat?.map((confirmedItem) => `${confirmedItem?.audi}, ${confirmedItem?.seatno}`)?.join(", ");
			}

		}

		return seatdetails && (seatdetails.screenName || seatdetails.seatrow)
			? `${seatdetails?.screenName && `${seatdetails?.screenName},`} ${seatdetails?.seatrow}${seatdetails?.seatno}`
			: "";
	};

	const handleClick = async () => {
		handleRadioTypeClick("radio1");
		setErrMsg(false);
		try {
			await seatDetailsAPI();


		} catch (error) {
			// Hide loader if there's an error
			setLoading(false);

			console.error("Error fetching seat details:", error);
			// Handle any errors that may occur while fetching seat details
		}
	};

	const getFinalTicket = async () => {
		try {

			const postData = {
				qr: localStorage.getItem("isQR") && isMobile ? "YES" : "NO",
				bookType: "FOOD",
				transId: transId,
				bookingId: bookingId,
				flowType: "FOOD",
				ccode: localStorage.getItem("theaterId"),
			};

			const response = await axios.post(
				`${appConstants.base_url}v1/booking/ticketing/completed`,
				postData,
				{
					headers: {
						Authorization: authToken || "",
						chain: localStorage.getItem("theme") === "inoxTheme" ? "INOX" : "PVR",
						city: localStorage.getItem("city"),
						appVersion: appConstants.av,
						platform: appConstants.pt,
						country: appConstants.cname,
					},
				}
			);

			const dt = response.data;
			if (dt.result === "success") {
				const ndt = dt.output;
				setFinalTicket(ndt);
				localStorage.setItem("orderId", ndt.bookingId);
				navigate(`/order-received`);

				localStorage.removeItem("transId");
				localStorage.removeItem("booking_type");
				localStorage.removeItem("isQR");
			} else {
				setIsBadTransaction(true);
				setShowAlert(true);
				setErrorMsg(dt);
			}
		} catch (error) {
			const err = error?.response;
			if (err?.status === 403) {
				refraceTokenService();
				console.log(
					"Forbidden: You do not have permission to access this resource."
				);
			} else {
				console.error("Error:", error?.message);
			}

			setIsBadTransaction(true);
			setShowAlert(true);
			setErrorMsg("There is a network failure.");
		} finally {
			// setLoading(false);
		}
	};

	const paymentHandle = async () => {
		try {
			if (!selected) {
				const messages = JSON.parse(localStorage.getItem("messages"));
				sweetAlertError(messages.deliveryLocation);
				return
			}
			setPaymentLoading(true);

			const itemIDs = addedItems.map(item => item.ItemID);

			const transformedFoodItems = addedItems.map(item => {
				const validParentId = item.parentUpsellId && itemIDs.includes(item.parentUpsellId) ? item.parentUpsellId : "";
				return {
					itemId: item.ItemID,
					name: item.ItemName,
					quantity: item.quantity,
					price: item.price || 0,
					veg: item.IsVeg,
					parentId: validParentId,
					foodType: parseInt(item.foodType) || 1,
					itemType: validParentId ? 1 : 2,
					// preparationTime: item.preparationTime,
					addons: item.AddOnItem
						? item.AddOnItem.map(addon => ({
							itemId: addon.addOnItemId,
							name: addon.name,
							price: addon.price || 0,
							quantity: addon.quantity,
						}))
						: [],
				};
			});

			const cartData = transformedFoodItems.map(item => ({
				itemId: item.itemId,
				name: item.name,
				quantity: item.quantity,
				price: item.price,
				veg: item.veg ? true : false,
				parentId: item.parentId,
				foodType: item.foodType,
				itemType: item.parentId ? 1 : 0,
				// preparationTime: item.preparationTime,
				addons: item.addons,
			}));

			if (cartData.length > 0) {
				const data = {
					fnAllData: cartData,
					FinalBookingDate: FinalBookingDate,
					FinalBookingTime: FinalBookingTime,
				};
				localStorage.setItem("getAllFnbData", JSON.stringify(data));
			} else {
				const messages = JSON.parse(localStorage.getItem("messages"));
				sweetAlertError(messages.emptyCart || "Your cart is empty.");
			}

			const totalPrice = addedItems.reduce((total, item) => {
				const totalPrice = parseInt(item?.price) * item?.quantity || 0;
				const addonPrice = item?.AddOnItem?.reduce((addonTotal, addon) => {
					return addonTotal + (addon?.price || 0) * addon?.quantity;
				}, 0);
				return total + totalPrice + addonPrice;
			}, 0);

			await fnbPaymentDetailsAPI("", (totalPrice / 100).toFixed(2), cartData);
		} catch (error) {
			console.error("Error processing payment:", error);
		} finally {
			setPaymentLoading(false);
		}
	};

	const sweetAlertSuccess = (msg) => {
		setAlertDetails({
			show: true,
			type: "success",
			title: "Success!",
			message: msg,
		});
	};

	const sweetAlertError = (msg) => {
		setAlertDetails({
			show: true,
			type: "error",
			title: "Error!",
			message: msg,

		});
	};

	const hideAlert = () => {
		setAlertDetails({ show: false });
	}
	const formattedShowEndTime = (data) => {
		if (data.length > 0) {
			const showTime = parse(data?.[0]?.showTime, "yyyy-MM-dd HH:mm:ss.S", new Date());
			const endTime = parse(data?.[0]?.endTime, "yyyy-MM-dd HH:mm:ss.S", new Date());
			const formattedShowTime = showTime && isValid(showTime) ? format(showTime, "hh:mm a") : "-";
			const formattedEndTime = endTime && isValid(endTime) ? format(endTime, "hh:mm a") : "-";
			setFormattedShowTime(formattedShowTime);
			setFormattedEndTime(formattedEndTime);
		}
	}

	const cartTransprantBtn = {
		color: "black",
		backgroundColor: "transparent",
		border: "1px solid #EDE8E9",
	};

	// Helper function to all items count
	let countAllItems = (() => {
		let i = 0;
		addedItems &&
			addedItems.forEach((item) => {
				i = i + item.quantity;
				if (item?.AddOnItem) {
					item?.AddOnItem.forEach((addon) => (i = i + addon.quantity));
				}
			});
		return i;
	})();

	const removeFromCart = (id) => {
		const numOfItems = cartItemIds.current.filter((itemId) => itemId === id);

		if (numOfItems.length === 1) {
			setAddedItems((prevItem) => {
				let stateCopy = [...prevItem];
				let removeInd = -1;
				stateCopy.map((item, index) => {
					if (item.ItemID === id) {
						item.quantity--;
						if (item?.AddOnItem?.length) {
							let addOns = [...item.AddOnItem];
							addOns.map((e) => {
								e.quantity--;
							});
							item.AddOnItem = addOns;
						}
						if (item.quantity <= 0) {
							removeInd = index;
						}
					}
				});
				if (removeInd >= 0) {
					stateCopy.splice(removeInd, 1);
				}
				localStorage.setItem("cartItems", JSON.stringify(stateCopy));
				return stateCopy;
			});
		} else {
			navigate("/select-food");
		}
	};

	const addToCart = (item) => {
		const updatedCart = [...addedItems, item];
		setAddedItems(updatedCart);
	}

	const updateAddedItems = (itemId, count) => {
		setAddedItems((prevItems) => ({
			...prevItems,
			[itemId]: count,
		}));
	};

	const handleUpgradeRadioClick = (index) => {
		setActiveIndex(index);
		selectedItem.quantity = index + 1;
	};

	const handleUpgradeConfirm = () => {
		const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
		const parentIndex = cartItems.findIndex(
			(item) => item.ItemID === selectedItem.upgradeParentId
		);

		if (parentIndex !== -1) {
			const parentItem = cartItems[parentIndex];
			const remainingQuantity = parentItem.quantity - selectedItem.quantity;

			if (remainingQuantity > 0) {
				cartItems[parentIndex].quantity = remainingQuantity;
			} else {
				cartItems.splice(parentIndex, 1);
			}
		}

		const existingItemIndex = cartItems.findIndex(
			(item) => item.ItemID === selectedItem.ItemID
		);

		if (existingItemIndex !== -1) {
			cartItems[existingItemIndex].quantity += selectedItem.quantity;
		} else {
			cartItems.push({
				...selectedItem,
				quantity: selectedItem.quantity,
			});
		}
		handleUpgradeModalClose()
		// localStorage.setItem("cartItems", JSON.stringify(cartItems));
		setAddedItems(cartItems);
	};

	return (
		<>
			{getWindowWidth &&
				<div className="bg-white show-in-desktop-view" style={{ position: "sticky", top: "0", zIndex: "999" }}>
					<Navbar />
				</div>
			}
			<div className={currentClasses.fnbdeskdiv}>
				<div className={currentClasses.fnbdeskdivInn}>
					{loading ? (
						<FnbCheckoutSkeleton />
					) : (
						<>
							<FbHeader headname="Checkout" addressShow={true} />
							<div className="co-outer-maindiv">
								{removeApplied && location && discAmt >= 0 && (
									<div iv className="fb-save-label">
										<p className="fb-save-lP">₹{discAmt} Savings on the bill</p>
									</div>
								)}
								{addedItems && (
									<div className="co-outer">
										{addedItems && addedItems?.map(
											(item, index) => (
												<div key={index} className="buyItem-list">
													<div className="buyItem-listInn">
														<div className="co-itemName">
															{item?.IsVeg ?
																<svg className="co-vNv" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#149752" />
																	<circle cx="6.99984" cy="6.99935" r="4.08333" fill="#149752" />
																</svg>
																:
																<svg className="co-vNv" width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<rect x="0.961426" y="0.964844" width="10.0769" height="10.0769" rx="1.5" stroke="#87001B" />
																	<path d="M9 8.80886H3L6.00011 3.18945L9 8.80886Z" fill="#87001B" />
																	<clipPath id="clip0_3452_1183">
																		<rect width="6" height="6" fill="#87001B" transform="translate(3 3)" />
																	</clipPath>
																</svg>
															}

															<div>
																<p className="co-itemNameP">{item?.ItemName || item?.itemName}</p>
																{item?.upgradeParentId && <span className='upgradeTag'>Upgraded</span>}
															</div>
														</div>
														<div className="co-itemName chkoutbtn">
															<AddButton
																cartBtn={true}
																cartStyle={cartTransprantBtn}
																key={`${item.ItemID}-upgrade`}
																data={item}
																cartIndex={index}
																setAddedItems={setAddedItems}
																addedItems={addedItems}
																isCart={true}
																updateCount={addToCart}
																removeFromCart={removeFromCart}
																totalItems={countAllItems}
															/>
															<p className="co-pricep">
																₹
																{(
																	(item?.quantity * item?.price) / 100
																	// (item?.AddOnItem?.reduce((sum, addon) => sum + (addon.price * addon.quantity) / 100, 0) || 0)
																).toFixed(2)}
															</p>
														</div>
													</div>
													{item?.AddOnItem?.length > 0 &&
														<div className="co-addon-div" style={{ paddingLeft: "25px" }}>
															Add ons:
															{item?.AddOnItem.map((addon, index) => {
																return (
																	<div key={index} className="co-addon-inndiv">
																		<span>
																			<em>{`${addon?.name} x ${addon?.quantity}`}</em>
																		</span>
																		<span style={{ fontFamily: "sf-pro-text-medium" }}>₹{((addon?.price * addon?.quantity) / 100).toFixed(2)}</span>
																	</div>
																);
															})}
														</div>
													}
												</div>
											)
										)}
									</div>
								)}

								{/* {upgradeData?.length > 0 && !addedItems.some(item => item.AddOnItem && item.AddOnItem.length > 0) && ( */}
								{upgradeData?.length > 0 && !addedItems.every(item => item.AddOnItem && item.AddOnItem.length > 0) && (
									<>
										<div className="co-ra-div">
											<img
												className="ra-leftline"
												src={theme === "pvrTheme" ? leftLinePVR : leftLineINOX}
												alt="left-line"
											/>
											<p className="co-ra-ptag">Upgrade your cart?</p>
											<img
												className="ra-rightline"
												src={theme === "pvrTheme" ? rightLinePVR : rightLineINOX}
												alt="right-line"
											/>
										</div>
										<div className={currentClasses.vchrLanding}>
											<div ref={scrollUpgradeRef} className="Rec-outer">
												{upgradeData?.filter(item => !(item.AddOnItem && item.AddOnItem.length > 0)).map((item) => {
													const parentDetails = addedItems.find(addedItem => addedItem.ItemID === item.upgradeParentId);
													return (
														<UpgradeCart
															key={`upgrade-${item.id}`}
															data={item}
															parentData={parentDetails}
															// handleFoodInfoShow={() => handleFoodInfoShow(item)}
															// handleCustomizfoShow={() =>
															// 	handleCustomizfoShow(item)
															// }
															// addedItems={addedItems}
															cartItemIds={cartItemIds.current}
															// updateAddedItems={addToCart}
															// updateCount={addToCart}
															removeFromCart={removeFromCart}
															totalItems={countAllItems}
															handleUpgradeModalShow={() =>
																handleUpgradeModalShow(item)
															}
														/>
													)
												})}
											</div>

											{getWindowWidth && upgradeData.length > 2 && (
												<>
													{isRightArrowVisibleUpgrade && (
														<img
															className="rptarw fnb-arrow fnb-scrollArrRight"
															src={scrollIcon}
															alt="scrollIcon"
															onClick={() => handleUpgradeScroll("right")}
														/>
													)}
													{isLeftArrowVisibleUpgrade && (
														<img
															className="rptarw fnb-arrow fnb-scrollArrleft"
															src={scrollIcon}
															alt="scrollIcon"
															onClick={() => handleUpgradeScroll("left")}
														/>
													)}
												</>
											)}
										</div>
									</>
								)}

								{/* <div style={{ marginTop: "10px" }} className="co-ra-div">
									<img
										className="ra-leftline"
										src={theme === "pvrTheme" ? leftLinePVR : leftLineINOX}
										alt="left-line"
									/>
									<p className="co-ra-ptag">OFFERS & VOUCHERS</p>
									<img
										className="ra-rightline"
										src={theme === "pvrTheme" ? rightLinePVR : rightLineINOX}
										alt="right-line"
									/>
								</div>
								<div className="fb-a-offer">
									<div className={`fb-a-a `} onClick={() => fnbPaymentDetailsAPI("offers")}>
										<p className="fb-a-aLink">
											<img
												className="tra-dic-co"
												src={theme === "pvrTheme" ? traDicPVR : traDicINOX}
												alt=""
											/>
											{payDetailLoading ? "Loading..." : "Explore & Apply Offers"}
										</p>
										<img src={rightArr} alt="" />
									</div>
									{removeApplied && location && discAmt >= 0 && (
										<>
											<div className="co-dass">
												<img className="co-dassImg" src={dassadLine} alt="" />
											</div>

											<div className="co-savepop">
												<p className="co-savepopP">
													Save ₹{discAmt} with ‘{appliedVocher?.vouPayType === "PROMOCODE" ? appliedVocher?.vouId : appliedVocher?.vouDesc}’
												</p>
												<img
													src={closeIcon}
													alt=""
													onClick={handleRemoveOfferApplied}
												/>
											</div>
										</>
									)}
								</div> */}
								<div className="co-ra-div">
									<img
										className="ra-leftline"
										src={theme === "pvrTheme" ? leftLinePVR : leftLineINOX}
										alt="left-line"
									/>
									<p className="co-ra-ptag">ORDER DELIVERY</p>
									<img
										className="ra-rightline"
										src={theme === "pvrTheme" ? rightLinePVR : rightLineINOX}
										alt="right-line"
									/>
								</div>
								<div className="dts-main-div">
									<div className="dts-inn-div">

										<div
											onClick={handleClick}
											className={`dtmsDiv ${selectedTheater?.fbDeliveryOnSeat || TransactionType === "OnlyFB" || getWindowWidth
												? "seatntaval"
												: (!getWindowWidth && (screenType && screenType !== "N" && TransactionType === "OnlyFB" && seatdetails?.seatrow) || selected === "radio1" || (renderSeatInfo() && selected === null))
													? "rdactive"
													: ""
												}`}
										>
											{loadingSeat ? (
												"Loading..."
											) : (
												<>
													<div className="inrDisc">
														<p className="dtmsP">Deliver to my seat</p>

														<p className="confirmSeat">
															{!getWindowWidth && ((screenType && screenType !== "N" && TransactionType === "OnlyFB" && seatdetails?.seatrow)
																|| selectedTheater?.fbDeliveryOnSeat || (TransactionType !== "OnlyFB" && !getWindowWidth && showSeatInfo))
																? renderSeatInfo()
																: ""
															}

															{getWindowWidth && "Available on PVR INOX Mobile App"}
														</p>
													</div>


													<div
														className={`rdochk ${selectedTheater?.fbDeliveryOnSeat || getWindowWidth
															? "seatntaval"
															: (!getWindowWidth && (screenType && screenType !== "N" && TransactionType === "OnlyFB" && seatdetails?.seatrow) || selected === "radio1" || (renderSeatInfo() && selected === null))
																? "rdactive"
																: ""
															}`}
													>
														{selectedTheater?.fbDeliveryOnSeat || getWindowWidth
															? ("")
															: (!getWindowWidth && (screenType && screenType !== "N" && TransactionType === "OnlyFB" && seatdetails?.seatrow) || selected === "radio1" || (renderSeatInfo() && selected === null))
																? (<img src={theme === "pvrTheme" ? checkPVR : checkINOX} alt="Selected" />)
																: ("")
														}
													</div>
												</>
											)}
										</div>


										<div
											onClick={() => {
												handleRadioTypeClick("radio2");
											}}
											className={`dtmsDiv ${selected === "radio2" ? "rdactive" : ""}`}
										>
											<div className="inrDisc">
												<p className="dtmsP">Pick-up from counter</p>
												<p className="confirmSeat">
													{(screenType && screenType !== "N" && TransactionType === "OnlyFB" && seatdetails?.seatrow)
														? ""
														: selected === "radio2" && `${PickupBookingDate} ${PickupBookingTime}`
													}
												</p>
											</div>
											<div
												className={`rdochk ${selected === "radio2" ? "rdactive" : ""}`}
											>
												{selected === "radio2" && (
													<img
														src={theme === "pvrTheme" ? checkPVR : checkINOX}
														alt="Selected"
													/>
												)}
											</div>
										</div>
									</div>
								</div>

								<div className="co-ra-div">
									<img
										className="ra-leftline"
										src={theme === "pvrTheme" ? leftLinePVR : leftLineINOX}
										alt="left-line"
									/>
									<p className="co-ra-ptag">BILL DETAILS</p>
									<img
										className="ra-rightline"
										src={theme === "pvrTheme" ? rightLinePVR : rightLineINOX}
										alt="right-line"
									/>
								</div>
								<div className="fb-stdiv">
									<div className="fb-stdivInn">
										{/* <div className="fb-stdivI">
											<p className="fb-stp">Sub Total</p>
											<p className="fb-stp prcfont">

												{addedItems
													? `₹${(addedItems.reduce((total, item) => {
														const totalPrice = item?.price * item.quantity;
														const addonPrice = item?.AddOnItem?.reduce((addonTotal, addon) => {
															return addonTotal + (addon?.price * addon.quantity || 0);
														}, 0);

														return total + totalPrice + addonPrice
													}, 0) / 100).toFixed(2)}`
													: "-"}
											</p>
										</div>
										<img src={dassadLine} alt="" />
										{removeApplied && discAmt >= 0 && (
											<>
												<div className="fb-stdiv4 b1">
													<p className="fb-stpII">Offer Applied</p>
													<p className="fb-stpII2">-₹{discAmt}</p>
												</div>
												<img src={dassadLine} alt="" />
											</>
										)} */}

										<div className="fb-stdivIIIII">
											<p className="fb-stpIII">Grand Total</p>
											<p className="fb-stpIII prcfont">
												{removeApplied &&
													finalAmount !== null &&
													finalAmount !== undefined && !isNaN(finalAmount)
													? `₹${finalAmount}`
													: addedItems
														? `₹${(addedItems.reduce((total, item) => {
															const totalPrice = item?.price * item.quantity;
															const addonPrice = (item?.AddOnItem || [])?.reduce((addonTotal, addon) => {
																return addonTotal + (addon?.price * addon.quantity || 0);
															}, 0);
															return total + totalPrice + addonPrice;
														}, 0) / 100).toFixed(2)}`
														: "-"}
											</p>
										</div>
										<p className="fb-stp" style={{ marginBottom: 0 }}>*Inclusive of all the taxes</p>
									</div>
								</div>
							</div>
							{/* <MobilePayment  /> */}
							<div onClick={paymentHandle} className={currentClasses.fbAddedDiv}>
								<button className="fb-added-btn">
									{paymentLoading ? (
										<span className="loading-spinner">Processing...</span>
									) : (
										getWindowWidth ?
											"Proceed to Payment" :
											removeApplied &&
												finalAmount !== null &&
												finalAmount !== undefined &&
												!isNaN(finalAmount)
												? finalAmount > 0
													? (
														<>
															Pay <span className='prcfont'>₹{finalAmount?.toFixed(2)}</span>
														</>
													)
													: "Proceed to Payment"
												: addedItems
													? (
														<>
															Pay <span className='prcfont'>₹{(addedItems.reduce((total, item) => {
																const totalPrice = item?.price * item.quantity || 0;
																const addonPrice = item?.AddOnItem?.reduce((addonTotal, addon) => {
																	return addonTotal + (addon?.price * addon.quantity || 0);
																}, 0);
																return total + totalPrice + addonPrice;
															}, 0) / 100).toFixed(2)}</span>
														</>
													)
													: "-"
									)}

								</button>
							</div>

						</>
					)}

				</div>
			</div>
			{getWindowWidth &&
				<Footer />
			}
			<Modal
				className={`${currentClasses.centerModal} p0`}
				show={showYourSeatModal}
				onHide={handleYourSeatModalClose}
				animation={false}
				keyboard={false}
				style={{ backgroundColor: "rgba(0,0,0,0.7)", overflow: "hidden" }}
			>
				<div className="ys-m-main-div">
					<div className="m-cus-head" style={{ justifyContent: "end" }}>
						<img
							onClick={handleYourSeatModalClose}
							src={closeIcon}
							alt="close"
						/>
					</div>
					<div className="ys-m-inn">
						<img
							className="ys-seatImg"
							src={theme === "pvrTheme" ? Seat : SeatInox}
							width={80}
							height={80}
							alt="Seat"
						/>
						<p className="ys-ptag">Your Seats</p>
						<p className="ys-pdesc">
							Your mobile no. {userInfoData?.data?.mob} returned the
							following booking(s). Please confirm by{" "}
							<b>tapping on seats below</b> to deliver your food
						</p>
					</div>
					<div className="ys-main-sinfo">
						<Swiper
							spaceBetween={
								seatDetailData?.length > 0
									? 5
									: 0
							}
							slidesPerView={
								seatDetailData?.length > 1
									? getWindowWidth
										? 1
										: 1.1
									: 1.1
							}
							centeredSlides={true}
							loop={false}
						>
							{seatDetailData?.map((item, index) => (
								<SwiperSlide className="ys-sinfo" key={index}>
									<p className='tagCount'>{`${index + 1}/${seatDetailData?.length}`}</p>
									<h2 className="ys-moviename">{item?.filmName}</h2>
									<p className="ys-cineAdd">{item?.theaterName}</p>
									<p className="ys-cineTime">Today, {formattedShowTime} - {formattedEndTime}</p>
									<div className="ys-sbox">
										<p className="ys-seatname">{item?.audi}</p>
										<div className="ys-seatSquMain">
											<div className="ys-seatSqu">
												{seatDetailData &&
													item?.seats?.split(",").slice(0, 5).map((seat) => (
														<p key={seat}
															className={`ys-seatcode ${selectedSeat === seat ? "selected" : ""}`}
															onClick={() => handleSeatClick(seat)}
														>
															{seat}
														</p>
													))}
											</div>
											<div className="ys-seatSqu">
												{seatDetailData &&
													item?.seats?.split(",").slice(5, 10).map((seat) => (
														<p key={seat}
															className={`ys-seatcode ${selectedSeat === seat ? "selected" : ""}`}
															onClick={() => handleSeatClick(seat)}
														>
															{seat}
														</p>
													))}

												{errMsg && <div className="errmsg">Select any one seat</div>}
											</div>
										</div>
										<p className="ys-bdesc">Please tap on the seat to select</p>
									</div>
								</SwiperSlide>
							))}
						</Swiper>
					</div>
					<Link to="/fnb-seats-selection" className="snf-manualBtn" style={{ margin: "5px 0 25px" }}>
						Enter Manually
					</Link>
					<button
						onClick={() => handleConfirm(seatDetailData)}
						className="ys-con-btn"
					>
						Confirm
					</button>
				</div>
			</Modal>

			<Modal
				className={`${currentClasses.centerModal} p0`}
				show={showYourSeatManual}
				onHide={handleYourSeatManualClose}
				animation={false}
				keyboard={false}
				style={{ backgroundColor: "rgba(0,0,0,0.7)", overflow: "hidden" }}
			>
				<div className="ys-m-main-div">
					<div className="m-cus-head" style={{ justifyContent: "end" }}>
						<img
							onClick={handleYourSeatManualClose}
							src={closeIcon}
							alt="close"
						/>
					</div>
					<div className="ys-m-inn">
						<img
							className="ys-seatImg"
							src={theme === "pvrTheme" ? Seat : SeatInox}
							width={80}
							height={80}
							alt="Seat"
						/>
						<p className="ys-ptag">Your Seats</p>
						<p className="ys-pdesc">
							Your mobile no. {userInfoData?.data?.mob} returned the
							following booking(s). Please confirm by{" "}
							<b>tapping on seats below</b> to deliver your food
						</p>
					</div>
					<div className="m-snf-div">
						<img src={seactNotFound} alt="" />
						<p className="snf-oops">Oops! No Movie Found..</p>
						<p className="snf-desc">
							We couldn’t find any booking against this number. Please add your
							seats manually.{" "}
						</p>
					</div>
					<Link to="/fnb-seats-selection" className="snf-manualBtn">
						Enter Manually
					</Link>
				</div>
			</Modal>


			<Modal
				className={`${currentClasses.centerModal} p0`}
				show={showUpgradeModal}
				onHide={handleUpgradeModalClose}
				animation={false}
				keyboard={false}
				style={{ backgroundColor: "rgba(0,0,0,0.7)", overflow: "hidden" }}
			>
				<div className="ys-m-main-div upgradeModale">
					<div className="m-cus-head" style={{ justifyContent: "end" }}>
						<img
							onClick={handleUpgradeModalClose}
							src={closeIcon}
							alt="close"
						/>
					</div>
					<div className="ys-m-inn">
						<p className="ys-ptag">Upgrade Cart Item</p>
						<div className="up-card-inn">
							<div className="Reco-item-view">
								<img className="Reco-item-viewImg" src={selectedItem?.itemImageURL || noImg} width="84" alt={selectedItem?.itemName || 'No image available'} onError={handleImageError} />
								{selectedItem?.IsVeg ? (
									<img className="Reco-itemVorN" src={vegIcon} alt="veg" />
								) : (
									<img className="Reco-itemNon" src={nonvegIcon} alt="veg-nonveg" />
								)}
							</div>
							<div className="Reco-item-desc">
								<p className="Reco-itemName">{selectedItem?.ItemName}</p>

								<p className="Reco-itemPrice">
									₹{(selectedItem?.price) / 100}
									{/* {setSelectedItem?.AddOnItem?.length > 0 &&
										<>
											<img src={ellipse} alt="dot" />
											<span>Customizable</span>
										</>
									} */}
								</p>
							</div>
						</div>
						{upgradeItem?.quantity > 0 && (
							<>
								<h4 className="mintitle">Upgrading Options</h4>
								<div className="foodqnty">
									{Array.from({ length: upgradeItem.quantity }).map((_, index) => (
										<div
											key={`upgradeModal-${index}`}
											className={`dtmsDiv ${activeIndex === index ? "rdactive" : ""}`}
											onClick={() => handleUpgradeRadioClick(index)}
										>
											<div className="inrDisc">
												<p className="dtmsP">
													{index + 1} X {selectedItem?.ItemName || "Item Name"}
												</p>
											</div>
											<div className={`rdochk ${activeIndex === index ? "rdactive" : ""}`}>
												{activeIndex === index && (
													<img
														src={theme === "pvrTheme" ? checkPVR : checkINOX}
														alt="Selected"
													/>
												)}
											</div>
										</div>
									))}
								</div>
							</>
						)}
						<div className="flxcnf">
							<button className='cmnbtn upgradebtnNo' onClick={handleUpgradeModalClose}>No</button>
							<button className="cmnbtn upgradebtnYes" onClick={handleUpgradeConfirm}>Yes, Proceed</button>
						</div>
					</div>
				</div>
			</Modal >

			<SweetAlertUtility type={alertDetails.type} title={alertDetails.title} message={alertDetails.message} show={alertDetails.show} onConfirm={hideAlert} />

			{
				showAlert &&
				<div>
					<SweetAlert
						error
						show={showAlert}
						title={errorMsg?.result === "dialog" ? "Oops" : errorMsg?.result}
						onConfirm={() => {
							setShowAlert(false);
							if (isBadTransaction) {
								navigate("/");
							} else {
								if (isMobile) {
									navigate(`/m.payment/${localStorage.getItem("cinemaName")}`);
								} else {
									navigate(`/payment/${localStorage.getItem("cinemaName")}`);
								}
							}
						}}
						onCancel={() => setShowAlert(false)}
						showConfirm={true}
						showCancel={false}
						confirmBtnText="OK"
						confirmBtnCssClass={
							theme === "inoxTheme"
								? "confirm-btn-inox border-0"
								: "confirm-btn-pvr border-0"
						}
						closeOnClickOutside={false}
						closeOnEscape={false}
					>
						{errorMsg?.msg}
					</SweetAlert>
				</div>
			}

			<div>
				<Dialog
					header=""
					visible={loginVewMode}
					// modal={false}
					showHeader={false}
					showCloseIcon={false}
					style={{ width: "40vw" }}
					onHide={() => dispatch(setLoginViewMode({ value: false }))}
					className="register-mobile"
					blockScroll={true}
				>
					<div className="row">
						<Login />
					</div>
				</Dialog>
			</div>

		</>
	);
};

export default Checkout