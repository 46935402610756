import React from "react";
import vegIcon from "../../../assets/food/veg.svg";
import nonvegIcon from "../../../assets/food/nonveg.svg";
import ellipse from "../../../assets/food/Ellipse.svg";
import noImg from "../../../assets/food/noimagesqr.png";
import AddButton from "./AddButton";


const PairItems = ({
  data,
  addedItems,
  updateAddedItems,
  handleFoodInfoShow,
  handleCustomizfoShow,
  removeFromCart,
  cartItemIds,
  updateCount,
  totalItems,
  key,
}) => {
  const handleCardClick = (e) => {
    if (!e.target.closest(".Customfb-btnDiv")) {
      handleFoodInfoShow();
    }
  };

  const handleImageError = (e) => {
    e.target.src = noImg;
  };
  // Find the corresponding item in addedItems
  const addedItem = addedItems.find((item) => item.ItemID === data.id);
  const itemCount = addedItem ? addedItem.quantity : 0;

  return (
    <div onClick={handleCardClick} className="Reco-card-main">
      <div
        style={{
          boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
          border: "none",
        }}
        className="Reco-card-inn"
      >
        <div className="Reco-item-view">
          <img
            className="Reco-item-viewImg"
            src={data.i || noImg}
            width="84"
            alt={data?.h || "No image available"}
            onError={handleImageError}
          />
          {/* {data.veg ? (
            <img className="Reco-itemVorN" src={vegIcon} alt="veg" />
          ) : (
            <img className="Reco-itemNon" src={nonvegIcon} alt="non-veg" />
          )} */}
          {data.veg ?
            <svg className="Reco-itemVorN" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#149752" />
              <circle cx="6.99984" cy="6.99935" r="4.08333" fill="#149752" />
            </svg>
            :
            <svg className="Reco-itemNon" width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.961426" y="0.964844" width="10.0769" height="10.0769" rx="1.5" stroke="#87001B" />
              <path d="M9 8.80886H3L6.00011 3.18945L9 8.80886Z" fill="#87001B" />
              <clipPath id="clip0_3452_1183">
                <rect width="6" height="6" fill="#87001B" transform="translate(3 3)" />
              </clipPath>
            </svg>
          }
        </div>
        <div className="Reco-item-desc">
          <p className="Reco-itemName">{data?.h}</p>
          <p className="Reco-itemPrice">
            ₹{parseInt(data.dp / 100)}
            {data.addOn && <img src={ellipse} alt="dot" />}
            {data.addOn && <span>Customizable</span>}
          </p>
          <div className="Customfb-btnDiv">
            <AddButton
              itemId={data.id}
              addedItems={addedItems}
              updateCount={updateCount}
              handleCustomizfoShow={() => handleCustomizfoShow(data)}
              data={data}
              cartItemIds={cartItemIds}
              updateAddedItems={updateAddedItems}
              removeFromCart={removeFromCart}
              totalItems={totalItems}
              btnQuantity={itemCount}
            />
            {/* {data.addOn && <span className="custom-btn">Customizable</span>} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PairItems;
