import React, { useEffect, useState } from "react";
import * as appConstants from "../../../constants/index";
import axios from "axios";
import { refraceTokenService } from "src/global-functions/refreshToken";
import { Link, useLocation, useNavigate } from "react-router-dom";
import miv_inox from "../../../assets/default-images/vertical-poster-inox.png";
import miv_pvr from "../../../assets/default-images/vertical-poster-pvr.svg";
import mih_pvr from "../../../assets/default-images/horizontal-poster-pvr.svg";
import mih_inox from "../../../assets/default-images/horizontal-poster-inox.png";
import noImage from "../../../assets/food/noimage.png";
import Cities from "src/views/common/cities/Cities";
import { useDispatch, useSelector } from "react-redux";
import MobileFooter from "../mobile-footer/MobileFooter";
import { Sidebar } from "primereact/sidebar";
import { InputText } from "primereact/inputtext";
import location from "../../../assets/nav-icon/location.svg";
import foodGIF from "../../../assets/food/food.gif";
import way from "../../../assets/search/ways.svg";
import angle_left from "../../../assets/mobilemoviedetail/arrow-b.svg";
import "./instafood.css";
import LikeIcon from "../../../assets/mobilemoviedetail/heart.svg";
import heart_pvr from "../../../assets/mobilemoviedetail/yellowheart.svg";
import heart_inox from "../../../assets/mobilemoviedetail/blueheart.svg";
import { addMinutes, setHours, format, setMinutes, isAfter, isBefore, getHours, getMinutes, isToday, isValid, parse } from 'date-fns';
import NowShowingSkeleton from "src/skeletons/NowShowingSkeleton";
import { monthsName } from "src/global-functions/globalFunctions";
import { Calendar } from "primereact/calendar";
import { ProceedButton } from "src/views/gift-cards/giftCardTags";
import backBtn from "../../../assets/food/backBtnn.svg";
import backBtndesk from '../../../assets/food/desktopBack.svg';
import locationIcon from '../../../assets/food/locationBlack.svg'
import FbHeader from "src/views/food-and-beverage/component/FbHeader";
import { Modal } from "react-bootstrap";
import Navbar from "src/views/common/navbar/Navbar";
import { getResponsiveClasses } from "src/views/food-and-beverage/component/ResponsiveClasses";
import Footer from "src/views/common/footer/Footer";
import SweetAlert from "react-bootstrap-sweetalert";
import SweetAlertUtility from "src/views/food-and-beverage/component/SweetAlertUtility";




const CinemaLocationList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = localStorage.getItem("theme");
  const modeVal = useSelector((state) => state.viewModeValue);
  const [cinemasData, setCinemasData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingSeat, setLoadingSeat] = useState(false);
  const [selectedCinemaId, setSelectedCinemaId] = useState("");
  const [visibleRight, setVisibleRight] = useState(true);
  const [filterCinema, setFilterCinema] = useState([]);
  const [daysList, setDaysList] = useState(null);
  const [theaterId, setTheaterId] = useState(null);
  const [theaterInfo, setTheaterInfo] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [time, setTime] = useState(addMinutes(new Date(), 30));
  const [date, setDate] = useState("");
  const [cinemaDistance, setCinemaDistance] = useState();
  const [maxtime, setMaxtime] = useState(addMinutes(new Date(), 30));
  const [mintime, setMintime] = useState("");
  const nineAM = setHours(setMinutes(new Date(), 0), 9); // 9:00 AM
  const elevenPM = setHours(setMinutes(new Date(), 0), 23); // 11:00 PM
  const queryParams = new URLSearchParams(window.location.search);
  const [seatDetailData, setSeatDetailData] = useState(null);
  const utmSource = queryParams.get("utm_source");
  const utm_campaign = queryParams.get("utm_campaign");
  const [visiblemapview, setVisiblemapview] = useState(false);
  const [alertDetails, setAlertDetails] = useState({ show: false, type: '', title: '', message: '' });
  const [show, setShow] = useState(false);
  const [mapMsg, setMapMsg] = useState(null);
  const [cinemaAdd, setCinemaAdd] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [formattedShowTime, setFormattedShowTime] = useState(null);
  const [formattedEndTime, setFormattedEndTime] = useState(null);
  const [screenType, setScreenType] = "";

  const { currentClasses, getWindowWidth } = getResponsiveClasses();

  const [showModalView, setShowModalView] = useState(() => {
    const storedValue = localStorage.getItem("setShowModalView");
    return storedValue !== null ? storedValue : "true";
  });

  const location = useLocation();

  const checkLat = JSON.parse(localStorage.getItem("userLocation")) || {};
  let TransactionType = localStorage.getItem("TransactionType");
  localStorage.removeItem("cartItems");

  useEffect(() => {
    if (!TransactionType && getWindowWidth) {
      TransactionType = "OnlyFB";
      localStorage.setItem("TransactionType", TransactionType);
    }
  }, [TransactionType])

  useEffect(() => {
    getCinemasData();
  }, [modeVal]);

  useEffect(() => {
    if (date) {
      getMaxTime();
      getMinTime();
    }
  }, [date, activeTab]);

  useEffect(() => {
    if (showModalView === "true") {
      if (TransactionType === "InCinemaFB") {
        userDistanceCalc();
      } else {
        seatDetailsAPI();
      }
      localStorage.setItem("setShowModalView", "true");
    }
  }, [cinemasData]);

  const getMaxTime = () => {
    setMaxtime(setHours(setMinutes(new Date(date), 0), 23));
  };

  const getMinTime = () => {
    const now = new Date();
    let minTime;

    if (activeTab === 0) {
      minTime = addMinutes(now, 30);
      const nineAM = setHours(setMinutes(new Date(), 0), 9);
      if (minTime.getTime() < nineAM.getTime()) {
        minTime = nineAM;
      }
    } else {
      minTime = setHours(setMinutes(new Date(date), 0), 9);
    }

    setTime(minTime);
    // setMintime(minTime);
  };

  function validateTime(event) {
    let selectedDate;
    let minTime;

    // Parse the selected time
    if (!event || !event.value) {
      const selectedData = localStorage.getItem("seatLayoutDate");
      const selDate = new Date(selectedData)
      if (selDate.getDate() === time.getDate() &&
        selDate.getMonth() === time.getMonth() &&
        selDate.getFullYear() === time.getFullYear())
        return;

      const now = new Date();
      const timeString = `${now.getHours()}:${now.getMinutes()}`;
      selectedDate = new Date(`${selectedData} ${timeString}`);
    } else {
      selectedDate = new Date(event.value);
    }

    if (isNaN(selectedDate.getTime())) {
      console.error("Invalid date parsed:", selectedDate);
      return;
    }

    const now = new Date();
    const selectedCurrentDate = new Date(selectedDate);
    const todayStart = new Date();
    todayStart.setHours(9, 0, 0, 0);
    const todayEnd = new Date();
    todayEnd.setHours(23, 0, 0, 0);

    if (
      selectedCurrentDate.getDate() === now.getDate() &&
      selectedCurrentDate.getMonth() === now.getMonth() &&
      selectedCurrentDate.getFullYear() === now.getFullYear()
    ) {
      const minAllowedTime = new Date(now.getTime() + 30 * 60 * 1000);
      if (selectedDate < minAllowedTime) {
        selectedDate = minAllowedTime;
      }
    } else {
      minTime = setHours(setMinutes(new Date(selectedDate), 0), 9);
      const maxTime = setHours(setMinutes(new Date(selectedDate), 0), 23);

      if (selectedDate < minTime) {
        selectedDate = minTime;
      } else if (selectedDate > maxTime) {
        selectedDate = maxTime;
      }
    }
    if (
      selectedCurrentDate.getDate() === now.getDate() &&
      selectedCurrentDate.getMonth() === now.getMonth() &&
      selectedCurrentDate.getFullYear() === now.getFullYear()
    ) {
      if (selectedDate < todayStart) {
        selectedDate = todayStart;
      } else if (selectedDate > todayEnd) {
        selectedDate = todayEnd;
      }
    }

    // const timeString = format(selectedDate, "h:mm a");

    setTime(selectedDate);
  }

  const getCinemasData = async () => {
    try {
      setLoading(true);

      // Retrieve latitude and longitude from localStorage
      const userLocation = JSON.parse(localStorage.getItem("userLocation")) || {};
      const latitude = userLocation.latitude || "0.000";
      const longitude = userLocation.longitude || "0.000";

      // Prepare post data
      const postData = {
        city: localStorage.getItem("city") || "",
        lat: latitude,
        lng: longitude,
        text: "",
      };

      // Send POST request
      const response = await axios.post(
        `${appConstants.base_url}v1/booking/content/cinemas`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
            chain: theme === "inoxTheme" ? "INOX" : "PVR",
            city: localStorage.getItem("city") || "",
            country: appConstants.cname,
            appVersion: appConstants.av,
            platform: appConstants.pt,
          },
        }
      );

      // Handle response
      if (response.status === 200 && response.data.code === 10001) {
        const cinemas = response.data.output.c || [];
        const sortedCinemas =
          parseFloat(longitude) > 0
            ? cinemas
            : cinemas.sort((a, b) => a.name.localeCompare(b.name));

        setCinemasData(cinemas);
        setFilterCinema(sortedCinemas);
        setMapMsg(response.data.output.fmsg);

        setShow(true);
      } else {
        setFilterCinema([])
        console.log("Unexpected response:", response);
      }
    } catch (error) {
      setLoading(false);
      const errResponse = error?.response;
      if (errResponse?.status === 403) {
        refraceTokenService(); // Refresh token if unauthorized
        console.error("Forbidden: You do not have permission to access this resource.");
      } else {
        console.error("Error fetching cinema data:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const monthName = (data) => {
    const monthNum = new Date(data).getMonth();
    const monthName = monthsName[monthNum];
    return monthName;
  };

  const userDistanceCalc = () => {
    const { latitude: userLat, longitude: userLong } =
      JSON.parse(localStorage.getItem("userLocation")) || {};

    const calculateDistance = (lat1, lon1, lat2, lon2) => {
      const toRad = (value) => (value * Math.PI) / 180; // Convert degrees to radians

      const R = 6371e3; // Radius of the Earth in meters
      const dLat = toRad(lat2 - lat1);
      const dLon = toRad(lon2 - lon1);

      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      const distance = R * c; // Distance in meters
      return distance;
    };

    const filteredCinemas = cinemasData.filter((item) => {
      if (item.distance < 15000) {
        const theatreLat = item.latitude;
        const theatreLong = item.longitude;

        const distance = calculateDistance(
          userLat,
          userLong,
          theatreLat,
          theatreLong
        );
        return distance < 15000;
      }
      return false;
    });

    const firstCinema = filteredCinemas.length > 0 ? filteredCinemas[0] : null;

    if (firstCinema) {
      setCinemaDistance(firstCinema);
    } else {
      setCinemaDistance(firstCinema);
      console.error("No cinemas found within 15000 meters.");
    }
  };

  const CinemasSessionRequest = async (data, date) => {
    setLoading(true);
    setTheaterId(data?.theatreId);
    setTheaterInfo(data);

    const postData = {
      city: localStorage.getItem("city"),
      cid: data?.theatreId,
      lat: localStorage.getItem("lat") || "0.00",
      lng: localStorage.getItem("long") || "0.00",
      dated: date,
      qr: localStorage.getItem("isQR") && isMobile ? "YES" : "NO",
      cineType: "",
      cineTypeQR: "",
    };
    try {
      localStorage.setItem("TheatreId", data?.theatreId);
      localStorage.setItem("theaterId", data?.theatreId); // Old used everywhere
      // localStorage.setItem("theaterId", data?.theatreId);
      localStorage.setItem("selectedTheater", JSON.stringify(data));
      // localStorage.setItem("fnb_qr", "No");
      localStorage.setItem("booking_type", "FOOD");

      if (TransactionType === "InCinemaFB") {
        navigate("/select-food");
      } else {
        await axios
          .post(
            `${appConstants.base_url}v1/booking/content/csessions`,
            postData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token") || " "}`,
                chain: theme === "inoxTheme" ? "INOX" : "PVR",
                city: localStorage.getItem("city"),
                appVersion: appConstants.av,
                platform: appConstants.pt,
                country: appConstants.cname,
              },
            }
          )
          .then((res) => {
            const dt = res.data;
            if (dt.result === "success") {
              const ndt = dt.output;

              setDaysList(ndt?.days);
              setDate(ndt?.days[0]?.dt);
              setLoading(false);
              setVisibleRight(false);
            } else if (dt.result === "error") {
              setDaysList(null);
              setLoading(false);
              navigate("/select-food");
            }
          })
          .catch((error) => {
            setLoading(false);
            const err = error.response;
            if (err.status === 403) {
              // refraceTokenService();
              console.log(
                "Forbidden: You do not have permission to access this resource."
              );
            } else {
              console.log("Error:", error.message);
            }
            // setLoading(false);
          });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const postPrefrences = async (data) => {
    setLoading(true);
    const postData = {
      city: data.cityName,
      id: data.theatreId,
      like: data.like === "false" ? true : false,
      type: "t",
      did: "",
    };


    await axios
      .post(`${appConstants.base_url}v1/user/prefrences/set`, postData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setLoading(false);
        const dt = res.data;
        if (dt.result === "success") {
          getCinemasData();
        } else if (dt.result === "error" || dt.result === "dialog") {
        }
      })
      .catch((error) => {
        const err = error?.response;
        if (err?.status === 403) {
          // refraceTokenService();
          console.log(
            "Forbidden: You do not have permission to access this resource."
          );
        } else {
          console.log("Error:", error.message);
        }
      });
  };

  const seatDetailsAPI = async () => {
    setLoadingSeat(true);
    // const formData = {
    //   upcomingMovies: false  //`2024-09-16 19:45:00.000000`
    // };
    try {
      const response = await axios.post(
        `${appConstants.base_url}v1/fnb/getExistingBookings?upcomingMovies=true`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token") || " "}`,
            chain: localStorage.getItem("theme") === "inoxTheme" ? "INOX" : "PVR",
            appVersion: appConstants.av,
            platform: appConstants.pt,
            country: appConstants.cname,
          },
        }
      );
      if (response.data.result === "success" && response.data.output.length > 0) {
        setSeatDetailData(response.data.output);
        formattedShowEndTime(response.data.output);
        setVisiblemapview(false);
      } else {
        setVisiblemapview(true)
      }
    } catch (error) {
      sweetAlertError("Something went wrong!");
      console.error("Failed to fetch seat details:", error);
    } finally {
      setLoadingSeat(false);
    }
  };

  const formattedShowEndTime = (data) => {
    if (data.length > 0) {
      const showTime = parse(data?.[0]?.showTime, "yyyy-MM-dd HH:mm:ss.S", new Date());
      const endTime = parse(data?.[0]?.endTime, "yyyy-MM-dd HH:mm:ss.S", new Date());
      const formattedShowTime = showTime && isValid(showTime) ? format(showTime, "hh:mm a") : "-";
      const formattedEndTime = endTime && isValid(endTime) ? format(endTime, "hh:mm a") : "-";
      setFormattedShowTime(formattedShowTime);
      setFormattedEndTime(formattedEndTime);
    }
  }

  const handleConfirm = () => {
    setShowModalView("false");
    localStorage.setItem("setShowModalView", "false");
  };

  const handleHide = () => {
    if (!getWindowWidth) {
      setShow(false);
      setVisiblemapview(false);
    }
  };
  const handleSkipHide = () => {
    setShow(false);
    setVisiblemapview(false);
  };

  const sweetAlertSuccess = (msg) => {
    setAlertDetails({
      show: true,
      type: "success",
      title: "Success!",
      message: msg,
    });
  };

  const sweetAlertError = (msg) => {
    setAlertDetails({
      show: true,
      type: "error",
      title: "Error!",
      message: msg,

    });
  };

  const hideAlert = () => {
    setAlertDetails({ show: false });
  }

  const handleImageError = (e) => {
    e.target.src = noImage; // Set fallback image if the original image fails
  };

  const handleUpcomingMovie = (data) => {
    const { showTime, theaterId } = data;
    const currentTime = new Date();
    const showTimeDate = new Date(showTime);
    const timeToUse = currentTime < showTimeDate ? showTimeDate : currentTime;
    localStorage.setItem("booking_type", "FOOD");
    localStorage.setItem("theaterId", theaterId);
    localStorage.setItem("screenType", screenType === "" ? screenType : "N");
    localStorage.setItem("FoodPickupDateTime", format(timeToUse, "dd MMM yy, hh:mm a"));
    navigate("/select-food", { state: { orderDateTime: format(timeToUse, "yyyy-MM-dd HH:mm:00") } });
  }

  return (
    <>
      {getWindowWidth &&
        <div className="bg-white show-in-desktop-view" style={{ position: "sticky", top: "0", zIndex: "999" }}>
          <Navbar />
        </div>
      }
      <div className={currentClasses.fnbdeskdiv}>
        <div className={currentClasses.fnbdeskdivInn}>
          {!visibleRight && (
            <>
              <div className="containerfnb cinelistHdr">
                <div className="fnb-loc-div">
                  <span
                    onClick={() => {
                      setVisibleRight(true);
                    }}
                  >
                    <img
                      style={{ marginTop: "3px" }}
                      src={getWindowWidth ? backBtndesk : backBtn}
                      alt="backBtn"
                    />
                  </span>
                  <div>
                    <p className={currentClasses.headerTitle}>
                      Food Pickup Date & Time
                    </p>
                    <Link
                      to="/select-cinema-for-fnb"
                      className={currentClasses.headerAddress}
                    >
                      <img src={locationIcon} alt="location" />
                      <p className="fnbloc-name">{theaterInfo?.name}</p>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="phn-cinemas*">
                {!loading ? (
                  <>
                    <p className="topnotedc">Please confirm by when should we keep your order ready.</p>
                    <div className="datePickers fnbDate">
                      <p className="pfontw">Select Pickup Date</p>
                      <div className="calnder-current">
                        <ul>
                          {daysList &&
                            daysList?.map((item, index) => (
                              <li
                                key={index}
                                onClick={() => {
                                  setActiveTab(index);
                                  setDate(item?.dt);
                                }}
                              >
                                <div
                                  className={
                                    activeTab === index && theme === "inoxTheme"
                                      ? "dates-active"
                                      : activeTab === index &&
                                        theme === "pvrTheme"
                                        ? "dates-pvr-active"
                                        : "dates-inactive"
                                  }
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    // getMovieSessionData(item.dt);
                                    localStorage.setItem("seatLayoutDate", item.dt);
                                    validateTime("");
                                  }}
                                >
                                  <span
                                    className="month"
                                    style={
                                      item.d === "01" || activeTab === index
                                        ? {}
                                        : { visibility: "hidden" }
                                    }
                                  >
                                    {monthName(item.dt)}
                                  </span>
                                  <h5>{item.d}</h5>
                                  <span className="day">{item.wd}</span>
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>

                    <div className="pvr-movie-time* fnbDate">
                      <p className="pfontw">Select Pickup Time</p>
                      <div
                        className="datetimemn"
                        style={{ textAlign: "center" }}
                      >
                        <Calendar
                          value={time}
                          onChange={(e) => {
                            validateTime(e);
                          }}
                          // minDate={mintime}
                          // maxDate={maxtime}
                          showTime
                          timeOnly
                          inline
                          hourFormat="12"
                          showIcon
                          placeholder="Pickup Time"
                        />
                      </div>
                    </div>
                    <div className={currentClasses.fbAddedDiv} style={{ backgroundColor: "#FFF", paddingBottom: 15 }}>
                      <p className="notedc">Note: Our kitchen’s operational hours are <span style={{ whiteSpace: "nowrap" }}>9 am</span> to <span style={{ whiteSpace: "nowrap" }}>11 pm</span> everyday.</p>
                      <ProceedButton
                        className={
                          time && !loading
                            ? "btn-proceeds"
                            : "btn-proceeds btn-opacity"
                        }
                        style={{ position: "static" }}
                        type="button"
                        onClick={() => {
                          if (time && !loading) {
                            localStorage.setItem("booking_type", "FOOD");
                            //localStorage.setItem("fnb_qr", "Yes");
                            localStorage.setItem("theaterId", theaterId);
                            localStorage.setItem("screenType", screenType === "" ? screenType : "N");
                            localStorage.setItem("FoodPickupDateTime", format(time, "dd MMM yy, hh:mm a"));
                            navigate("/select-food", { state: { orderDateTime: format(time, "yyyy-MM-dd HH:mm:00") } });
                            // navigate(
                            // 	`/food/${localStorage.getItem(
                            // 		"city"
                            // 	)}/qr/${theaterId}?slot=${format(
                            // 		time,
                            // 		"yyyy-MM-dd HH:mm:ss"
                            // 	)}&utmSource=${utmSource || ""}&utm_campaign=${utm_campaign || ""
                            // 	}`
                            // );
                          }
                        }}
                      >
                        Proceed
                      </ProceedButton>
                    </div>
                  </>
                ) : (
                  // <span className="mx-3">Cinema not  found</span>
                  <NowShowingSkeleton countVal={4} />
                )}
              </div>
            </>
          )}
          {visibleRight && (
            <div className="citieslist">
              <FbHeader headname="Cinemas" addressShow={true} />
              <div className="cities-show*">
                <div style={{ width: "100%" }} className="search-current">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      <i className="pi pi-search" />
                    </span>
                    <InputText
                      placeholder="Search Cinema"
                      id="cinema"
                      value={searchText}
                      onChange={(e) => {
                        const val = e.target.value;
                        setSearchText(val);
                        const filterCinemaData =
                          cinemasData &&
                          cinemasData?.filter((item) =>
                            item?.name
                              ?.toLowerCase()
                              ?.includes(val?.toLowerCase())
                          );
                        setFilterCinema([...filterCinemaData]);
                      }}
                    />
                  </div>
                </div>
                <div className="citisli">
                  {loading ? (
                    <NowShowingSkeleton countVal={1} />
                  ) : filterCinema.length > 0 ? (
                    filterCinema?.map((item, idx) => {
                      let url = `/cinemasessions/${localStorage.getItem(
                        "city"
                      )}/${item.name}/${item.theatreId}`;
                      let url2 = url.replace(/\s/g, "-");
                      localStorage.setItem("selectLoction", item);
                      return (
                        <>
                          {item.foodAvailable && (
                            <div
                              style={{ width: "100%" }}
                              className="col-md-6 col-12"
                              key={idx}
                            >
                              <div
                                className="dearch-box-mobile cinemalist"
                                state={item}
                                style={{ color: "black" }}
                                key={idx}
                                onClick={() => {
                                  CinemasSessionRequest(item, "NA");
                                }}
                              >
                                <div className="mobile-search-cinemas-show">
                                  <span className="cinema-head-msearch">
                                    {item?.name}
                                  </span>
                                  <div className="cinemakm">
                                    {checkLat?.latitude > 0 && (
                                      <div className="distance-show-m">
                                        {item?.distanceText &&
                                          item?.distanceText.replace(
                                            "away",
                                            ""
                                          )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="address-search-m">
                                  <p>{item?.address1}</p>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })
                  ) : (
                    <span className="mx-3">Cinema not found</span>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {getWindowWidth &&
        <Footer />
      }
      {showModalView === 'true' && TransactionType === "InCinemaFB" && cinemaDistance && visibleRight && cinemaDistance.distance < 15000 && (
        <Modal
          className={`${currentClasses.centerModal} usrLocMdl`}
          show={show}
          onHide={handleHide}
          animation={false}
          keyboard={false}
          dialogClassName={show ? "modal-dialog modal-hide" : "modal-dialog"}
          style={{
            backgroundColor: "rgba(0,0,0,0.7)",
            overflow: "hidden",
            zIndex: 99999999999,
          }}
        >
          {/* <div className="modalhdr flxlc">
					Near by location
					<p className="dislc">{cinemaDistance.distanceText}</p>
				</div> */}
          <div className="modalbody">
            {/* <img src={stopwatch} alt="" /> */}
            <h3>{cinemaDistance?.name}</h3>
            <p className="dislc">{cinemaDistance?.distanceText}</p>
            <p>{cinemaDistance?.address1}</p>

            <div className="fdrdmbtn">
              <button className="modalbtn cancelbtn" onClick={handleHide}>
                Explore Cinemas
              </button>
              <button
                className="modalbtn confirmbtn"
                onClick={() => {
                  CinemasSessionRequest(cinemaDistance, "NA");
                  handleConfirm();
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal>
      )}
      {showModalView === 'true' && TransactionType !== "InCinemaFB" && (
        <div>
          <Sidebar
            visible={visiblemapview}
            showCloseIcon={false}
            style={getWindowWidth && { maxWidth: "550px", borderRadius: "10px" }}
            position={getWindowWidth ? "center" : "bottom"}
            onHide={() => {
              setVisiblemapview(false);
            }}
            dismissable={true}
          >
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="mob-map-container">
                  <div className="mob-map-container-content">
                    <img src={foodGIF} alt="" /><br />
                    <p>{mapMsg}</p>
                    <a href="javascript:void(0)" onClick={() => { setVisiblemapview(false) }} className={
                      theme === "inoxTheme"
                        ? "confirm-btn-inox border-0"
                        : "confirm-btn-pvr border-0"
                    } style={{ display: "block", fontSize: "18px", borderRadius: "20px", padding: "8px 27px" }}>OKAY</a>
                    {/* <hr/> */}
                  </div>
                </div>
              </div>
            </div>
          </Sidebar>
          {seatDetailData?.length > 0 &&
            <Modal
              className={`${currentClasses.centerModal} upcmngModal`}
              show={show}
              onHide={handleHide}
              animation={false}
              keyboard={false}
              dialogClassName={show ? "modal-dialog modal-hide" : "modal-dialog"}
            >
              <div className="modalbody">
                <h3 className="upTitle">

                  {getWindowWidth && <Link to="/?tab=food" className="bckarw">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 6.75H12V5.25H6V0L0 6L6 12V6.75Z" fill="black" />
                    </svg>
                  </Link>}
                  Your upcoming movie bookings
                </h3>
                <p className="dislc">Select a movie to pre-order food</p>

                <div className="upcmnglist">
                  <div className="upcmnglistinr">

                    {seatDetailData?.map((item, index) => (
                      <div key={`upcomingMovies${index}`} className="movieupcmngdetails" onClick={() => handleUpcomingMovie(item)}>
                        {item?.filmName && (
                          <div className="movieImg">
                            <img
                              src={item?.filmImg || noImage}
                              width={getWindowWidth ? 60 : 50}
                              alt={item?.filmName || "No image available"}
                              onError={handleImageError}
                            />
                          </div>
                        )}
                        <div className="upcmovdets">
                          {item?.filmName && (
                            <h3 className="movUpTitle">{item?.filmName}</h3>
                          )}

                          {item?.showTime && (
                            <p className="cmnUpfnt">{format(item?.showTime, "eee, MMM dd, hh:mm a ")}</p>
                          )}
                          <div className="addflx">
                            {item?.theaterName && (
                              <p className="cmnUpfnt">{item?.theaterName}</p>
                            )}
                          </div>
                        </div>
                        {getWindowWidth ?
                          <div className="upbtnmain">
                            <button className="upcmnbtn">Select</button>
                          </div>
                          :
                          <svg className="rgtarwup" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.7809 5.33327L5.2049 1.75726L6.1477 0.814453L11.3332 5.99993L6.1477 11.1853L5.2049 10.2425L8.7809 6.6666H0.666504V5.33327H8.7809Z" fill="black" />
                          </svg>
                        }
                      </div>
                    ))}
                  </div>
                </div>

                {/* <div className="flxbtn">
                  {getWindowWidth && <Link to="/?tab=food" className="skipUpcmng">Back</Link>} */}
                <p onClick={handleSkipHide} className="skipUpcmng">Skip</p>
                {/* </div> */}
              </div>
            </Modal>
          }
        </div>
      )}

      <SweetAlertUtility type={alertDetails.type} title={alertDetails.title} message={alertDetails.message} show={alertDetails.show} onConfirm={hideAlert} />

      {showAlert &&
        <div>
          <SweetAlert
            error
            show={showAlert}
            title={errorMsg?.result === "dialog" ? "Oops" : errorMsg?.result}
            onConfirm={() => {
              setShowAlert(false);
              if (isBadTransaction) {
                navigate("/");
              } else {
                if (isMobile) {
                  navigate(`/m.payment/${localStorage.getItem("cinemaName")}`);
                } else {
                  navigate(`/payment/${localStorage.getItem("cinemaName")}`);
                }
              }
            }}
            onCancel={() => setShowAlert(false)}
            showConfirm={true}
            showCancel={false}
            confirmBtnText="OK"
            confirmBtnCssClass={
              theme === "inoxTheme"
                ? "confirm-btn-inox border-0"
                : "confirm-btn-pvr border-0"
            }
            closeOnClickOutside={false}
            closeOnEscape={false}
          >
            {errorMsg?.msg}
          </SweetAlert>
        </div>}

      {/* cinemaDistance */}
    </>
  );
};


export default CinemaLocationList;
