import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import * as appConstants from "../../../constants/index";
import { Chips } from "primereact/chips";
import explore from "../../../assets/payment/explore.svg";
import offerRight from "../../../assets/payment/offer-right-arrow.svg";
import arrowgrey from "../../../assets/payment/arrow-grey.svg";
import cred from "../../../assets/payment/cred.png";
import upi from "../../../assets/payment/UPI.svg";
import paytm from "../../../assets/payment/paytm-u.png";
import Samsungwallet from "../../../assets/payment/sumsunagwallet.png"
import { ProceedButton } from "../../common/login/loginTags";
import { useNavigate } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { InputText } from "primereact/inputtext";
import { setRetryPayment } from "src/services/CouponPayment";


const Upi = (props) => {
  const [value, setValue] = useState([]);

  const formRef = useRef(null);
  const theme = localStorage.getItem('theme');
  const bookingid = localStorage.getItem("bookingid");
  const subscription = localStorage.getItem('subscription')
  const loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
  const [value6, setValue6] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const [errorMsg, setErrorMsg] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    //getNetBankingList1();
    // console.log(props);
    initJuspay();
  }, []);

  const initJuspay = async () => {
    // console.log('working');
    var juspay_form = Juspay.Setup({
      payment_form: "#payment_form_upi",
      success_handler: function (status) {
        console.log(status);
      },
      error_handler: function (error_code, error_message, bank_error_code, bank_error_message, gateway_id) {
        console.log('error', error_code, error_message);
        setErrorMsg("Facing some challange: "+ error_message);
        setShowAlert(true);
        setLoading(false);
      },
    })
  }
  const getverifyVpa = async () => {
    if (!value6) {
      setErrorMsg("Please enter vpa!");
      setShowAlert(true);
      return '';
    }
    setLoading(true);
    const postData = {
      "bookingId": localStorage.getItem("bookingid"),
      "key": value6,
      "mandate": localStorage.getItem("booking_type") === "PASSPORT"?subscription == "true"?true:false:false
    };
    await axios
      .post(`${appConstants.base_url}v1/payment/juspay/vpa`, postData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") || ""
            }`,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      })
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          let output = dt.output;
          // console.log('working');
          // gethmac();
          if(localStorage.getItem("booking_type") === "PASSPORT" && subscription == "true" && output.mandate == "false"){
            setErrorMsg("UPI is not valid for subscription");
            setShowAlert(true);
            setLoading(false)
          }
          else if(output.valid == "true"){
            gethmac();
          }
        } else {
          setLoading(false);
          setErrorMsg(res.data.msg);
          setShowAlert(true);
          console.log(res.data.msg);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  }
  const gethmac = async () => {
    if (!value6) {
      setErrorMsg("Please enter vpa!");
      setShowAlert(true);
      return '';
    }
    setLoading(true);
    const postData = {
      "transId": localStorage.getItem("transid"),
      "bookingId": localStorage.getItem("bookingid"),
      "bookType": localStorage.getItem("booking_type") ? localStorage.getItem("booking_type") : 'BOOKING',
      "paytype": "UPI",
      "pgtype": "JUSPAY",
      "mandate": localStorage.getItem("booking_type") === "PASSPORT"?subscription == "true"?true:false:false
    };
    const vouDesc = localStorage.getItem("vouDesc") || "";
    const vouId = localStorage.getItem("vouId") || "";
    const discValue = localStorage.getItem("discValue") || "";
    const validity = localStorage.getItem("validity") || "";
    const itemCategory5 = `${vouDesc}, ${vouId}, ${discValue}, ${validity}`;

    await axios
      .post(`${appConstants.base_url}v1/payment/juspay/hmac`, postData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") || ""
            }`,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      })
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          localStorage.setItem('pgused','UPI');
          setRetryPayment(props.payMode, "retry-UPI"+localStorage.getItem("bookingid"));
          const url = window.location.href;
          let item_category = '';
          if (localStorage.getItem("tickettype") && localStorage.getItem("tickettype") === "food") {
            item_category= 'ticket,f&b';
          }
          else
          item_category= 'ticket' + "," + vouDesc;
          window.dataLayer.push({
            event: 'add_payment_info',
            ecommerce: {
              currency: 'INR',
              value: Number(res.data.output.amount),
              items: [{
                affiliation: 'CREDITCARD',                                                
                item_name: localStorage.getItem('movie_name'),  
                item_id: localStorage.getItem('movieid'),
                price: Number(res.data.output.amount),
                item_brand: appConstants.cinemaChain, 
                item_category: item_category,  
                item_category2: localStorage.getItem('cinema_name') + "/" + localStorage.getItem('city'),
                item_category3: localStorage.getItem('selecteddate')+","+localStorage.getItem('selectedseats'),                      //( selected date & seat number)
                item_category4: localStorage.getItem('genre'), 
                item_category5: itemCategory5,      
                item_variant: localStorage.getItem('format'),   
                quantity: localStorage.getItem('quantity')
              }]
            }
          });
          // setTicketSummary(ndt);
          //document.getElementById("payment_form_NB").submit();
          localStorage.setItem("paymentid", dt.output.paymentId);
          localStorage.setItem("payment_type", "nonwallet");
          console.log("bankList==>", dt);
          formRef.current.click();
        } else {
          setLoading(false);
          console.log(res.data.msg);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  }
  return (
    <>
      <SweetAlert
        error
        show={showAlert}
        title={errorMsg}
        onConfirm={() => {
          setShowAlert(false);
          // console.log('confirm')
          //navigate('/')
        }}
        onCancel={() => setShowAlert(false)}
        confirmBtnCssClass={theme === 'inoxTheme' ? 'confirm-btn-inox border-0' : 'confirm-btn-pvr border-0'}
      >
        {errorMsg}
      </SweetAlert>
      <div className="">
        <div className="payment-form">
          <div className="paywithdebit">
            <div className="debit-card show-in-desktop-view">
              <h6>UPI</h6>
            </div>
            <div className="to-paid">
              {/* <p>
                To be Paid:
                <span className="price-cross">₹8888</span>{" "}
                <span className="price-blod">₹8888</span>
              </p> */}
            </div>
          </div>
          <div className="payment-netbanking">
            <div className="upi-flow">
              <form className="juspay_inline_form" id="payment_form_upi">
                <InputText type="hidden" className="merchant_id" value={props.juspayinit ? props.juspayinit.merchant_id : ''} />
                <InputText type="hidden" className="order_id" value={bookingid} />
                {localStorage.getItem("booking_type") === "PASSPORT" && subscription == "true"?
                <>
                  <InputText style={{ "display": "none" }} type="checkbox" className="should_create_mandate" checked={subscription} value={subscription} />
                  <InputText type="hidden" className="mandate_type" value="EMANDATE" />
                </>
                :""}
                {/* <div className="upi-feilds">
                  <div className="p-fluid">
                    <span className="p-float-label">
                      <InputText
                        id="upi"
                        className="form-control upi_vpa"
                        value={value6}
                        onChange={(e) => {
                          setValue6(e.target.value)
                          // setValue(e.value);
                          // console.log('value', e.value)
                        }} />
                      <label htmlFor="upi">UPI ID</label>
                    </span>
                  </div>
                </div> */}
                <div className="col-md-12 my-3">
                  <div className="payment-feild">
                    <span className="p-float-label">
                    <InputText 
                    type="text" 
                    id="upi" 
                    className="offer-control upi_vpa" 
                    value={value6}
                    onChange={(e) => setValue6(e.target.value)} 
                    />
                    <label htmlFor="upi">UPI ID</label>
                    </span>
                  </div>
                  {props?.promotionText?
                    <p className="my-3" dangerouslySetInnerHTML={{ __html: props?.promotionText }}></p>
                  :""}
                </div>
                <div className="register-btn">
                  <ProceedButton
                    className={value6 && !loading? 'btn-proceeds' : "btn-proceeds btn-opacity"}
                    type="button"
                    onClick={() => {
                      getverifyVpa()
                      //navigate('/final-ticket')
                      // console.log("Button Clicked")
                    }}
                  >
                    Verify & Pay 
                    {loading ? (
                            <i
                              className="pi pi-spin pi-spinner"
                              style={{ fontSize: "1.5em" }}
                            ></i>
                          ) : (
                            ""
                          )}
                  </ProceedButton>
                  <button ref={formRef} type="submit" style={{ "display": "none" }}>
                    Pay
                  </button>
                </div>
                <div className="upi-collect">
                {/* {props?.promotionText?
                  <p dangerouslySetInnerHTML={{ __html: props?.promotionText }}></p>
                :""} */}
                  <p>A collect request notification will be sent to this UPI ID</p>
                </div>
                <InputText type="hidden" className="payment_method_type" value="UPI" />
                <InputText type="hidden" className="payment_method" value={localStorage.getItem("booking_type") === "PASSPORT" && subscription == "true"?"COLLECT":"UPI"} />
                <InputText type="hidden" className="txn_type" value="UPI_COLLECT" />
                <InputText type="hidden" className="redirect" value="true" />
                <InputText type="hidden" className="payment_channel" value="WEB" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Upi