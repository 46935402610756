import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import * as appConstants from "../../../constants/index";
import { ProceedButton } from "../../common/login/loginTags";

const AmazonPay = (props) => {
  const formRef = useRef(null);
  const bookingid = localStorage.getItem("bookingid");
  const theme = localStorage.getItem("theme");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //getNetBankingList1();
    console.log(props);
    initJuspay();
  }, []);

  const initJuspay = async() =>{
    console.log('working');
    var juspay_form = Juspay.Setup({
      payment_form: "#payment_form_wallet",
      success_handler: function(status) {
        console.log(status);
      },
      error_handler: function(error_code, error_message, bank_error_code, bank_error_message, gateway_id) {
        console.log('error', error_code, error_message);
      },
      customer: {

        customer_id: props.juspayinit?props.juspayinit.customer_id:'',// change to relevant customer id value

        client_auth_token: props.juspayinit?props.juspayinit.client_auth_token:'',// populate the active client_auth_token value fetched from Create Order/Create Customer API

      },
    })
  }
  const gethmac = async() =>{
    // if(!value6){
    //   setErrorMsg("Please enter vpa!");
    //   setShowAlert(true);
    //   return '';
    // }
    setLoading(true);
    const postData = {
      "transId": localStorage.getItem("transid"),
      "bookingId": localStorage.getItem("bookingid"),
      "bookType": localStorage.getItem("booking_type") === "GIFTCARD" ? 'GIFTCARD' : localStorage.getItem("booking_type") === "FOOD" ? 'FOOD' : 'BOOKING',
      "paytype": "WALLET",
      "pgtype": "JUSPAY"
    };
    const vouDesc = localStorage.getItem("vouDesc") || "";
    const vouId = localStorage.getItem("vouId") || "";
    const discValue = localStorage.getItem("discValue") || "";
    const validity = localStorage.getItem("validity") || "";
    const itemCategory5 = `${vouDesc}, ${vouId}, ${discValue}, ${validity}`;

    await axios
      .post(`${appConstants.base_url}v1/payment/juspay/hmac`, postData, {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("token") || ""
          }`,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
            city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      })
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          localStorage.setItem('pgused','PAYTM');
          const url = window.location.href;

          localStorage.setItem("cinema_name",url.split('/').pop().replaceAll('%20',' '));
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
          event: 'add_payment_info',
          ecommerce: {
            currency: 'INR',
            value: res.data.output.amount, 
            items: [{
            affiliation: 'AMAZON',                   //(payment type)                                                
            item_name: localStorage.getItem('movie_name'),                            // (ticket's name,passport's name,gift card's name)
            item_id: localStorage.getItem('movieid'),                             //(ticket's id,passport's id,gift card's id)
            price: res.data.output.amount,  
                                           //(total amount)
            //(total amount)
            item_brand: appConstants.cinemaChain,                       // (pvr/inox)
            item_category: 'ticket' +"," + vouDesc,                        //(selected ticket, gift card & passport)
            item_category2: localStorage.getItem('city')+"/"+localStorage.getItem('cinema_name'),  
            item_category3: localStorage.getItem('selecteddate')+","+localStorage.getItem('selectedseats'),                         //( selected date & seat number)
            item_category4: localStorage.getItem('genre'),   
            item_category4:  itemCategory5,                 //(language &  genre)
            item_variant: localStorage.getItem('format'),                      //( selected format)   
            quantity: localStorage.getItem('quantity') 

           
            }]
          }
        });
          // setTicketSummary(ndt);
          //document.getElementById("payment_form_NB").submit();
          localStorage.setItem("paymentid", dt.output.paymentId);
          localStorage.setItem("payment_type", "nonwallet");
          console.log("bankList==>", dt);
          formRef.current.click(); 
        } else {
          setLoading(false);
          console.log(res.data.msg);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  }
  return (
    <>
    <div className="">
      <div className="payment-form">
        <div className="paywithdebit ">
          <div className="debit-card show-in-desktop-view">
            <h6>{props?.payMode?.name}</h6>
          </div>
          <div className="to-paid">
            {/* <p>
              To be Paid:
              <span className="price-cross">₹8888</span>{" "}
              <span className="price-blod">₹8888</span>
            </p> */}
          </div>
        </div>
        <div className="cinema-house payment-postpaytm">
          {/* <h1>Enter OTP</h1>
          <p className="otp-content">You need to enter the OTP received</p>
          <h6>+91 {sendOTPFormik.values.mobileInput}</h6>
          <div className="otp-feild">
            <OtpInput
              value={mobileOtp}
              onChange={setMobileOtp}
              numInputs={6}
              inputStyle={{width:'60px'}}
              // renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} className="otpfeild" />}
            />
          </div> */}
          <div className="register-btn">
            <ProceedButton className={!loading?"btn-proceeds verfiy-paytm":"btn-proceeds verfiy-paytm btn-opacity"}  onClick={() => gethmac()}>
             Continue Pay {props?.payMode?.name}
             {loading ? (
                            <i
                              className="pi pi-spin pi-spinner"
                              style={{ fontSize: "1.5em" }}
                            ></i>
                          ) : (
                            ""
                          )}
            </ProceedButton>
          </div>
          {/* <div className="resend-otp">
            <h6
              style={{ cursor: "pointer" }}
              // onClick={() => reSendOTPService()}
            >
              Resend OTP
            </h6>
          </div> */}
          <div className="row pt-4">
            <div className="col-md-12">
              <div className="gyftr-box">
                  <p>Clicking would redirect you to AmazonPay’s payment page.</p>
                  {/* <div className="divider-gyftr"></div>
                  <p>*If there is an intent connectivity problem while booking the free tickets using your coupon codes and the tickets are not issued please wait for 30 minutes before trying to use the Coupon Codes again.</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <form className="juspay_inline_form" id="payment_form_wallet" style={{"display":"none"}}>
      <input type="hidden" className="merchant_id" value={props.juspayinit?props.juspayinit.merchant_id:''} />
      <input type="hidden" className="order_id" value={bookingid} />
      <input type="hidden" className="payment_method_type" value="WALLET" />
      <div className="">
          <div className="modal-tab-block">
              <div className="modal-tab-block">
                  <div className="form-group">
                      <div className="select-box">
                          <select className="payment_method" id="walletselect">
                              <option value="AMAZONPAY" label="AMAZONPAY" selected>AMAZONPAY</option>
                          </select>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <button ref={formRef} type="submit" className="make_payment">
                Pay
      </button>
      <input type="hidden" className="redirect" value="true" />
      <input type="hidden" className="payment_channel" value="WEB" />
      <input type="hidden" id="direct_wallet_token" className="direct_wallet_token" value="" />
    </form>
    </div>
  </>
  )
}

export default AmazonPay