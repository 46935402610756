import * as appConstants from "../../../constants/index";
import { RECAPTCHA_SITE_KEY } from "../../../constants/index"; 


/**
 * Executes Google reCAPTCHA and returns a token.
 * @returns {Promise<string>} reCAPTCHA token
 */
export const executeRecaptcha = () => {
    return new Promise((resolve, reject) => {
        if (window.grecaptcha) {
            window.grecaptcha.ready(() => {
                window.grecaptcha
                    .execute("6Lc_edEqAAAAAOz-ftKFlLck5WCfl5Bdjnr_UwcV", { action: "submit" })
                    .then(resolve)
                    .catch(reject);
            });
        } else {
            reject(new Error("reCAPTCHA not loaded"));
        }
    });
};
