import React, { useEffect, useState } from "react";
import "./moreDropdown.css";
import { useNavigate } from "react-router-dom";
import giftCardIcon from "../../../../assets/dropdowIcon/card_giftcard.svg";
import kotak from "../../../../assets/dropdowIcon/koktacredit.svg";
import advertiseIcon from "../../../../assets/dropdowIcon/newspaper_black.svg";
import pristineIcon from "../../../../assets/dropdowIcon/auto_awesome.svg";
import pvrcareIcon from "../../../../assets/dropdowIcon/care.svg";
import merchandiseIcon from "../../../../assets/dropdowIcon/local_mall_black.svg";
import movieOnDemand from "../../../../assets/dropdowIcon/theaters_black.svg";
import custoArrowB from "../../../../assets/cities/black-cities.svg";
import menubar from "../../../../assets/nav-icon/menubar.svg";
import OdrBlack from "../../../../assets/nav-icon/odr-black.svg";
import odrWhite from "../../../../assets/nav-icon/odr-white.svg";
import filmFestival from "../../../../assets/nav-icon/Film-festival-1.png";
import promoteEarn from "../../../../assets/nav-icon/Promote.svg";
import Screenit_icon from "../../../../assets/nav-icon/screenit_logo.png";
import { getSplashData } from "src/services/OtherServices";

const MoreDropdown = () => {
  const navigate = useNavigate();
  const theme = localStorage.getItem("theme");
  const [spalshData, setSpalshData] = useState(null);
  useEffect(() => {
    console.log(" moredropdown");
    profileRequest()
  }, [localStorage.getItem('city')]);
  const hitGa = (event) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: event,
    });
  };
  const hitMoreGa = (click_text) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: "top_navi_more_click",
      click_text: click_text,
    });
  };
  const profileRequest = async () => {
    getSplashData().then((res) => {
      const dt = res.data;
      if (dt.result === "success") {
        setSpalshData(dt.output)
      } else {
      }
    })
      .catch((err) => {
        console.log('error', err)
      })
  };
  return (
    <>

      <div className="more">
        <img src={menubar} alt="" className="img-fluid" />
        <span className="mx-2 more-center">More</span>
        <img src={custoArrowB} alt="" className="more-side" />
        <div className="more-content rounded">
          <ul>
            <li
              className="more-item"
              onClick={() => {
                const currentPath = window.location.pathname;
                hitMoreGa("Gift Cards");
                if (currentPath === "/gift-cards") {
                  window.location.href = "/gift-cards";
                } else {
                  navigate("/gift-cards"); // Use your navigation function here
                }
              }}
            >
              <img src={giftCardIcon} alt="" className="mx-2" />
              Gift Card
            </li>
            <li
              className="more-item"
              onClick={() => {
                hitGa("top_navi_offers");
                window.open("https://smartodr.in/login", '_blank').focus();
              }}
            >
              <div className="nav-icons">
                {theme === "inoxTheme" ? (
                  <img src={OdrBlack} alt="" className="img-fluid mx-2" />
                ) : (
                  <img src={OdrBlack} alt="" className="img-fluid mx-2" />
                )}
              </div>
              <div
                className="useful-link"
                onClick={() => { hitGa("ODR_PORTAL") }}
              >
                ODR Portal
              </div>
            </li>

            <li
              className="more-item"
              onClick={() => {
                const currentPath = window.location.pathname;
                hitMoreGa("Bulk Gift Cards");
                if (currentPath === "/bulk-gift-cards") {
                  window.location.href = "/bulk-gift-cards";
                } else {
                  navigate("/bulk-gift-cards"); // Use your navigation function here
                }
              }}
            >
              <img src={giftCardIcon} alt="" className="mx-2" />
              Bulk Gift Card
            </li>
            {/* <li
                            className='more-item'
                            onClick={() => {  hitMoreGa("Kotak Privilege+");
                            navigate('/#')}}
                        >
                            <img src={kotak} alt="" className="mx-2" />
                            Kotak Privilege+
                        </li> */}
            <li
              className="more-item"
              onClick={() => {
                hitMoreGa("Advetise");
                const currentPath = window.location.pathname;
                if (currentPath === "/advertise") {
                  window.location.href = "/advertise";
                } else {
                  navigate("/advertise"); // Use your navigation function here
                }
              }}
            >
              <img src={advertiseIcon} alt="" className="mx-2" />
              Advertise
            </li>
            {/* <li
                            className='more-item'
                            onClick={() => {
                                hitGa('vpristine');
                                hitMoreGa('vpristine');
                                window.open(
                                    theme === "inoxTheme"
                                        ? "https://www.pvrcinemas.com/pvrstatic/PVR-VpristineB2C/"
                                        : "https://www.pvrcinemas.com/pvrstatic/PVR-VpristineB2C/",
                                    "_blank"
                                );
                             
                          
                            }}
                        >
                            <img src={pristineIcon} alt="" className="mx-2" />
                            V Pristine
                        </li> */}
            {/* <li
                            className='more-item'
                            onClick={() => {
                                                                hitGa('pvr_cares');

                                window.open(
                                    theme === "inoxTheme"
                                      ? "https://www.pvrcinemas.com/pvrstatic/pvr-care/"
                                      : "https://www.pvrcinemas.com/pvrstatic/pvr-care/",
                                    "_blank"
                                  );
                            }}
                        >
                            <img src={pvrcareIcon   } alt="" className="mx-2" />
                            PVR Cares
                        </li> */}
            <li
              className="more-item"
              onClick={() => {
                hitMoreGa("Merchandise");

                window.open(
                  theme === "inoxTheme"
                    ? "https://inox.macmerise.com/"
                    : "https://pvr.macmerise.com/?user_agent=pvr/",
                  "_blank"
                );
              }}
            >
              <img src={merchandiseIcon} alt="" className="mx-2" />
              Merchandise
            </li>
            {spalshData?.pvrlive ?
              <li
                className='more-item'
                onClick={() => {
                  hitGa('live_movies');
                  const currentPath = window.location.pathname;
                  if (currentPath === '/live-movies') {
                    window.location.href = '/live-movies';
                  } else {
                    navigate('/live-movies'); // Use your navigation function here
                  }
                }}
              >
                <img src={filmFestival} alt="" className="mx-2" />
                {spalshData?.pvrlive}
              </li>
              : ""}

            <li
              className='more-item'
              onClick={() => {
                hitGa('screenit_click');
                const currentPath = window.location.pathname;
                if (currentPath === '/screenit') {
                  window.location.href = '/screenit';
                } else {
                  navigate('/screenit'); // Use your navigation function here
                }
              }}
            >
              <img src={Screenit_icon} alt="" className="mx-2" />
              ScreenIT
            </li>



            <li
              className='more-item'
              onClick={() => {               
                const currentPath = window.location.pathname;
                if (currentPath === '/promote-earn  ') {
                  window.location.href = '/promote-earn';
                } else {
                  navigate('/promote-earn');
                }
              }}
            >
              <img src={promoteEarn} alt="" className="mx-2" />
              Promote & Earn
            </li> 
            <li>
              <div className="menu-divider"></div>
            </li>
            {theme === "inoxTheme" ? (
              ""
            ) : (
              <li
                className="more-item mx-2"
                onClick={() => {
                  hitMoreGa("Privilege Plus");

                  const currentPath = window.location.pathname;
                  if (currentPath === "/kotak-pp-plus") {
                    window.location.href = "/kotak-pp-plus";
                  } else {
                    navigate("/kotak-pp-plus"); // Use your navigation function here
                  }
                }}
              >
                Privilege Plus
              </li>
            )}
            <li
              className="more-item mx-2"
              onClick={() => {
                hitMoreGa("About us");

                const currentPath = window.location.pathname;
                if (currentPath === "/about-us") {
                  window.location.href = "/about-us";
                } else {
                  navigate("/about-us"); // Use your navigation function here
                }
              }}
            >
              About us
            </li>
            <li
              className="more-item mx-2"
              onClick={() => {
                hitMoreGa("Experiences");

                const currentPath = window.location.pathname;
                if (
                  currentPath ===
                  "/experience/" + localStorage.getItem("city")
                ) {
                  window.location.href =
                    "/experience/" + localStorage.getItem("city");
                } else {
                  navigate("/experience/" + localStorage.getItem("city")); // Use your navigation function here
                }
              }}
            >
              Experiences
            </li>
            <li
              className="more-item mx-2"
              onClick={() => {
                hitMoreGa("coroporate-booking");
                const currentPath = window.location.pathname;
                if (currentPath === "/bulkbooking/coroporate-booking") {
                  window.location.href = "/bulkbooking/coroporate-booking";
                } else {
                  navigate("/bulkbooking/coroporate-booking"); // Use your navigation function here
                }
              }}
            >
              Corporate Booking
            </li>
            {/* <li
              className="more-item mx-2"
              onClick={() => {
                const currentPath = window.location.pathname;
                if (currentPath === "/oscar") {
                  window.location.href = "/oscar";
                } else {
                  navigate("/oscar");
                }
              }}
            >
              Oscar
            </li> */}
            <li
              className="more-item mx-2"
              onClick={() => {
                hitMoreGa("FAQ");

                const currentPath = window.location.pathname;
                if (currentPath === "/faq") {
                  window.location.href = "/faq";
                } else {
                  navigate("/faq"); // Use your navigation function here
                }
              }}
            >
              FAQs
            </li>
            <li
              className="more-item mx-2"
              onClick={() => {
                hitMoreGa("Career");

                const currentPath = window.location.pathname;
                if (currentPath === "/careers-us") {
                  window.location.href = "/careers-us";
                } else {
                  hitGa("career");

                  navigate("/careers-us"); // Use your navigation function here
                }
              }}
            >
              Career
            </li>
            <li
              className="more-item mx-2"
              onClick={() => {
                // hitMoreGa('Feedback');

                const currentPath = window.location.pathname;
                if (currentPath === "/feedback") {
                  window.location.href = "/feedback";
                } else {
                  // hitGa('feedback')
                  hitMoreGa("Feedback");
                  hitGa("feedback");
                  navigate("/feedback"); // Use your navigation function here
                }
              }}
            >
              Feedback
            </li>
            {/* <li
                            className='more-item mx-2'
                            onClick={() => navigate('/advertise')}
                        >
                           Advertise
                        </li> */}
            <li
              className="more-item mx-2"
              onClick={() => {
                hitMoreGa("News");

                const currentPath = window.location.pathname;
                if (currentPath === "/news") {
                  window.location.href = "/news";
                } else {
                  navigate("/news"); // Use your navigation function here
                }
              }}
            >
              News
            </li>
            {/* <li
                            className='more-item mx-2'
                            onClick={() => {
                                hitMoreGa('Beyond Movies');

                                const currentPath = window.location.pathname;
                                if (currentPath === '/beyond-movies') {
                                    window.location.href = '/beyond-movies';
                                } else {
                                    navigate('/beyond-movies'); // Use your navigation function here
                                }
                            }}
                        >
                           Beyond Movies
                        </li> */}
            {/* <li
              className="more-item mx-2"
              onClick={() => {
                hitMoreGa("Investors Section");

                const currentPath = window.location.pathname;
                if (currentPath === "/investors-section") {
                  window.location.href = "/investors-section";
                } else {
                  hitGa("investors-section");
                  navigate("/investors-section"); // Use your navigation function here
                }
              }}
            >
              Investors Section
            </li> */}
            <li
              className="more-item mx-2"
              onClick={() => {
                hitMoreGa("NVSP");

                window.open(
                  theme === "inoxTheme"
                    ? "https://voters.eci.gov.in/"
                    : "https://voters.eci.gov.in/",
                  "_blank"
                );
              }}
            >
              NVSP
            </li>
            <li
              className="more-item mx-2"
              onClick={() => {
                hitMoreGa("NEST");

                window.open("https://pvrnest.godaddysites.com/home", "_blank");
              }}
            >
              NEST
            </li>
            {/* <li
                            className='more-item mx-2'
                            onClick={() => {
                                hitMoreGa('Birthday Request');

                                const currentPath = window.location.pathname;
                                if (currentPath === '/birthday-request') {
                                    window.location.href = '/birthday-request';
                                } else {
                                    navigate('/birthday-request'); // Use your navigation function here
                                }
                            }}
                        >
                           Birthday Request
                        </li> */}
          </ul>
        </div>
      </div>

    </>
  );
};

export default MoreDropdown;
