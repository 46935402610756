import axios from 'axios';
import * as appConstants from "../constants/index";
const theme = localStorage.getItem("theme");

let splashData;
let financialData;
let loading = false;
let PromotionalData = null;
export const getDelSplashData = async () => {
    splashData = null;
}

export const getSplashData = async () => {
    if (splashData) return splashData;
    if (loading) {
        // Wait for the ongoing request to finish and then return the cached data
        return new Promise((resolve) => {
            const interval = setInterval(() => {
                if (!loading) {
                    clearInterval(interval);
                    resolve(splashData);
                }
            }, 100);
        });
    }

    try {
        loading = true;
        const response = await axios.post(
            `${appConstants.base_url}v1/booking/content/splash`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    chain: theme === "inoxTheme" ? "INOX" : "PVR",
                    city: localStorage.getItem("city")|| "Delhi-NCR",
                    appVersion: appConstants.av,
                    platform: appConstants.pt,
                    country: appConstants.cname,
                },
            }
        );
        splashData = response; 
        return splashData;
    } catch (error) {
        console.error("Failed to fetch splash data:", error);
        throw error;
    } finally {
        loading = false;
    }
};


export const getPromotionalData = async () => {
    if (PromotionalData) return PromotionalData; 
    if (loading) {        
        return new Promise((resolve) => {
            const interval = setInterval(() => {
                if (!loading) {
                    clearInterval(interval);
                    resolve(PromotionalData); 
                }
            }, 100);
        });
    }

    try {
        loading = true;
        const response = await axios.get(
            `${appConstants.base_url}v1/user/promotional/get`, 
            {
                params: {}, // Any query params
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    chain: theme === "inoxTheme" ? "INOX" : "PVR",
                    city: localStorage.getItem("city"),
                    appVersion: appConstants.av,
                    platform: appConstants.pt,
                    country: appConstants.cname,
                },
            }
        );

        PromotionalData = response.data;        
        // Use response.data to get the actual data
        return PromotionalData;
    } catch (error) {
        console.error("Failed to fetch promotional data:", error);
        throw error;
    } finally {
        loading = false; 
    }
};


export const getFinancialDataApi = async ()=>{
    if(financialData) return financialData; 
    else{
        const response = await axios
        .post(
            `${appConstants.base_url}v1/web/financials`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    chain: theme === "inoxTheme" ? "INOX" : "PVR",
                    city: localStorage.getItem("city"),
                    appVersion: appConstants.av,
                    platform: appConstants.pt,
                    country: appConstants.cname,
                },
            }
        );
        financialData = response;
        return response;
    }
} 
export const giftCardBallance = async (data) =>{
    const response = await axios
        .post(
            `${appConstants.base_url}v1/giftcard/balance`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    chain: theme === "inoxTheme" ? "INOX" : "PVR",
                    city: localStorage.getItem("city"),
                    appVersion: appConstants.av,
                    platform: appConstants.pt,
                    country: appConstants.cname,
                },
            }
    );
    return response;
}

export const getProfileVoucher = async ()=>{
    
    const response = await axios
    .get(
        `${appConstants.base_url}v1/history/vouchers`,
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                chain: theme === "inoxTheme" ? "INOX" : "PVR",
                city: localStorage.getItem("city"),
                appVersion: appConstants.av,
                platform: appConstants.pt,
                country: appConstants.cname,
                "Content-Type": "application/json",
            },
        });
    return response;
} 
export const getGoogleWalletApi = async (bookingid, booktype)=>{
    const response = await axios
    .post(
        `${appConstants.base_url}v1/wallet/google/${bookingid}/${booktype}`,
        {},
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                chain: theme === "inoxTheme" ? "INOX" : "PVR",
                city: localStorage.getItem("city"),
                appVersion: appConstants.av,
                platform: appConstants.pt,
                country: appConstants.cname,
            },
        }
    );
    return response;    
}
export const formatTime = async (hour) => {
    // console.log(hour);
  
    if (hour !== undefined && hour !== null) {
      if (hour < 0 || hour > 24) {
        return "Invalid hour";
      }
      const period = hour >= 12 ? "PM" : "AM";
      const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
      return `${formattedHour.toString().padStart(2, "0")}:00 ${period}`;
    } else {
      return "";
    }
  };