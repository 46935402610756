import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import FbHeader from './component/FbHeader'
import { useDispatch, useSelector } from 'react-redux';
import { constantsTypes } from 'src/redux/reduxConstants';
import emoji from '../../assets/food/emoji.svg'
import { TabPanel, TabView } from 'primereact/tabview';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import * as appConstants from "../../constants/index";
import SweetAlertUtility from './component/SweetAlertUtility';
import Navbar from '../common/navbar/Navbar';
import { getResponsiveClasses } from './component/ResponsiveClasses';

const OffersCardDetailPage = () => {
    const [showOffer, setShowOffer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [cardType, setCardType] = useState('CC');
    const [cardNumber, setCardNumber] = useState('');
    const [cardError, setCardError] = useState(false);
    const [errMsg, setErrMsg] = useState(false);
    const [offerApplied, setOfferApplied] = useState(false);
    const [alertDetails, setAlertDetails] = useState({ show: false, type: '', title: '', message: '' });

    const { currentClasses, getWindowWidth } = getResponsiveClasses();

    const authToken = "Bearer " + localStorage.getItem("token");
    const theatreId = localStorage.getItem("TheatreId");
    const transactionType = localStorage.getItem("TransactionType");
    const appliedVocher = JSON.parse(localStorage.getItem("AppliedOfferData"));
    const storedDataString = JSON.parse(localStorage.getItem("getAllFnbData"));

    const finalAmount = appliedVocher?.resOffer?.output?.dp;

    const { name } = useParams();
    const pathname = name.replaceAll("-", " ");
    const location = useLocation();
    const applyVpucherState = location?.state?.bankCurrentDataState;
    const navigate = useNavigate();

    const creditCardInputRef = useRef(null);
    const debitCardInputRef = useRef(null);

    const userInfo = localStorage.getItem("loginInfo");
    const userInfoData = JSON.parse(userInfo);
    const userSelectedFood = JSON.parse(localStorage.getItem("getAllFnbData"));


    const handleOfferClose = () => {
        setShowOffer(false);
        navigate("/fnb-checkout");
    };

    const handleChange = (e) => {
        let value = e.target.value.replace(/\D/g, '');
        value = value.replace(/(.{4})/g, '$1 ').trim();
        setCardNumber(value);
    };

    const handleCard = async (e) => {
        e.preventDefault();

        if (cardNumber.replaceAll(" ", "").trim() === "") {
            setCardError(true);
            setErrMsg("Enter card number");
            return;
        } else if (!/^\d+$/.test(cardNumber.replaceAll(" ", "")) || cardNumber.length < 19) {
            setCardError(true);
            setErrMsg("Enter valid card number");
            return;
        } else {
            localStorage.setItem('cardDetails', JSON.stringify({ cardType, cardNumber }));
            setCardError(false);



            const qrsData = transactionType === "InCinemaFB" ? true : false;

            const formData = {
                ccode: theatreId || "",
                bookingId: localStorage.getItem("bookingid") || 0,
                transId: localStorage.getItem("transid") || 0,
                bookType: "FOOD",
                couponId: applyVpucherState?.couponId,
                coupon: applyVpucherState?.coupon,
                couponType: "BANKOFFER" || "",
                qr: "No",
                card: cardNumber.replaceAll(" ", "") || "",
                cardType: cardType || "",
                mobile: userInfoData?.data?.mob || "",
                otp: "",
                qrs: qrsData,
            };

            setLoading(true);

            try {
                const response = await axios.post(`${appConstants.base_url}v1/payment/coupon/apply`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: authToken,
                            chain: localStorage.getItem("theme") === "inoxTheme" ? "INOX" : "PVR",
                            appVersion: appConstants.av,
                            platform: appConstants.pt, //"WEBSITE", //,
                            country: appConstants.cname
                        },
                    }
                );
                // debugger
                if (response.data.result === "success") {
                    setOfferApplied(response.data);

                    const AppliedOfferData = {
                        vouDesc: pathname,
                        vouId: applyVpucherState.vouId,
                        resOffer: response.data,
                        vouPayType: cardType || "Auto",
                        cardNumber: cardNumber.replaceAll(" ", "")
                    };

                    localStorage.setItem('AppliedOfferData', JSON.stringify(AppliedOfferData));

                    const existGetAllFnbData = JSON.parse(localStorage.getItem("getAllFnbData"))
                    existGetAllFnbData.AppliedOfferDataNew = AppliedOfferData;
                    AppliedOfferData.ApplyOfferRespn = response?.data;
                    localStorage.setItem('getAllFnbData', JSON.stringify(existGetAllFnbData));

                    setShowOffer(true);
                } else {
                    setCardError(true);
                    setErrMsg(response.data.msg);
                }
            } catch (error) {
                sweetAlertError("Something went wrong!")
                console.error('Failed to fetch splash data:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (activeIndex === 0 && creditCardInputRef.current) {
            creditCardInputRef.current.focus();
        } else if (activeIndex === 1 && debitCardInputRef.current) {
            debitCardInputRef.current.focus();
        }
    }, [activeIndex]);

    const sweetAlertError = (msg) => {
        setAlertDetails({
            show: true,
            type: 'error',
            title: 'Error!',
            message: msg,
        });
    };

    const hideAlert = () => {
        setAlertDetails({ show: false });
    }

    return (
        <>
            {getWindowWidth &&
                <div className="bg-white show-in-desktop-view" style={{ position: "sticky", top: "0", zIndex: "999" }}>
                    <Navbar />
                </div>
            }
            <div className={currentClasses.fnbdeskdiv}>
                <div className={currentClasses.fnbdeskdivInn}>
                    <FbHeader headname={pathname} />
                    <div className='ttlamt'>
                        <p>To be paid</p>
                        <p> {finalAmount
                            ? `₹${finalAmount}`
                            : `₹${userSelectedFood?.fnAllData.reduce((total, item) => {
                                const totalPrice = item?.price || 0;
                                const addonPrice = item?.addons?.reduce((addonTotal, addon) => {
                                    return addonTotal + (addon?.price || 0);
                                }, 0);
                                return total + totalPrice + (addonPrice * 100);
                            }, 0) / 100
                                .toFixed(2)}`}</p>
                    </div>
                    <div className='contentPage'>
                        <TabView
                            className='cardTabs'
                            activeIndex={activeIndex}
                            onTabChange={(e) => {
                                setActiveIndex(e.index);
                                setCardType(e.index === 0 ? 'CC' : 'DC');
                                setCardNumber('');
                                setCardError(false);
                            }}
                        >
                            <TabPanel header="Credit Card">
                                <div className="frmgrp">
                                    <label>Card Number</label>
                                    <input
                                        type="tel"
                                        className='frmcntrl'
                                        name="cardno"
                                        id="cardno"
                                        placeholder='XXXX XXXX XXXX XXXX'
                                        value={cardNumber}
                                        maxLength={19}
                                        onChange={handleChange}
                                        ref={creditCardInputRef}
                                        autoComplete="off"
                                    />
                                    {cardError && <p className='errmsg'>{errMsg}</p>}
                                </div>
                            </TabPanel>
                            <TabPanel header="Debit Card">
                                <div className="frmgrp">
                                    <label>Card Number</label>
                                    <input
                                        type="tel"
                                        className='frmcntrl'
                                        name="cardno"
                                        id="cardno"
                                        placeholder='XXXX XXXX XXXX XXXX'
                                        value={cardNumber}
                                        maxLength={19}
                                        onChange={handleChange}
                                        ref={debitCardInputRef}
                                        autoComplete="off"
                                    />
                                    {cardError && <p className='errmsg'>{errMsg}</p>}
                                </div>
                            </TabPanel>
                        </TabView>
                        <div className={currentClasses.applyButton}><button className="cmnbtn w100" onClick={handleCard}>{loading ? 'Processing...' : 'Apply'}</button></div>
                        {applyVpucherState?.tnc && applyVpucherState?.tnc.length > 0 && <div className='cnd'>
                            <h3>Terms & Conditions</h3>
                            {applyVpucherState?.tnc}

                        </div>}
                    </div>
                </div>
            </div>


            <style>
                {`
                .modal.show .modal-dialog {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .modal-content {
                    max-width: 85vw;
                    position: relative;
                    border-radius: 20px;
                }
                `}
            </style>
            <Modal
                show={showOffer}
                onHide={handleOfferClose}
                animation={false}
                keyboard={false}
                style={{ backgroundColor: "rgba(0,0,0,0.7)", overflow: "hidden" }}
            >
                <div className="fb-o-m">
                    <img className="fb-o-emoji" src={emoji} alt="" />
                    <p className="fb-m-code">'{pathname}' Applied!</p>
                    <div className="fb-m-det">
                        <p className="fb-m-price">₹{offerApplied?.output?.di}</p>
                        <p className="fb-m-saved">saved with this</p>
                        <p className="fb-m-offP">Food Offer</p>
                    </div>
                    <button onClick={handleOfferClose} className="fb-m-aBtn">
                        Awesome!
                    </button>
                </div>
            </Modal>

            <SweetAlertUtility type={alertDetails.type} title={alertDetails.title} message={alertDetails.message} show={alertDetails.show} onConfirm={hideAlert} />
        </>
    )
}

export default OffersCardDetailPage
