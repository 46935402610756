import { combineReducers } from "redux";
import { viewModeValue } from "./viewModeReducer";
import { trailerViewMode } from "./trailerModeReducer";
import { cartReduceHandler } from "./cartReducer";
import { loginViewMode } from "./loginViewReducer";
import { movieDetailsViewMode } from "./viewMovieDetailsReducers";
import { mobileFilterReduce } from "./mobileFilterReducer";
import { nowShowingMoviesReduce } from "./nowShowingMoviesReaducers";
import { comingSoonMovieReduce } from "./comingsoonMoviesReducers";
import { grandTotalData } from "./grandTotalReducers";
import { cityChangeData } from "./cityReducer";
import { addToCartReduceHandler } from "./addToCartReducer"; //AurusIt Developer
// import {orderReducer} from "./addToCartReducer";
//import orderReducer from "./orderReducer";

export default combineReducers({
  viewModeValue,
  trailerViewMode,
  cartReduceHandler,
  addToCartReduceHandler, //AurusIt Developer
  loginViewMode,
  movieDetailsViewMode,
  mobileFilterReduce,
  nowShowingMoviesReduce,
  comingSoonMovieReduce,
  grandTotalData,
  cityChangeData,
  //orderReducer
});
