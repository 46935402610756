import React, { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import avtar from "../../../../assets/profile/avtar.svg";
import arrow from "../../../../assets/profile/navigatenext.svg";
import offer from "../../../../assets/profile/offer.svg";
import info from "../../../../assets/profile/info.svg";
import { ProgressBar } from "primereact/progressbar";
import { FaPencilAlt } from "react-icons/fa";
import { useFormik } from "formik";
import * as appConstants from "../../../../constants/index";
import swal from "sweetalert";
import ArrowRight from "../../../../assets/profile/arrowright.svg";
import { InputText } from "primereact/inputtext";
import mail from "../../../../assets/static-page/mail.svg";
import phone from "../../../../assets/static-page/phone.svg";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { ProceedButton } from "../../../gift-cards/giftCardTags";
import custoArrow from "../../../../assets/cities/black-cities.svg";
import calendarIcon from "../../../../assets/movie-on-demand/calnder.svg";
import { AlsoAddBtn } from "src/views/get-food/getfoodTags";
import axios from "axios";
import { DarkButton, LightButton } from "src/styled-components/customButtons";
import angle_left from "../../../../assets/mobilemoviedetail/arrow-b.svg";
import EditPencil from "../../../../assets/profile/editblack.svg";

const ProfilePersonalDeatils = () => {
  const theme = localStorage.getItem("theme");
  const [visibleRight, setVisibleRight] = useState(false);
  const [sidebarViewMode, setSidebarViewMode] = useState(0);
  const [activeStepIndex, setActiveStepIndex] = useState(1);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(null);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const token = localStorage.getItem('token');

  const maritalOption = [
    { name: "Married", code: "Married" },
    { name: "Unmarried", code: "Unmarried" },
  ];
  const genderOption = [
    { name: "Male ", code: "Male" },
    { name: "Female", code: "Female" },
    { name: "Transgender", code: "Transgender" },
    { name: "Other ", code: "Other" },
  ];

  useEffect(() => {
    getProfileData();
   hitGa("profile_personal_details")
  }, []);

  const personalDetailsFormik = useFormik({
    initialValues: {
      name: "",
      email: "",
      gender: "",
      maritalStatus: "",
      dob: "",
      doa: "",
    },
    validate: (data) => {
      let errors = {};
      let nameVal;
      if (data?.name?.length) {
        nameVal = data?.name?.split(" ");
        // console.log("name", nameVal?.length, nameVal);
      }
      if (!data?.name) {
        errors.name = "Please enter your name";
      } else if (nameVal?.length == 1) {
        errors.name = "Please enter your full name";
      } else if (nameVal?.length > 1 && nameVal[1] === "") {
        errors.name = "Last Name is mandatory";
      }
      if (!data.email) {
        errors.email = "Please enter your email";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
      ) {
        errors.email = "Invalid email address.";
      }
      if (!data.gender) {
        errors.gender = "Please select gender";
      }
      if (!data.maritalStatus) {
        errors.maritalStatus = "Please select marital status";
      }
      const timeDifference =
        new Date(data?.doa)?.getTime() - new Date(data?.dob)?.getTime();
      const eighteenYearsInMillis = 18 * 365 * 24 * 60 * 60 * 1000;
      if (!data.dob) {
        errors.dob = "Please select date of birth";
      }
      if (
        !data.doa &&
        personalDetailsFormik.values.maritalStatus === "Married"
      ) {
        errors.doa = "Please select marriage anniversary";
      }
      if (data.doa && personalDetailsFormik.values.maritalStatus === "Married") {
        if (timeDifference < eighteenYearsInMillis) {
          errors.doa = "Anniversary date must be 18 years greater than DOB.";
        }
      }
      // console.log("erroe", errors);
      return errors;
    },
    onSubmit: (data) => {
      updateProfieDetails(data);
      // console.log("formikData", data);
    },
  });
  const isPersonalDetailsFormFieldValid = (name) =>
    !!(
      personalDetailsFormik.touched[name] && personalDetailsFormik.errors[name]
    );
  const getPersonalDetailsFormErrorMessage = (name) => {
    return (
      isPersonalDetailsFormFieldValid(name) && (
        <small className="p-error">{personalDetailsFormik.errors[name]}</small>
      )
    );
  };

  // console.log("profile", profile);

  const getProfileData = async () => {
    await axios
      .post(
        `${appConstants.base_url}v1/user/profile`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            chain: theme === "inoxTheme" ? "INOX" : "PVR",
            city: localStorage.getItem("city"),
            appVersion: appConstants.av,
            platform: appConstants.pt,
            country: appConstants.cname,
          },
        }
      )
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          setProfile(dt.output);
        } else {
          setLoading(false);
          // swal(res.data.msg);
        }
      })
      .catch((error) => {
        const err = error?.response;
        if (err?.status === 403) {
          // refraceTokenService();
          console.log(
            "Forbidden: You do not have permission to access this resource."
          );
        } else {
          console.log("Error:", error.message);
        }
      });
  };

  const exactMinDate = new Date();
  exactMinDate.setFullYear(exactMinDate.getFullYear() - 13);
  exactMinDate.setHours(0, 0, 0, 0);
  const minAnniversaryDate = new Date(personalDetailsFormik.values.dob);
  minAnniversaryDate.setFullYear(minAnniversaryDate.getFullYear() + 18);
  minAnniversaryDate.setHours(0, 0, 0, 0);

  const updateProfieDetails = async (data) => {
    setLoading(true);
    const inputDobDate = data.dob;

    const day = inputDobDate.getDate();
    const month = inputDobDate.getMonth() + 1;
    const year = inputDobDate.getFullYear();
    const finalDate = `${day}-${month}-${year}`;

    const inputDoaDate = data.doa;
    const doaday = inputDoaDate.getDate();
    const doamonth = inputDoaDate.getMonth() + 1;
    const doayear = inputDoaDate.getFullYear();
    const finalDoaDate = `${doaday}-${doamonth}-${doayear}`;
    const postData = {
      name: data.name,
      email: data.email,
      gender: data.gender,
      maritalStatus: data.maritalStatus,
      dob: finalDate,
      doa: data.maritalStatus === "Married" ? finalDoaDate : "",
    };
    try {
      await axios
        .post(`${appConstants.base_url}v1/user/edit`, postData, {
          headers: {
            Authorization: `Bearer ${token}`,
            chain: theme === "inoxTheme" ? "INOX" : "PVR",
            city: localStorage.getItem("city"),
            appVersion: appConstants.av,
            platform: appConstants.pt,
            country: appConstants.cname,
          },
        })
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            // console.log('dt', dt)
            swal({
              text: dt.msg,
              timer: 3000,
              icon: "success",
              buttons: false,
            });
            getProfileData();
            setShowEditProfile(false);
            setLoading(false);
          } else {
            swal({
              text: dt.msg,
              timer: 2000,
              icon: "error",
              buttons: false,
            });
            setLoading(false);
          }
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const profileCompleteStatics = () => {
    const stepsItems = [
      {
        label: "Provide your contact number",
        command: (event) => {
          console.log("");
        },
      },
      {
        label: "Your emial",
        command: (event) => {
          console.log("");
        },
      },
      {
        label: "Gender information",
        command: (event) => {
          console.log("");
        },
      },
      {
        label: "Date of birth",
        command: (event) => {
          console.log("");
        },
      },
      {
        label: "Anniversary",
        command: (event) => {
          console.log("");
        },
      },
    ];
    return (
      <>
        <div className="drawer-complete-mobile">
          <h3>Complete Your Profile</h3>
          <span
            onClick={() => {
              setVisibleRight(false);
            }}
          >
            <i
              className="pi pi-times cinema-search-mobile-cross"
              style={{ color: "#000", cursor: "pointer" }}
            ></i>
          </span>
        </div>
        <div
          className={
            theme === "inoxTheme"
              ? "mobile-profile-complete "
              : "mobile-profile-complete  "
          }
        >
          {/* <h5>{profile?.percentage}%</h5> */}
          <ProgressBar
            // style={theme === 'inoxTheme' ? {backgroundColor:"#234B9E"}:{backgroundColor:'#FFCB05'}}
            color={theme === "inoxTheme" ? "#234B9E" : "#FFCB05"}
            value={profile?.percentage}
          ></ProgressBar>
          {/* <p>{profile?.msg}</p> */}
        </div>
        <div className="Show-progressbar-line-mobile">
          <p className="provide-contact-update">
            <span
              className={
                theme === "inoxTheme"
                  ? "border rounded-circle bg-dark-inox text-white"
                  : "border rounded-circle bg-dark-pvr text-white"
              }
            >
              {" "}
              <i
                className={
                  profile?.data?.mob ? "pi pi-check mr-2" : "pi pi-times mr-2"
                }
              />
            </span>{" "}
            Provide your contact number
          </p>
          <p className="provide-contact-update">
            <span
              className={
                theme === "inoxTheme"
                  ? "border rounded-circle bg-dark-inox text-white"
                  : "border rounded-circle bg-dark-pvr text-white"
              }
            >
              <i
                className={
                  profile?.data?.em ? "pi pi-check mr-2" : "pi pi-times mr-2"
                }
              />
            </span>
            Your email
          </p>
          <p className="provide-contact-update">
            <span
              className={
                theme === "inoxTheme"
                  ? "border rounded-circle bg-dark-inox text-white"
                  : "border rounded-circle bg-dark-pvr text-white"
              }
            >
              <i
                className={
                  profile?.data?.gd ? "pi pi-check mr-2" : "pi pi-times mr-2"
                }
              />
            </span>{" "}
            Gender information
          </p>
          <p className="provide-contact-update">
            <span
              className={
                theme === "inoxTheme"
                  ? "border rounded-circle bg-dark-inox text-white"
                  : "border rounded-circle bg-dark-pvr text-white"
              }
            >
              <i
                className={
                  profile?.data?.dob ? "pi pi-check mr-2" : "pi pi-times mr-2"
                }
              />
            </span>{" "}
            Date of birth
          </p>
          <p className="provide-contact-update">
            <span
              className={
                theme === "inoxTheme"
                  ? "border rounded-circle-last  bg-dark-inox text-white"
                  : "border rounded-circle-last  bg-dark-pvr text-white"
              }
            >
              <i
                className={
                  profile?.data?.doa ? "pi pi-check mr-2" : "pi pi-times mr-2"
                }
              />
            </span>{" "}
            Anniversary
          </p>
        </div>
      </>
    );
  };

  // console.log("profile", profile);
  const hitGa = (event) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: event,
    });
  };
  
  const profileInfoUi = () => {
    return (
      <>
        <div className="Account-view-show-mobile">
          <div
            className="account-angle-btn"
            onClick={() => setVisibleRight(false)}
          >
            <h4>
              <img src={angle_left} alt="" className="" /> &nbsp; Profile
            </h4>
          </div>
          <div
            className=""
            onClick={() => {
              personalDetailsFormik.setFieldValue("name", profile?.data?.un);
              personalDetailsFormik.setFieldValue("email", profile?.data?.em);
              personalDetailsFormik.setFieldValue("gender", profile?.data?.gd);
              personalDetailsFormik.setFieldValue(
                "maritalStatus",
                profile?.data?.ms
              );
              const exactMinDate = new Date();
              exactMinDate.setFullYear(exactMinDate.getFullYear() - 13);
              exactMinDate.setHours(0, 0, 0, 0);

              const minAnniversaryDate = new Date(
                personalDetailsFormik.values.dob
              );
              minAnniversaryDate.setFullYear(
                minAnniversaryDate.getFullYear() + 18
              );

              const dobValue = profile?.data?.dob
                ? new Date(profile.data.dob)
                : exactMinDate;
              const doaValue = profile?.data?.doa
                ? new Date(profile.data.doa)
                : new Date();
              personalDetailsFormik.setFieldValue("dob", dobValue);
              personalDetailsFormik.setFieldValue("doa", doaValue);

              setShowEditProfile(true);
            }}
          >
            <img src={EditPencil} alt="" className="img-fluid" />
          </div>
        </div>
        <div className="luxe-divider"></div>
        <div className="user-mobile-show-details">
          <div className="">
            <h4>
              <span className="avtar">
                <img
                  src={profile?.data?.img ? profile?.data?.img : avtar}
                  alt=""
                  className=""
                  width={100}
                />
              </span>
            </h4>
          </div>
          <div className="user-detail-mobile-personal">
            <h4>{profile?.data?.un}</h4>
            <p>Member since {profile?.data?.cd}</p>
          </div>
        </div>
        <div className="luxe-divider"></div>
        <div className="Personal-show-static">
          <div className="d-flex justify-content-between">
            <span>Phone</span>
            <span>{profile?.data?.mob}</span>
          </div>
          <div className="luxe-divider"></div>
          <div className="d-flex justify-content-between">
            <span>Email</span>
            <span>{profile?.data?.em}</span>
          </div>
          <div className="luxe-divider"></div>
          <div className="d-flex justify-content-between">
            <span>Gender</span>
            <span>{profile?.data?.gd}</span>
          </div>
          <div className="luxe-divider"></div>
          <div className="d-flex justify-content-between">
            <span>Date of Birth</span>
            <span>{profile?.data?.dob}</span>
          </div>
          <div className="luxe-divider"></div>
          <div className="d-flex justify-content-between">
            <span>Marital Status</span>
            <span>{profile?.data?.ms}</span>
          </div>
          <div className="luxe-divider"></div>
          <div className="d-flex justify-content-between">
            <span>Anniversary</span>
            <span>{profile?.data?.doa}</span>
          </div>
          <div className="luxe-divider"></div>
        </div>
        <div className="profile-show-mobile">
          <Sidebar
            visible={showEditProfile}
            showCloseIcon={false}
            style={{ height: "85vh" }}
            position={"bottom"}
            className="Edit-profile-mobile"
            onHide={() => {
              setShowEditProfile(false);
            }}
            dismissable={true}
            blockScroll={true}
          >
            <>
              <div className="profile-mobile-box-spaces-edit-profile ">
                <span className="edit-profile-mobiles">Edit Profile</span>
              </div>
              <div className="luxe-divider"></div>
              <div className="profile-mobile-box-spaces-edit-profile ">
                <h4>
                  <span className="avtar">
                    <img
                      src={profile?.data?.img ? profile?.data?.img : avtar}
                      alt=""
                      className=""
                      width={80}
                    />
                  </span>
                </h4>
              </div>
              <div className="profile-mobile-box-spaces ">
                <form onSubmit={personalDetailsFormik.handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mobile">
                        <span className="p-float-label">
                          <InputText
                            id="name"
                            value={personalDetailsFormik.values.name}
                            onChange={personalDetailsFormik.handleChange}
                          />
                          <label htmlFor="ssn_input">Name</label>
                        </span>
                        <div className="advertise-icon">
                          {/* <img src={profile} alt="" className="img-fluid" /> */}
                        </div>
                        {getPersonalDetailsFormErrorMessage("name")}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mobile">
                        <span className="p-float-label">
                          <InputText
                            id="email"
                            value={personalDetailsFormik.values.email}
                            onChange={personalDetailsFormik.handleChange}
                          />
                          <label htmlFor="ssn_input">Email ID</label>
                        </span>
                        <div className="advertise-icon">
                          <img src={mail} alt="" className="img-fluid" />
                        </div>
                        {getPersonalDetailsFormErrorMessage("email")}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mobile">
                        <span className="p-float-label">
                          <InputText
                            id="mobile"
                            value={profile?.data?.mob}
                            onChange={personalDetailsFormik.handleChange}
                            disabled
                          />
                          <label htmlFor="ssn_input">Mobile No.</label>
                        </span>
                        <div className="advertise-icon">
                          <img src={phone} alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="organisation-feild now-right ">
                        <div className="mobiless">
                          <span className="p-float-label">
                            <Dropdown
                              id="gender"
                              options={genderOption}
                              value={personalDetailsFormik.values.gender}
                              onChange={personalDetailsFormik.handleChange}
                              optionLabel="name"
                              optionValue="code"
                            />
                            <div className="custom-dropdown-arrow">
                              <img src={custoArrow} alt="" className="" />
                            </div>
                            <label
                              htmlFor="ssn_input"
                              className="dropdowns-type"
                            >
                              Gender
                            </label>
                          </span>
                          {getPersonalDetailsFormErrorMessage("gender")}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="organisation-feild now-right">
                        <span className="p-float-label">
                          <Dropdown
                            id="maritalStatus"
                            options={maritalOption}
                            value={personalDetailsFormik.values.maritalStatus}
                            onChange={personalDetailsFormik.handleChange}
                            optionLabel="name"
                            optionValue="code"
                          />
                          <div className="custom-dropdown-arrow">
                            <img src={custoArrow} alt="" className="" />
                          </div>
                          <label htmlFor="ssn_input" className="dropdowns-type">
                            Marital Status
                          </label>
                        </span>
                        {getPersonalDetailsFormErrorMessage("maritalStatus")}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="select-date-search">
                        <div className="fields-search">
                          <div className="mobile">
                            <span className="p-float-label">
                              <Calendar
                                id="dob"
                                value={personalDetailsFormik.values.dob}
                                onChange={personalDetailsFormik.handleChange}
                                dateFormat="dd-mm-yy"
                                maxDate={exactMinDate} // Up to the exact 18th birthday
                                showIcon
                                icon={
                                  <img src={calendarIcon} alt="Calendar Icon" />
                                }
                              />
                              <label htmlFor="ssn_input">Date Of Birth</label>
                            </span>
                            {getPersonalDetailsFormErrorMessage("dob")}
                          </div>
                        </div>
                      </div>
                    </div>
                    {personalDetailsFormik.values.maritalStatus === "Married" ||
                    personalDetailsFormik.values.maritalStatus === "Married" ? (
                      <div className="col-md-6">
                        <div className="select-date-search">
                          <div className="fields-search">
                            <div className="mobile">
                              <span className="p-float-label">
                                <Calendar
                                  // id="doa"
                                  // value={personalDetailsFormik.values.doa}
                                  // onChange={personalDetailsFormik.handleChange}
                                  // dateFormat="dd-mm-yy"
                                  // // icon='pi pi-calendar'
                                  // showIcon
                                  // icon={<img src={calendarIcon} alt="Calendar Icon" />}

                                  id="doa"
                                  value={personalDetailsFormik.values.doa}
                                  onChange={personalDetailsFormik.handleChange}
                                  dateFormat="dd-mm-yy"
                                  minDate={minAnniversaryDate} // Disable future dates
                                  showIcon
                                  icon={
                                    <img
                                      src={calendarIcon}
                                      alt="Calendar Icon"
                                    />
                                  }
                                />
                                <label htmlFor="ssn_input">
                                  Marriage Anniversary
                                </label>
                              </span>
                              {getPersonalDetailsFormErrorMessage("doa")}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col-md-12">
                      <div className="edit-divider"></div>
                      <div className="mobile-edit-profile">
                        <div className="mobile-save-edit-btn">
                          <LightButton
                            className="mobile-cancel-btn-edit"
                            type="button"
                            onClick={() => {
                              setShowEditProfile(false);
                              personalDetailsFormik.resetForm();
                            }}
                          >
                            Discard
                          </LightButton>
                        </div>
                        <div className="save-btn-edits">
                          <DarkButton
                            className={
                              loading
                                ? "mobile-save-btn-edit btn-opacity"
                                : "mobile-save-btn-edit"
                            }
                            type="submit"
                          >
                            Save
                          </DarkButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </>
          </Sidebar>
        </div>
      </>
    );
  };

  return (
    <>
      <div>
        {/* <div className="profile-offer">
          <div className="profile-offer-img">
            <img src={offer} alt="" className="" />
          </div>
        </div> */}
        {/* <div className="offer-border"></div> */}
        <div
          className="profile-img"
          onClick={() => {
            setVisibleRight(true);
            setSidebarViewMode(0);
          }}
        >
          <div className="profile-text">
            <h4>
              <span className="avtar">
                <img
                  src={profile?.data?.img ? profile?.data?.img : avtar}
                  alt=""
                  className=""
                  width={50}
                />
              </span>
              <b></b>
            </h4>
            <div className="profile-text-info">
              <h2>{profile?.data?.un}</h2>
              <p>Member since {profile?.data?.cd}</p>
            </div>
          </div>
          <div className="img-arrow" >
            <img src={arrow} alt="" className="" />
          </div>
        </div>
        {profile?.percentage !== 100 && (
        <div
          className={
            theme === "inoxTheme"
              ? "profile-complection bg-light-inox"
              : "profile-complection bg-light-pvr"
          }
          onClick={() => {
            setVisibleRight(true);
            setSidebarViewMode(1);
          }}
        >
          <div className="voucher-section">
            <div className="profile-complection-text">
              <h5>
                Profile Completion{" "}
                <span>
                  <img src={info} alt="" className="" />
                </span>
              </h5>
            </div>
            <div className="percentage">
              <p>{profile?.percentage}%</p>
            </div>
          </div>
          <div className="card-info">
            <ProgressBar
              // style={theme === 'inoxTheme' ? {backgroundColor:"#234B9E"}:{backgroundColor:'#FFCB05'}}
              color={theme === "inoxTheme" ? "#234B9E" : "#FFCB05"}
              value={profile?.percentage}
            ></ProgressBar>
            <div className="offer-voucher-text">
              <p>{profile?.msg} </p>
            </div>
          </div>
        </div>

        )}
      </div>
      <div>
        <Sidebar
          visible={visibleRight}
          //   icons={proceedbtn}
          showCloseIcon={false}
          className="profile-show-mobile"
          style={
            sidebarViewMode === 0 ? { width: "100vw" } : { height: "60vh" }
          }
          position={sidebarViewMode === 0 ? "right" : "bottom"}
          onHide={() => {
            setVisibleRight(false);
          }}
          dismissable={true}
          blockScroll={true}
          //   fullScreen={sidebarViewMode === 0 ? true : false}
        >
          <>
            {sidebarViewMode === 0 ? profileInfoUi() : profileCompleteStatics()}
          </>
        </Sidebar>
      </div>
    </>
  );
};

export default ProfilePersonalDeatils;
