import React, { useState } from "react";
import angle_left from "../../../assets/mobilemoviedetail/arrow-b.svg";
import OtpInput from "react-otp-input";
import "./mobilepayment.css";
import "../../seat-layout/seatlayout.css";
import "../../payment/payment.css";
import check from "../../../assets/payment/checked 1.svg";
import { LightButton } from "src/styled-components/customButtons";
import TicketSummary from "../../payment/summary/TicketSummary";
import Lock from "../../../assets/payment/lock.svg";
import { applycoupon, removeCoupon, getPassport } from "src/services/CouponPayment";
import PassportCard from "../../../assets/payment/passport-card.png";
import { useLocation, useNavigate } from "react-router-dom";
import { ProceedBtn } from "src/views/get-food/getfoodTags";
import certificateImg1 from "../../../assets/brand/certificate1.png";
import certificateImg2 from "../../../assets/brand/certificate2.png";
import { ThemeDiv, ThemeLightDiv } from "src/styled-components/generalTags";
import { useEffect } from "react";
import { setLoginViewMode } from "src/redux/actions/loginViewAction";
import { useDispatch, useSelector } from "react-redux";
import * as appConstants from "../../../constants/index";
import axios from "axios";
import { refraceTokenService } from "src/global-functions/refreshToken";
import explore from "../../../assets/payment/explore.svg";
import explore_pvr from "../../../assets/payment/explore_pvr.svg";
import Bankoffer from "../../../assets/payment/banker-offer.svg";
import SavedCard from "src/views/payment/frequentlyUsed/SavedCard";
import SweetAlert from "react-bootstrap-sweetalert";
import { Divider } from "primereact/divider";
import { Dialog } from "primereact/dialog";
import Login from "src/views/common/login/Login";
import DebitCard from "src/views/payment/frequentlyUsed/DebitCard";
import NetBanking from "src/views/payment/frequentlyUsed/NetBanking";
import CreditCard from "src/views/payment/frequentlyUsed/CreditCard";
import Paytm from "src/views/payment/wallets/Paytm";
import Mobikwik from "src/views/payment/wallets/Mobikwik";
import Phonepe from "src/views/payment/wallets/Phonepe";
import GiftCard from "src/views/payment/other-options/GiftCard";
import Privilegecard from "src/views/payment/other-options/Privilegecard";
import Gyftr from "src/views/payment/other-options/Gyftr";
import Starpass from "src/views/payment/pvr-exclusive/Starpass";
import Mcoupon from "src/views/payment/pvr-exclusive/Mcoupon";
import HyattDining from "src/views/payment/pvr-exclusive/HyattDining";
import ticketup from "../../../assets/mobilemoviedetail/ticketup.svg";
import Upi from "../../payment/frequentlyUsed/Upi";
import PaytemPostpaid from "../../payment/frequentlyUsed/PaytmPostpaid";
import GiftCardSummary from "src/views/payment/summary/GiftCardSummary";
import { Checkbox } from "primereact/checkbox";
import { Sidebar } from "primereact/sidebar";
import { DarkButton } from "src/styled-components/customButtons";
import { FaRupeeSign } from "react-icons/fa";
import Bankofferinox from "../../../assets/payment/bank-offer-blue.svg";
import BankOffers from "src/views/payment/bank-offers/BankOffers";
import { setGrandTotalamount } from "src/redux/actions/gradTotalAction";
import start_pvr from "../../../assets/payment/star-pvr.jpeg";
import PaymodesListSkeleton from "src/skeletons/PaymodesListSkeleton";
import PrivilegePlus from "src/views/payment/pvr-exclusive/PrivilegePlus";
import AmazonPay from "src/views/payment/wallets/AmazonPAY";
import PasssportSummary from "src/views/payment/summary/PasssportSummary";
import { IscustomEvent } from "src/global-functions/globalFunctions";
import { executeRecaptcha } from "../../common/login/RecaptchaV3";


const MobilePayment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation()
  const isMobile = window.matchMedia("(max-width: 575px)").matches;
  const [visible, setVisible] = useState(false);
  const theme = localStorage.getItem("theme");
  const loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
  const bookingType = localStorage.getItem("booking_type");
  const loginVewMode = useSelector((state) => state.loginViewMode.value);
  const appliedVocher = JSON.parse(localStorage.getItem("AppliedOfferData"));
  const modeVal = useSelector((state) => state.viewModeValue);
  const [viewMode, setViewMode] = useState(-1);
  const [juspayinit, setJuspayinit] = useState(0);
  const [paymentModes, setPaymentModes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [finalTicketLoading, setFinalTicketLoading] = useState(false);
  const [activeButtonId, setActiveButtonId] = useState(null);
  const [paymentAlert, setPaymentAlert] = useState(false);
  const [summaryData, setSummaryData] = useState(null);
  const [checked, setChecked] = useState(false);
  const [tnCVisibleRight, setTnCVisibleRight] = useState(false);
  const [grandvalue, setGrandvalue] = useState();
  const [surcharge, setSurcharge] = useState(false);
  const [surchargeVal, setSurchargeVal] = useState(null);
  const [sendotp, setSendotp] = useState(false);
  const [mobileOtp, setMobileOtp] = useState("");
  const [passportitem, setPassportitem] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [ditxt, setDitxt] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [payMode, setPayModes] = useState(null);
  const [showVoucher, setshowVoucher] = useState(true);
  const [showSummary, setShowSummary] = useState(false);
  const [passportSummary, setPassportSummary] = useState(null);
  const [dependentCheckbox, setDependentCheckbox] = useState(0);
  const [isBankOfferApplied, setIsBankOfferApplied] = useState(false);
  const [enableCardID, setEnableCardID] = useState(null);
  const [payment, setPayment] = useState(false);
  const [applydata, setApplyData] = useState(null);
  const [cardNumber, setcardNumber] = useState(null);
  const [bankName, setBankName] = useState(null);
  const [passportCheck, setpassportCheck] = useState(0);
  const grandTotal = useSelector((state) => state?.grandTotalData);
  const [passportVouchers, setPassportVouchers] = useState([]);
  const [passportData, SetPassportData] = useState("");
  const [mobCoupon, setMobCoupon]= useState(false);
  //dispatch(setGrandTotalamount({ cutPrice: 0, finalPrice: 0 }));
  // console.log("offerData", offerData);

  const theatreId = localStorage.getItem("TheatreId");
  const transId = localStorage.getItem("transid") || 0;
  const bookingId = localStorage.getItem("bookingid") || 0;
  const transactionType = localStorage.getItem("TransactionType");
  const authToken = "Bearer " + (localStorage.getItem("token") || " ");
  useEffect(() => {
    if (localStorage.getItem("token")) {
      getJuspayInit();
      getPayModeLists();
      const { paymentOptions } = location.state || {};
      if (paymentOptions === 1) {
        setActiveButtonId("");
        setViewMode(paymentOptions);
        paymentUI();
      } else {
        setViewMode(0)
      }
      if (localStorage.getItem("booking_type") == "GIFTCARD" && !isMobile) {
        hitGCGA();
        //  alert("Dsd")
      }
    } else {
      dispatch(setLoginViewMode({ value: true }));
    }
  }, [loginVewMode, modeVal]);
  useEffect(() => {
    const handleBackButton = () => {
      setPaymentAlert(true);
      window.history.pushState(null, null, window.location.pathname);
    };
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButton);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);
  const hitGCGA = () => {
    let dataLayer = window["dataLayer"] || [];
    // dataLayer.push({
    //   event: "gift_card_proceed_to_pay",
    //   gift_card_type: localStorage.getItem("giftCardDatatype"),
    //   // gift_card_no: localStorage.getItem("giftCardDatadisplay"),
    //   gift_card_amount: localStorage.getItem('giftcardamount')
    // });
  };
  const hitBeginCheckout = (
    event,
    item_brand,
    item_name,
    item_id,
    item_category,
    item_category2,
    item_category3,
    item_variant,
    quantity,
    price
  ) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: event,
      ecommerce: {
        items: [
          {
            item_brand: item_brand,
            item_name: item_name,
            item_id: item_id,
            item_category: item_category, //(selected ticket, gift card & passport)
            item_category2: item_category2, //(selected f&b & city name)
            item_category3: item_category3, //( selected date & seat number)
            item_variant: item_variant, //( selected format)
            quantity: quantity,
            price: price,
          },
        ],
      },
    });
  };

  const getJuspayInit = async () => {
    setLoading(true);
    const postData = {
      transId: localStorage.getItem("transid"),
      bookingId: localStorage.getItem("bookingid"),
      bookType: bookingType,
      paytype: "",
      pgtype: "",
    };

    await axios
      .post(`${appConstants.base_url}v1/payment/juspay/init`, postData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      })
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          setJuspayinit(dt.output);
          // console.log("init==>", dt.output)
        } else {
          console.log(res.data.msg);
        }
      })
      .catch((error) => {
        const err = error?.response;
        if (err && err?.status === 403) {
          refraceTokenService();
          console.log(
            "Forbidden: You do not have permission to access this resource."
          );
        } else {
          console.log("Error:", error?.message);
        }
        setLoading(false);
      });
  };

  // for loyality voucher

  const loyalityVoucher = () => {
    getPassport()
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          SetPassportData(dt?.output?.voucher_text || "AVAILABLE Vouchers");
          let uvouchers = dt.output?.vouchers.map(obj =>
            ({ ...obj, verification: false })
          );
          setPassportVouchers(uvouchers);
        } else {
        }
      })
      .catch((error) => {
        const err = error?.response;
        console.log(err);
        if (err?.status === 403) {
          refraceTokenService();
          //profileRequest();
          console.log(
            "Forbidden: You do not have permission to access this resource."
          );
        } else {
          console.log("Error:", error.message);
        }
      });
  };
  const getPayModeLists = async () => {
    setLoading(true);
    const postData = {
      ccode:
        localStorage.getItem("booking_type") === "GIFTCARD"
          ? ""
          : localStorage.getItem("theaterId"),
      bookingId: localStorage.getItem("bookingid"),
      transId: localStorage.getItem("transid"),
      bookType: localStorage.getItem("booking_type"),
      // mandate: true,
      unpaid:  localStorage.getItem("unpaidMode"),
    };
    // console.log("token", `Bearer ${loginInfo.access.token}`)

    await axios
      .post(`${appConstants.base_url}v1/booking/ticketing/paymodes`, postData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
        },
      })
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          const ndt = dt.output;

          setPaymentModes(ndt);
          // if(localStorage.getItem("booking_type") == "BOOKING"){
            loyalityVoucher();
          // }
          // console.log("payModeData==>", ndt);

          // if (bookingType === "FOOD") {
          //   ndt.offers = [],
          //     ndt.bankoffers = [],
          //     ndt.vouchers = [],
          //     ndt.autoVouchers = [],
          //     ndt.wallets = []
          // }

          if (appliedVocher) {

            let val = appliedVocher.vouPayType === "CC" ? "201" : appliedVocher.vouPayType === "DC" ? "202" : "";

            switch (appliedVocher.vouPayType) {
              case "CC":
                val = "201"
                setcardNumber(appliedVocher.cardNumber)
                break;
              case "DC":
                val = "202"
                setcardNumber(appliedVocher.cardNumber)
                break;

              default:
                break;
            }

            console.log(ndt);

            val ? setPaymentModes(prevModes => ({
              ...prevModes,
              gateway: ndt?.gateway.filter(item => item.pid === val),
              offers: [],
              bankoffers: [],
              vouchers: [],
              autoVouchers: [],
              wallets: []
            })) : setPaymentModes(ndt);
          } else {
            setPaymentModes(ndt);
          }
          if (localStorage.getItem("booking_type") == "BOOKING") {
            loyalityVoucher();
          }
          setLoading(false);
        } else if (res.data.code === 12001) {
          // alert(res.data.msg);
          setLoading(false);
        } else {
          console.log(res?.data?.msg);
          setLoading(false);
        }
      })
      .catch((error) => {
        const err = error?.response;
        if (err?.status === 403) {
          // refraceTokenService();
          console.log(
            "Forbidden: You do not have permission to access this resource."
          );
        } else {
          console.log("Error:", error?.message);
        }
        setLoading(false);
      });
  };

  const getFinalTicket = async () => {
    setFinalTicketLoading(true);
    try {
      const postData = {
        qr: localStorage.getItem("isQR") && isMobile ? "YES" : "NO",
        bookType: "FOOD",
        transId: transId,
        bookingId: bookingId,
        flowType: "FOOD",
        ccode: theatreId,
      };

      const response = await axios.post(
        `${appConstants.base_url}v1/booking/ticketing/completed`,
        postData,
        {
          headers: {
            Authorization: authToken || "",
            chain: localStorage.getItem("theme") === "inoxTheme" ? "INOX" : "PVR",
            city: localStorage.getItem("city"),
            appVersion: appConstants.av,
            platform: appConstants.pt,
            country: appConstants.cname,
          },
        }
      );

      const dt = response.data;
      if (dt.result === "success") {
        const ndt = dt.output;
        // setFinalTicket(ndt);
        localStorage.setItem("orderId", ndt.bookingId);
        navigate(`/order-received`);

        localStorage.removeItem("transId");
        localStorage.removeItem("booking_type");
        localStorage.removeItem("isQR");
      } else {
        setIsBadTransaction(true);
        setShowAlert(true);
        setErrorMsg(dt);
      }
    } catch (error) {
      const err = error?.response;
      if (err?.status === 403) {
        refraceTokenService();
        console.log(
          "Forbidden: You do not have permission to access this resource."
        );
      } else {
        console.error("Error:", error?.message);
      }

      setIsBadTransaction(true);
      setShowAlert(true);
      setErrorMsg("There is a network failure.");
    } finally {
      setFinalTicketLoading(false);
    }
  };

  const sendDataForPayment = (index) => {
    // the callback. Use a better name
    console.log("sendDataForPayment", index);
    let dt = index;
    if (dt.result === "success") {
      if (dt.voucheName != "PRIVILEGE PLUS" && dt.voucheName != "M-COUPON"&& dt.voucheName != "STAR-PASS") {
        setActiveButtonId("");
        setshowVoucher(false);
        setMobCoupon(false);
      } else if (dt.voucheName == "M-COUPON" || dt.voucheName == "STAR-PASS") {
        setshowVoucher(false);
        setMobCoupon(true);
      }
      const ndt = dt.output;
      if (dt.voucheName) {
        setApplyData({ voucheName: dt.voucheName });
      }
      // console.log("setDonationData==>", ndt);
      if (ndt && ndt.it && ndt.ft) {
        dispatch(
          setGrandTotalamount({
            cutPrice: Number(ndt?.it),
            finalPrice: Number(ndt?.ft),
          })
        );
      }
      IscustomEvent("Offer Applied");
      setDitxt(ndt.txt);
      setPayment(true);
      setDiscount(JSON.stringify(ndt));
      if (ndt.p == true) {
        localStorage.setItem("booking_ticket", 1);
        let url = localStorage.getItem("booking_type") == "BOOKING" ? "/booking-ticket" : getFinalTicket();
        // let url = localStorage.getItem("booking_type") == "BOOKING" ? "/booking-ticket" : "/food-ticket"
        navigate(url);
      }
      // console.log(dt);
      if (dt.cardtype == "NB") {
        setBankName(dt.bankName);
        console.log(bankName);
      } else {
        setcardNumber(dt.cardNum);
      }
      if (dt.cardtype) {
        // setActiveOfferMode(true);
        const val =
          dt.cardtype === "CC" ? "201" : dt.cardtype === "DC" ? "202" : "203";
        setEnableCardID(val);
        setActiveButtonId({ pid: val, name: dt.cardtype === "CC" ? "Credit Card" : dt.cardtype === "DC" ? "Debit Card" : "NET BANKING" });
        setIsBankOfferApplied(true);
        setViewMode(2);
        setPaymentModes(prevModes => ({
          ...prevModes,
          gateway: prevModes?.gateway.filter(item => item.pid === val),
          bankoffers: [],
          vouchers: [],
          wallets: []
        }));
      }

      setLoading(false);
    } else if (dt.result === "dialog") {
      setErrorMsg(dt.msg);
      setShowAlert(true);
      setLoading(false);
    } else {
      setErrorMsg(dt.msg);
      setShowAlert(true);
      setLoading(false);
    }
  };

  const removeApplyData = () => {
    console.log("working", applydata);
    removeApply(
      applydata ? applydata.voucheName : "",
      applydata ? applydata.vochId : ""
    );
  };

  const removeApply = async (type, couponId) => {
    setLoading(true);
    const postData = {
      ccode: localStorage.getItem("theaterId"),
      bookingId: localStorage.getItem("bookingid"),
      transId: localStorage.getItem("transid"),
      couponId: couponId,
      bookType: localStorage.getItem("booking_type")
        ? localStorage.getItem("booking_type")
        : "BOOKING",
      coupon: type == "PROMOCODE" ? searchText : couponId,
      couponType: type,
      qr:
        (localStorage.getItem("isQR") || localStorage.getItem("fnb_qr")) &&
        isMobile
          ? "YES"
          : "NO",
      cardType: "",
      //"qrs": false,
      card: "",
      mobile: "",
      otp: "",
    };
    //removeCoupon
    removeCoupon(postData)
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          const ndt = dt.output;
          //console.log("setDonationData==>", ndt);
          if (ndt && ndt.totalAmount) {
            dispatch(
              setGrandTotalamount({
                cutPrice: 0,
                finalPrice: Number(ndt?.totalAmount) / 100,
              })
            );
          }
          setMobCoupon(false);
          setDitxt(dt.msg);
          setshowVoucher(true);
          setPayment(true);
          setApplyData(null);
          setDiscount(JSON.stringify(ndt));
          setDependentCheckbox(0);
          setEnableCardID(null);
          setActiveButtonId("");
          setcardNumber("");
          //setDonationVewMode(false);
          // if(ndt === 'no'){
          //   setDonationPrice(0)
          // }
          setLoading(false);
        } else {
          setErrorMsg(dt.msg);
          setShowAlert(true);
          console.log(res.data.msg);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };
  const getApply = async (type, couponId, unlock = false, item = null) => {
    setPassportitem(item);
    if (item?.dependent && !mobileOtp) {
      sendOTPService(item?.dependentMobile);
      return '';
    }
    setLoading(true);
    const postData = {
      ccode: localStorage.getItem("theaterId"),
      bookingId: localStorage.getItem("bookingid"),
      transId: Number(localStorage.getItem("transid")),
      couponId: type == "AUTOVOUCHER" ? couponId : 0,
      bookType: localStorage.getItem("booking_type")
        ? localStorage.getItem("booking_type")
        : "BOOKING",
      coupon: type == "PROMOCODE" ? searchText : couponId,
      couponType: type,
      qr:
        (localStorage.getItem("isQR") || localStorage.getItem("fnb_qr")) &&
        isMobile
        ? "YES"
        : "NO",
      cardType: "",
      //"qrs": false,
      qrs: transactionType === "InCinemaFB" ? true : false,
      card: "",
      mobile: item?.dependent ? item?.dependentMobile : loginInfo?.data?.mob,
      otp: item?.dependent ? mobileOtp : "",
      unlock: unlock,
    };

    //removeCoupon
    applycoupon(postData)
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          const ndt = dt.output;
          setActiveButtonId("");
          dt.voucheName = type;
          setSendotp(false);
          if (dt?.output?.surcharge) {
            setSurcharge(true);
            setSurchargeVal(dt.output);
          } else {
            if (surchargeVal?.surcharge) {
              dt.output.surcharge = surchargeVal?.surcharge;
            }
            let uvouchers = passportVouchers.map(obj =>
              obj.vouNumber == item.vouNumber ? { ...obj, verification: true } : obj
            );
            dt.output.countVoucher = uvouchers.filter(item => item.verification === true).length;
            setPassportVouchers(uvouchers);
            sendDataForPayment(dt);
            setMobileOtp("");
            if (type == "PASSPORT") setPassportSummary(ndt);
          }
          //setDonationVewMode(false);
          // if(ndt === 'no'){
          //   setDonationPrice(0)
          // }
          setLoading(false);
        } else {
          setpassportCheck(0);
          setErrorMsg(dt.msg);
          setShowAlert(true);
          console.log(res.data.msg);
          setLoading(false);
          setMobileOtp("");
          setDependentCheckbox(0);
          //console.log(dt.status);
          if (dt.status == 302) {
            setSendotp(false);
          }
        }
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };

  function getTimestamp() {
    return Date.now();
  }
  const timestamp = getTimestamp(); 
async function generateHashText(mobile,platform) {  
  const secretKey = "tdy*gdr?diu(grui^j9utyf";  
  const hashText = `|${mobile}|${timestamp}|${secretKey}|${mobile.substring(0, 6)}|${platform}|`;
  const encoder = new TextEncoder();  
  const data = encoder.encode(hashText);  
  const hashBuffer = await crypto.subtle.digest("SHA-512", data);
  const hashArray = new Uint8Array(hashBuffer);
  const hashHex = Array.from(hashArray)
    .map(byte => byte.toString(16).padStart(2, '0')) 
    .join('');  
  return { hash: hashHex, timestamp };
}
  // ===== send otp with mobile services ===========
  const sendOTPService = async (mobile) => {
    setLoading(true);
    // try {
    //   const token = await executeRecaptcha();
    //   if (!token) {
    //       setError("reCAPTCHA verification failed, please try again.");
    //       setLoading(false);
    //       return;
    //   }
    const { hash } = await generateHashText(mobile,"OTP"); 
    const postData = {
      mobile:mobile,
      token: hash,
      type: "PASSPORT",
      timestamp:timestamp,
     // ptoken: token,
    };
    // const postData = {
    //   mobile: mobile,
    //   type: "PASSPORT"
    // };
    await axios
      .post(`${appConstants.base_url}v1/auth/otp`, postData, {
        headers: {
          chain: theme === "inoxTheme" ? "INOX" : "PVR",
          city: localStorage.getItem("city"),
          appVersion: appConstants.av,
          platform: appConstants.pt,
          country: appConstants.cname,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const dt = res.data;
        if (dt.result === "success") {
          //setSuccessMsg(dt);
          setSendotp(true);
        } else if (dt.result === "error" || dt.result === "dialog") {
          setErrorMsg(dt);
          setShowAlert(true);
          //setShowErrorAlert(true);
          setLoading(false);
        }
        // console.log('userData', dt)
      })
      .catch((err) => {
        console.log("error", err);
        // showOTPSendMsg("error", err.response.data.error);
        setLoading(false);
      });
  //   } catch (error) {
  //     console.error("reCAPTCHA error:", error);
  //     setLoading(false);
  // }
  };
  const SaveticketSummaryData = (data) => {
    // console.log(data);
    setSummaryData(data);
  };

  const payModeUI = () => {
    return (
      <div className="payment-mobile-box">
        <div className="luxe-divider"></div>
        <div className="mobile-payment-user">
          <span>{loginInfo?.data?.un}</span>
          <span>{loginInfo?.data?.mob}</span>
        </div>
        <ThemeLightDiv className="payment-grand-amount-mobile">
          <span>To be paid</span>

          <span>
            {grandTotal.cutPrice > 0 ? (
              <span className="cut-price-passport">
                <FaRupeeSign
                  style={{
                    fontSize: "10px",
                    fontWeight: "500",
                    marginBottom: "2px",
                  }}
                />
                {parseFloat(grandTotal.cutPrice).toFixed(2)}
              </span>
            ) : (
              ""
            )}
            <FaRupeeSign
              style={{
                fontSize: "10px",
                fontWeight: "500",
                marginBottom: "2px",
              }}
            />
            {grandTotal.finalPrice}
            {passportSummary ?
              <i className="pi pi-info-circle" onClick={() => {
                setShowSummary(true)
              }} style={
                theme === "inoxTheme"
                  ? { backgroundColor: "#DCE2EF" }
                  : { backgroundColor: "#F8F6F6" }
              } />
              : ""}
          </span>
        </ThemeLightDiv>
        {/* <div className="luxe-divider"></div> */}
        {/* <Divider style={{ margin: "5px 0" }} />  */}
        <div className="row mobile-payment-spaces ">
          {loading ? <PaymodesListSkeleton /> :
            (<div className="mobile-slide-show">
              {!localStorage.getItem("is_cvp_apply") && passportVouchers && passportVouchers.length > 0 ? (
                <>
                  <div className="col-md-12">
                    <div className="voucher">
                      <h6>{passportData}</h6>
                    </div>
                  </div>
                  <div className="phone-view-passport">
                    {passportVouchers.map((item, idx) => {
                      return item?.vouType == "Passport" ? (
                        <>
                          <div className="voucher-redmeeauto" onClick={() => {
                            !loading && !item.verification ? getApply("PASSPORT", item.vouNumber, false, item) : ""
                            setpassportCheck(idx + 1)
                          }}>
                            <div className={!loading && !item.verification ? "voucher-passport-new" : "voucher-passport-new voucher-passport-reedeem "}>
                              <div className="Mobile-passport">
                                <img src={PassportCard} className="img-fluid" />
                              </div>
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <div className="passport-card-number">
                                    <h6>Passport Number</h6>
                                    <h4>{item.vouNumber}</h4>
                                  </div>
                                </div>
                                <div className="col-md-6 col-6">
                                  <div className="passport-card-number">
                                    <h6>Valid Till</h6>
                                    <h4>{item.expiryDate}</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="redmee-auto-fil">
                              <div className="passport-voucher">
                                {!item.dependent ?
                                  <div className="redmee-tick-mobile">
                                    <div className="tick-cricle-passport">
                                      <label style={{ marginTop: '9px' }}>
                                        <input type="checkbox"
                                          checked={
                                            passportCheck == idx + 1 || item.verification
                                          }
                                          disabled={
                                            item.verification
                                          }
                                        />
                                        <div class="custom-checkbox">
                                          <div class="tick"></div>
                                        </div>
                                      </label>
                                    </div>
                                    <div className="passport-redmee">
                                      <p className={item.verification ? "preedeemm" : ""}>{!item.verification ? "Redeem" : "Redeemed"}</p>
                                      {!item.verification ? <p style={{ marginLeft: "40%" }}>Mob: {loginInfo?.data?.mob}</p> : ""}
                                    </div>
                                  </div>
                                  : ""}
                                {item.dependent ?
                                  <div className="unlock-passport">
                                    <p className={item.verification ? "preedeemm" : ""}> {!item.verification ?
                                      <><span className="lock-icon"><img src={Lock} alt="" className="img-fluid" /></span> Unlock Voucher</>
                                      : "Redeemed"
                                    }</p>
                                    {!item.verification ? <p style={{ marginLeft: "10%" }}>Mob: {item.dependentMobile}</p> : ""}
                                  </div>
                                  : ""}
                              </div>
                            </div>
                          </div>

                        </>

                      ) : (
                        <div className="payment-fnb pointer" onClick={() => {
                          !loading && !item.verification ? getApply("FNBVOUCHER", item.vouNumber, false, item) : ""
                          setpassportCheck(idx + 1)
                        }}>
                          <div className="payment-food-show" style={item?.category == "FB" ? { backgroundImage: "linear-gradient(#1D468E, #1D468E)" } : {}}>
                            <h6>{item.vouDesc}</h6>
                            <h3>Voucher Number</h3>
                            <h5>{item.vouNumber}</h5>
                            <h3>Validity</h3>
                            <span>{item.expiryDate}</span>
                          </div>
                          <div className="redmee-auto-fil">
                            {!item.dependent ?
                              <div className="passport-voucher">
                                <div className="tick-cricle-passport">
                                  <label style={{ marginTop: '5px' }}>
                                    <input type="checkbox"
                                      checked={
                                        passportCheck == idx + 1 || item.verification
                                      }
                                      disabled={
                                        item.verification
                                      }
                                onChange={() => {
                                window.dataLayer.push({
                                event: 'family_bundle_redemption',
                                voucher_name: item.vouDesc,
                                voucher_id: item?.voucherId, 
                                price: Number(item?.balAmount),
                                valid_date: item.expiryDate
                                });
                                console.log("Data Layer Event Pushed:", item);
                                }}
                                    />
                                    <div class="custom-checkbox">
                                      <div class="tick"></div>
                                    </div>
                                  </label>
                                </div>
                                <div className="passport-redmee">
                                  <p className={item.verification ? "preedeemm" : ""}>{!item.verification ? "Redeem" : "Redeemed"}</p>

                                </div>
                                {item?.category == "FB"?
                                <div className="passport-redmee" style={{ width: "-webkit-fill-available" }}>

                                  <p className="float-end" style={{ marginTop: "4px" }}>Balance Amount: ₹{item?.balAmount}</p>
                                </div>
                                :""}
                              </div>
                              : ""}

                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                ""
              )}
              {paymentModes &&
                paymentModes.autoVouchers.length > 0 ? (
                <>
                  <div className="col-md-12">
                    <div className="voucher">
                      <h6 style={{ marginTop: "20px" }}>offers</h6>
                    </div>
                  </div>
                  <div className={!showVoucher ? "auto-voucher btn-opacity" : "auto-voucher"} >
                    {paymentModes.autoVouchers.map((item, idx) => {
                      return (
                        <div className="offer-box-save">
                          <div
                            className="passport-voucher"
                            onClick={() => {
                              // setApplyData({voucheName:"AUTOVOUCHER", vochId:item.vouId});
                              !loading
                                ? dependentCheckbox == idx + 1
                                  ? removeApply(
                                    "AUTOVOUCHER",
                                    item.vouId
                                  )
                                  : getApply(
                                    "AUTOVOUCHER",
                                    item.vouId
                                  )
                                : "";
                              setDependentCheckbox(idx + 1)

                              console.log(
                                dependentCheckbox,
                                idx,
                                idx + 1
                              );
                            }}
                          >
                            <div className="tick-cricle-passport">
                              <label>
                                <input
                                  type="checkbox"
                                  checked={
                                    dependentCheckbox == idx + 1
                                  }
                                  disabled={
                                    dependentCheckbox > 0 &&
                                    dependentCheckbox != idx + 1
                                  }
                                />
                                <div className="custom-checkbox">
                                  <div className="tick"></div>
                                </div>
                              </label>
                            </div>
                            <div className="passport-redmee">
                              <h5>{item.vouDesc}</h5>
                              <h6>Click to view details</h6>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="mobile-payment-method">
                {!localStorage.getItem("is_cvp_apply") && (paymentModes?.vouchers.length > 0 ||
                  paymentModes?.bankoffers.length > 0) ? (
                  <h6>{paymentModes?.gateway && "Offers for you"}</h6>
                ) : (
                  ""
                )}
                {!localStorage.getItem("is_cvp_apply") ?
                  <ul>
                    {paymentModes?.vouchers.length > 0 ? (
                      <li
                        style={{ cursor: "pointer" }}
                        className={
                          activeButtonId === "EXOF" ? "payment-active" : ""
                        }
                        onClick={() => {
                          setActiveButtonId({
                            name: "Explore Offers",
                            pid: "EXOF",
                          });
                          setViewMode(2);
                        }}
                      >
                        <span className="paytm-photo">
                          <img
                            src={theme === "inoxTheme" ? explore : explore_pvr}
                            alt=""
                            className="img-fluid"
                            width={25}
                            height={20}
                          />
                        </span>
                        Explore Offers
                      </li>
                    ) : (
                      ""
                    )}
                    {paymentModes?.bankoffers.length > 0 ? (
                      <li
                        style={{ cursor: "pointer" }}
                        className={
                          activeButtonId === "BKOF" ? "payment-active" : ""
                        }
                        onClick={() => {
                          setActiveButtonId({ name: "Bank Offers", pid: "BKOF" });
                          setViewMode(2);
                        }}
                      >
                        <span className="paytm-photo">
                          <img
                            src={
                              theme === "inoxTheme" ? Bankofferinox : Bankoffer
                            }
                            alt=""
                            className="img-fluid"
                            width={25}
                            height={20}
                          />
                        </span>
                        Bank Offers
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                  : ""}
                <h6>
                  {!localStorage.getItem("is_cvp_apply") && paymentModes?.offers.length > 0 ? "Offers & Promotions" : ""}
                </h6>
                <ul>
                  {!localStorage.getItem("is_cvp_apply") && paymentModes &&
                    paymentModes.offers.map((item, idx) => (
                      <li
                        key={idx}
                        className={`
                          ${activeButtonId === item.pid ? "payment-active" : ""}
                          ${!showVoucher ? "btn-opacity" : ""}
                          `
                        }
                        onClick={() => {
                          setViewMode(2);
                          setActiveButtonId(item);
                          setPayModes(item);
                          console.log(item);
                        }}
                        style={
                          !enableCardID ||
                            (enableCardID && enableCardID == item.pid)
                            ? { cursor: "pointer" }
                            : { "pointer-events": "none", opacity: 0.4 }
                        }
                      >
                        <div className="d-flex justify-content-between">
                          <div>
                            <span className="paytm-photo">
                              <img
                                src={
                                  theme === "inoxTheme"
                                    ? item.imageUrl
                                    : start_pvr
                                }
                                alt=""
                                className="img-fluid"
                                width={25}
                                height={20}
                              />
                            </span>
                            {/* <span className="paytm-photo">
                            <img
                              src={item.imageUrl}
                              alt=""
                              className="img-fluid"
                              width={25}
                              height={20}
                            />
                          </span> */}
                            {item.name}
                          </div>
                          <div>
                            <i className="pi pi-angle-right" />{" "}
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
                <h6>{paymentModes?.gateway && "PAYMENT METHODS"}</h6>
                <ul>
                  {paymentModes && paymentModes?.savedcard && false ? (
                    <li
                      className={
                        activeButtonId?.pid == "197000" ? "payment-active" : ""
                      }
                      onClick={() => {
                        // window.scrollTo({
                        //   top:
                        //     25 *
                        //     parseFloat(
                        //       getComputedStyle(document.documentElement)
                        //         .fontSize
                        //     ),
                        //   left: 0,
                        //   behavior: "smooth",
                        // });
                        setActiveButtonId({ pid: 197000 });
                      }}
                      style={
                        !enableCardID
                          ? { cursor: "pointer" }
                          : { "pointer-events": "none", opacity: 0.4 }
                      }
                    >
                      <div className="d-flex justify-content-between">
                        <div>
                          <span className="paytm-photo">
                            <img
                              src="https://originserver-static1-uat.pvrcinemas.com/pvrcms/paymodes/credircard_68643_oKs6qiQS.png"
                              alt=""
                              className="img-fluid"
                              width={25}
                              height={20}
                            />
                          </span>
                          Saved Card
                        </div>
                        <div>
                          <i className="pi pi-angle-right" />{" "}
                        </div>
                      </div>
                    </li>
                  ) : (
                    ""
                  )}
                  {paymentModes &&
                    paymentModes?.gateway?.map((item, idx) => (
                      <li
                        key={idx}
                        onClick={() => {
                          setViewMode(2);
                          setActiveButtonId(item);
                          setPayModes(item);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="d-flex justify-content-between">
                          <div>
                            <span className="paytm-photo">
                              <img
                                src={item.imageUrl}
                                alt=""
                                className="img-fluid"
                                width={25}
                                height={20}
                              />
                            </span>
                            {item.name}
                          </div>
                          <div>
                            <i className="pi pi-angle-right" />{" "}
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
                <h6>{paymentModes?.wallets.length > 0 && "WALLETS"}</h6>
                <ul>
                  {paymentModes &&
                    paymentModes?.wallets?.map((item, idx) => (
                      <li
                        key={idx}
                        //   className={
                        //     activeButtonId === item.pid ? "payment-active" : ""
                        //   }
                        onClick={() => {
                          setViewMode(2);
                          setActiveButtonId(item);
                          setPayModes(item);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="d-flex justify-content-between">
                          <div>
                            <span className="paytm-photo">
                              <img
                                src={item.imageUrl}
                                alt=""
                                className="img-fluid"
                                width={25}
                                height={20}
                              />
                            </span>
                            {item.name}
                          </div>
                          <div>
                            <i className="pi pi-angle-right" />{" "}
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>)}
          <div className="col-xl-9 col-md-8 col-12"></div>
        </div>
        <div
          className="secure-payment"
          style={
            theme === "inoxTheme"
              ? { backgroundColor: "#DCE2EF" }
              : { backgroundColor: "#F8F6F6" }
          }
        >
          <p>Your transactions are secure and certified</p>
          <div className="pay-image">
            <div className="panacea">
              <img src={certificateImg1} alt="" className="img-fluid" />
            </div>
            <div className="panacea">
              <img src={certificateImg2} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const paymentUI = () => {
    return (
      <>
        <ThemeLightDiv className="payment-grand-amount-mobile">
          <span className="Mobile-topaid">To be paid</span>
          <span className="Mobile-topaid">
            {grandTotal && grandTotal.cutPrice > 0 ? (
              <span className="cut-price-passport">
                <FaRupeeSign
                  style={{
                    fontSize: "10px",
                    fontWeight: "500",
                    marginBottom: "2px",
                  }}
                />
                {parseFloat(grandTotal.cutPrice).toFixed(2)}
              </span>
            ) : (
              ""
            )}
            <FaRupeeSign
              style={{
                fontSize: "10px",
                fontWeight: "500",
                marginBottom: "2px",
              }}
            />
            {grandTotal && grandTotal.finalPrice ? grandTotal.finalPrice : ""}
          </span>
        </ThemeLightDiv>
        <Divider style={{ margin: "7px 0" }} />
        <div>
          {activeButtonId?.pid === 9 ? (
            <PaytemPostpaid />
          ) : activeButtonId?.pid === "EXOF" ? (
            <BankOffers
              offerType="Explore"
              sendDataForPayment={sendDataForPayment}
              payMode={paymentModes}
              imgUrl={theme === "inoxTheme" ? explore : explore_pvr}
              viewMode={1}
            />
          ) : activeButtonId?.pid === "BKOF" ? (
            <BankOffers
              offerType="Bank"
              sendDataForPayment={sendDataForPayment}
              payMode={paymentModes}
              imgUrl={theme === "inoxTheme" ? Bankofferinox : Bankoffer}
              viewMode={2}
            />
          ) : activeButtonId?.pid == "197000" ? (
            <SavedCard juspayinit={juspayinit} payMode={paymentModes} />
          ) : activeButtonId?.pid === "202" ? (
            <DebitCard
              juspayinit={juspayinit}
              payMode={payMode}
              cardNum={cardNumber}
              isBankOfferApplied={isBankOfferApplied}
            />
          ) : activeButtonId?.pid === "203" ? (
            <NetBanking
              juspayinit={juspayinit}
              payMode={payMode}
              bankName={bankName}
            />
          ) : activeButtonId?.pid === "204" ? (
            <Upi juspayinit={juspayinit} payMode={payMode} promotionText={paymentModes?.promotionText} />
          ) : activeButtonId?.pid === "201" ? (
            <CreditCard
              juspayinit={juspayinit}
              payMode={payMode}
              cardNum={cardNumber}
              isBankOfferApplied={isBankOfferApplied}
            />
          ) : activeButtonId?.pid === "W101" ? (
            <Paytm juspayinit={juspayinit} />
          ) : activeButtonId?.pid === "W103" ? (
            <AmazonPay juspayinit={juspayinit} payMode={payMode} />
          ) : activeButtonId?.pid === "W014" ? (
            <Mobikwik juspayinit={juspayinit} />
          ) : activeButtonId?.pid === "W104" ? (
            <Phonepe sendDataForPayment={sendDataForPayment} juspayinit={juspayinit} payMode={payMode} />
          ) : activeButtonId?.pid === "105" ? (
            <GiftCard sendDataForPayment={sendDataForPayment} />
          ) : activeButtonId?.pid === 31 ? (
            <Privilegecard />
          ) : activeButtonId?.pid === "O108" ? (
            <Gyftr
              name={"Gyftr"}
              type={"GYFTR"}
              sendDataForPayment={sendDataForPayment}
            />
          ) : activeButtonId?.pid === "O111" ? (
            // <TicketXpress />
            <Gyftr
              name={"Ticket Xpress"}
              type={"TICKETXPRESS"}
              sendDataForPayment={sendDataForPayment}
            />
          ) : activeButtonId?.pid === "O105" ? (
            <Starpass
              ticketSummary={summaryData}
              sendDataForPayment={sendDataForPayment}
            />
          ) : activeButtonId?.pid === "O102" ? (
            <Mcoupon
              ticketSummary={summaryData}
              sendDataForPayment={sendDataForPayment}
            />
          ) : activeButtonId?.pid === 'O112' ? (
            <PrivilegePlus sendDataForPayment={sendDataForPayment} />
          ) : activeButtonId?.pid === "O104" ? (
            // <Promocode />
            <Gyftr
              name={"Promocode"}
              type={"PROMOCODE"}
              sendDataForPayment={sendDataForPayment}
            />
          ) : activeButtonId?.pid === 43 ? (
            <HyattDining />
          ) : (
            ""
          )}
        </div>
      </>
    );
  };
  // console.log("viewMode", viewMode);

  return (
    <>
      {finalTicketLoading
        ? <PaymodesListSkeleton />
        :
        <>
          <SweetAlert
            error
            show={showAlert}
            //title={errorMsg}
            onConfirm={() => {
              setShowAlert(false);
              // console.log('confirm')
              //navigate('/')
            }}
            onCancel={() => setShowAlert(false)}
            confirmBtnCssClass={
              theme === "inoxTheme"
                ? "confirm-btn-inox border-0"
                : "confirm-btn-pvr border-0"
            }
          >
            {errorMsg}
          </SweetAlert>
          <div className="payment-mobile-header">
            <div className="payment-header-item">
              <span
                className={localStorage.getItem("unpaidMode") === 'true' ? "btn-disabled" : ""}
                onClick={() => {
                  if (viewMode === 0) {
                    if (localStorage.getItem("booking_type") === "GIFTCARD") {
                      navigate("/gift-cards");
                    } else if (localStorage.getItem("fnb_qr")) {
                      navigate(
                        `/food/${localStorage.getItem(
                          "city"
                        )}/qr/${localStorage.getItem("theaterId")}`
                      );
                    } else {
                      // navigate(-1);
                      setPaymentAlert(true);
                    }
                  } else if (viewMode === 2) {
                    setViewMode(1);
                  } else {
                    setPaymentAlert(true);
                  }
                }}
              >
                {" "}
                <img src={angle_left} alt="" className="" />{" "}
              </span>
              <h4>
                {viewMode === 0
                  ? "Checkout"
                  : viewMode === 1
                    ? "Payment"
                    : activeButtonId && activeButtonId?.name}
              </h4>
            </div>
            <div className="search-mobile-cinemas"></div>
          </div>
          <div className="luxe-divider"></div>
          <div className="mt-5">
            {viewMode === 0 ? (
              <div>
                {bookingType === "GIFTCARD" || bookingType === "PASSPORT" ? (
                  <>
                    <GiftCardSummary discount={discount} removeApply={removeApplyData}
                      applyData={applydata} />
                    {bookingType == "GiftCard" ? <>
                      <div className="Mobile-summary-gift"></div>
                      <div className="terms-gift-connect">
                        <div className="terms-check-box">
                          <div
                            className={
                              theme === "inoxTheme"
                                ? " check-box-gift-inox"
                                : " check-box-gift-pvr"
                            }
                          >
                            <div className="flex align-items-center">
                              <Checkbox
                                onChange={(e) => setChecked(e.checked)}
                                checked={checked}
                              ></Checkbox>
                            </div>
                          </div>
                          <div className="term-gift-check">
                            <p onClick={() => setTnCVisibleRight(true)}>
                              {" "}
                              I agree To the <u>terms & Condition</u>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mobile-pay-button-box">
                        <div className="set-view-fift">
                          <div className="Total-amount-gift">
                            <h6 className="price-lastest">
                              <span className="rupees-icon">
                                <FaRupeeSign
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: "500",
                                    marginBottom: "2px",
                                  }}
                                />
                              </span>
                              {localStorage.getItem("giftcard_amount")}
                            </h6>
                            <p>Total</p>
                          </div>
                          <div className="pro-gift">
                            <DarkButton
                              className={
                                checked
                                  ? "btn-proceeded"
                                  : "btn-proceeded btn-opacity"
                              }
                              onClick={() => {
                                hitGCGA();
                                setViewMode(1);
                                localStorage.removeItem("giftcard_tnc");
                              }}
                            >
                              <div className="d-flex justify-content-center px-3">
                                <p className="payto-check">Proceed to Pay</p>
                              </div>
                            </DarkButton>
                          </div>
                        </div>
                      </div></>
                      :
                      <>
                        <div className="mobile-pay-button-box">
                          <div className="proceed-code">
                            <p
                              style={
                                theme === "inoxTheme"
                                  ? { backgroundColor: "#DCE2EF" }
                                  : { backgroundColor: "#F8F6F6" }
                              }
                            >
                              Please put offer codes on next page!
                            </p>
                          </div>
                          <div className="">
                            <ProceedBtn
                              className="btn-proceeded"
                              onClick={() => {
                                isBankOfferApplied ? setViewMode(2)
                                  : setViewMode(1);
                              }}
                            >
                              <div className="d-flex justify-content-between px-3">
                                <div>
                                  Pay{" "}
                                  <FaRupeeSign
                                    style={{
                                      fontSize: "10px",
                                      fontWeight: "500",
                                      marginBottom: "2px",
                                    }}
                                  />
                                  {grandTotal.finalPrice} |
                                </div>
                                <p className="payto-check">Proceed to Pay</p>
                              </div>
                            </ProceedBtn>
                          </div>
                        </div>
                      </>
                    }
                  </>
                ) : (
                  <>
                    <TicketSummary
                      discount={discount}
                      ticketSummaryData={SaveticketSummaryData}
                      removeApply={removeApplyData}
                      applyData={applydata}
                    />
                    <div className="mobile-pay-button-box">
                      <div className="proceed-code">
                        <p
                          style={
                            theme === "inoxTheme"
                              ? { backgroundColor: "#DCE2EF" }
                              : { backgroundColor: "#F8F6F6" }
                          }
                        >
                          Please put offer codes on next page!
                        </p>
                      </div>
                      <div className="">
                        <ProceedBtn
                          className="btn-proceeded"
                          onClick={() => {
                            setViewMode(1);
                            IscustomEvent("Procced To Cart")
                          }}
                        >
                          <div className="d-flex justify-content-between px-3">
                            <div>
                              Pay{" "}
                              <FaRupeeSign
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "500",
                                  marginBottom: "2px",
                                }}
                              />
                              {grandTotal.finalPrice} |
                            </div>
                            <p className="payto-check">Proceed to Pay</p>
                          </div>
                        </ProceedBtn>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ) : viewMode === 1 ? (
              payModeUI()
            ) : viewMode === 2 ? (
              paymentUI()
            ) : (
              ""
            )}
          </div>
          <div>
            <Dialog
              header=""
              visible={loginVewMode}
              // modal={false}
              showHeader={false}
              showCloseIcon={false}
              style={{ width: "40vw" }}
              onHide={() => dispatch(setLoginViewMode({ value: false }))}
              className="register-mobile"
              blockScroll={true}
            >
              <div className="row">
                <Login />
              </div>
            </Dialog>
          </div>
          <div className="gift-coupon-conditions">
            <Sidebar
              visible={tnCVisibleRight}
              position="bottom"
              onHide={() => setTnCVisibleRight(false)}
              showCloseIcon={false}
              style={{ height: "75vh" }}
              className="gift-coupon-conditions"
            >
              <div className="terms-gift">
                <div className="cities-show terms-spaces">
                  <div className="select-city">
                    <h4>Terms & Conditions</h4>
                  </div>
                  <div className="colse-btn">
                    <span onClick={() => setTnCVisibleRight(false)}>
                      <i
                        className="pi pi-times"
                        style={{ color: "#000", cursor: "pointer" }}
                      ></i>
                    </span>
                  </div>
                </div>
                <div className="divider-coupon"></div>
                <div className="terms-content">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: localStorage.getItem("giftcard_tnc"),
                    }}
                    className="px-1"
                  ></p>
                </div>
                <div className="divider-coupon"></div>
              </div>
            </Sidebar>
          </div>
          <div>
            <Dialog
              header=""
              visible={paymentAlert}
              // modal={false}
              showHeader={false}
              showCloseIcon={false}
              style={{ width: "80vw" }}
              onHide={() => setPaymentAlert(true)}
              className=""
              blockScroll={true}
            >
              {(viewMode === 0 && localStorage.getItem("isQR")) ||
                !viewMode === 0 ? (
                <div className="card">
                  <div className="d-flex justify-content-center do-want">
                    <p>Do you want to end the session?</p>
                  </div>
                  <ThemeDiv className="d-flex justify-content-between do-you-want">
                    <DarkButton
                      className="no-m"
                      onClick={() => setPaymentAlert(false)}
                    >
                      Cancel
                    </DarkButton>
                    <DarkButton
                      className="no-m"
                      onClick={() => {
                        if (localStorage.getItem("isQR")) {
                          navigate(
                            `/cinemasessions/${localStorage.getItem(
                              "city"
                            )}/qr/${localStorage.getItem("theaterId")}`
                          );
                        } else {
                          navigate("/?tab=food");
                        }
                      }}
                    >
                      Yes
                    </DarkButton>
                  </ThemeDiv>
                </div>
              ) : viewMode === 0 ? (
                <div className="card end-the-session">
                  <div className="d-flex justify-content-between">
                    <p className="want-do">Do you want to</p>
                    <div
                      onClick={() => setPaymentAlert(false)}
                      className="pointer menu-crosser"
                    >
                      <i className="pi pi-times" />{" "}
                    </div>
                  </div>
                  <div className="end-the-session-box">
                    <DarkButton
                      className="btn-session-end"
                      onClick={() => {
                        if (localStorage.getItem("isQR")) {
                          navigate(
                            `/cinemasessions/${localStorage.getItem(
                              "city"
                            )}/qr/${localStorage.getItem("theaterId")}`
                          );
                        } else if (localStorage.getItem("fnb_qr")) {
                          navigate(
                            `/food/${localStorage.getItem(
                              "city"
                            )}/qr/${localStorage.getItem("theaterId")}`
                          );
                        } else {
                          navigate("/");
                        }
                      }}
                    >
                      End the Session
                    </DarkButton>
                  </div>
                  {summaryData?.orderTicket?.flexiApply ? "" : <>
                    <p className="text-center do-you-want-to or-info">OR</p>
                    <div className="explore-menu">
                      <DarkButton
                        className="btn-explore-menu"
                        onClick={() => navigate(-1)}
                      >
                        Explore Menu
                      </DarkButton>
                    </div>
                  </>}
                </div>
              ) : (
                <div className="card">
                  <div className="d-flex justify-content-center do-want">
                    <p>Do you want to end the session?</p>
                  </div>
                  <ThemeDiv className="d-flex justify-content-between do-you-want">
                    <DarkButton
                      className="no-m"
                      onClick={() => setPaymentAlert(false)}
                    >
                      Cancel
                    </DarkButton>
                    <DarkButton
                      className="no-m"
                      onClick={() => {
                        if (localStorage.getItem("isQR")) {
                          navigate(
                            `/cinemasessions/${localStorage.getItem(
                              "city"
                            )}/qr/${localStorage.getItem("theaterId")}`
                          );
                        } else if (localStorage.getItem("fnb_qr")) {
                          navigate(
                            `/food/${localStorage.getItem(
                              "city"
                            )}/qr/${localStorage.getItem("theaterId")}`
                          );
                        } else {
                          navigate("/?tab=food");
                        }
                      }}
                    >
                      Yes
                    </DarkButton>
                  </ThemeDiv>
                </div>
              )}
            </Dialog>
          </div>
          <div>
            <Dialog
              header=""
              visible={payment}
              modal={false}
              showHeader={false}
              showCloseIcon={false}
              style={{ width: "35vw" }}
              onHide={() => setPayment(false)}
              className="ticke-cancel-modal"
              blockScroll={true}
            >
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="cross-icon">
                    <span onClick={() => setPayment(false)}>
                      <i className="pi pi-times"></i>
                    </span>
                  </div>
                  <div className="saved-amount">
                    <div className="checked-applied">
                      <img src={check} alt="" className="img-fluid check-icon" />
                      <p>Success</p>
                      <h4 dangerouslySetInnerHTML={{ __html: ditxt }}></h4>
                      {/* <p className="promo">using {searchText}</p> */}
                      <div className="awesome">
                        <DarkButton
                          className="awesome"
                          onClick={() => {
                        if(!mobCoupon){
                              setViewMode(0);
                            }
                            setPayment(false);
                          }}
                        >
                          Awesome!
                        </DarkButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Dialog>
          </div>
          <div>
            <Dialog
              header=""
              visible={surcharge}
              modal={false}
              showHeader={false}
              showCloseIcon={false}
              style={{ width: "35vw" }}
              onHide={() => setSurcharge(false)}
              blockScroll={true}
              className="ticke-cancel-modal"
            >
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="upgrade-your">
                    <h3>Enhance your Passport Visit?</h3>
                  </div>
                  <div className="cross-icon">
                    <span onClick={() => { setSurcharge(false); setLoading(false); setpassportCheck(0); }}>
                      <i className="pi pi-times"></i>
                    </span>
                  </div>
                  <div className="please-review-pvr"
                    style={
                      theme === "inoxTheme"
                        ? { backgroundColor: "#DCE2EF" }
                        : { backgroundColor: "#F8F6F6" }
                    }
                  >
                    <p>Additional Charge applicable to access this format/seat type:</p>
                  </div>
                  {surchargeVal && surchargeVal?.surcharge.length > 0 ? (
                    <>
                      {surchargeVal?.surcharge.map((item, idx) => {
                        return (
                          <div className="format-passport-view">
                            <div className="fromat-popup-view">
                              <h3><span className="format-view-gap-passport">{item.text}  </span></h3>
                            </div>
                            <div className="fromat-popup-price">
                              <p>+<FaRupeeSign
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "500",
                                  marginBottom: "2px",
                                }}
                              />{item.amount}</p>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    ""
                  )}

                  {/* <div className="format-passport-view">
                <div className="fromat-popup-view">
                  <h3>Seat: <span className="seat-show-recliner"><img src={SeatShow} alt="" className="img-fluid" />Recliner  </span></h3>
                </div>
                <div className="fromat-popup-price">
                  <p>+<FaRupeeSign
                    style={{
                      fontSize: "10px",
                      fontWeight: "500",
                      marginBottom: "2px",
                    }}
                  />75.00</p>
                </div>
              </div> */}
                  <div className="">
                    <LightButton
                      severity="secondary"
                      className="surcharge-proceed"
                      onClick={() => {
                        setSurcharge(false);
                        setLoading(false);
                        setpassportCheck(0);
                      }}
                    >
                      Cancel
                    </LightButton>
                    <DarkButton
                      className="surcharge-proceed"
                onClick={() => {setSurcharge(false);
                        getApply("PASSPORT", surchargeVal?.coupon, true, passportitem);
                      }}
                    >
                      Yes, Proceed
                    </DarkButton>
                  </div>
                </div>
              </div>
            </Dialog>
          </div>
          <div>
            <Dialog
              header=""
              visible={sendotp}
              modal={false}
              showHeader={false}
              showCloseIcon={false}
              style={{ width: "35vw" }}
              onHide={() => { setSendotp(false); setMobileOtp(""); setLoading(false); }}
              blockScroll={true}
              className="ticke-cancel-modal"
            >
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="upgrade-your">
                    <h3>Enter OTP</h3>
                  </div>
                  <div className="cross-icon">
                    <span onClick={() => { setSendotp(false); setMobileOtp(""); setLoading(false); }}>
                      <i className="pi pi-times"></i>
                    </span>
                  </div>
                  <div className="please-review-pvr"
                    style={
                      theme === "inoxTheme"
                        ? { backgroundColor: "#DCE2EF" }
                        : { backgroundColor: "#F8F6F6" }
                    }
                  >
                    <p className="otp-number">OTP sent to {passportitem?.dependentMobile} successfully:</p>
                  </div>
                  <div className="otp-feild">
                    <OtpInput
                      value={mobileOtp}
                      onChange={(e) => {
                        const numericOtp = e.replace(/\D/g, "");
                        setMobileOtp(numericOtp);
                      }}
                      inputType="tel"
                      numInputs={6}
                      inputStyle={{ width: "60px" }}
                      className="otp-box"
                      isInputNum={true}
                      shouldAutoFocus={true}
                      renderInput={(props, index) => (
                        <input
                          {...props}
                          className="otpfeild"
                        // keyfilter={/^\d*$/}
                        // type="text" // Set the type to "text" to ensure only numeric characters are allowed
                        // pattern="[0-9]*" // Add a pattern attribute to further restrict input
                        // onKeyDown={(e) => preventMoveNext(e, props.input)}
                        />
                      )}
                    />
                  </div>

                  <div className="">
                    <DarkButton
                      className="passport-proceed"
                onClick={() => {setSurcharge(false);
                        getApply("PASSPORT", passportitem?.vouNumber, false, passportitem);
                      }}
                    >
                      Verify
                    </DarkButton>
                  </div>
                </div>
              </div>
            </Dialog>
          </div>
          <PasssportSummary summary={passportSummary} showModal={showSummary} hideModal={setShowSummary} />
        </>
      }
    </>
  );
};

export default MobilePayment;
