import React, { useEffect, useRef, useState } from "react";
import booking from "../../../../assets/profile/booking.svg";
import arrow from "../../../../assets/profile/navigatenext.svg";
import { Sidebar } from "primereact/sidebar";
import { TabView, TabPanel } from "primereact/tabview";
import axios from "axios";
import * as appConstants from "../../../../constants/index";
import nobooking from "../../../../assets/profile/nobooking.svg";
import { DarkButton, LightButton } from "src/styled-components/customButtons";
import DirectionProfile from "../../../../assets/profile/directprofile.svg";
import qricon from "../../../../assets/profile/qr-icon.svg";
import redeemIcon from "../../../../assets/profile/redeemed-voucher.png";
import veg from "../../../../assets/food/veg-icon.svg";
import noveg from "../../../../assets/food/nonveg.svg";
import foodIcon from "../../../../assets/food/foodicon.svg";
import OnTime from "../../../../assets/flexi/FLEXISHOW-LOGO-WHITE.svg";
import InfoFlexi from "../../../../assets/flexi/info.svg";
import noImage from "../../../../assets/food/noimage.png";
import { Swiper, SwiperSlide } from "swiper/react";
import grab from "../../../../assets/profile/grab.svg";
import miv_pvr from "../../../../assets/default-images/vertical-poster-pvr.svg";
import miv_inox from "../../../../assets/default-images/vertical-poster-inox.png";
import foodImg from "../../../../assets/default-images/food-pvr.svg";
import cancelBooking from "../../../../assets/profile/cancel-icon.png";
import { handleMivImageError } from "src/global-functions/imageErrorSrc";
import { Link, useLocation, useNavigate } from "react-router-dom";
import angle_left from "../../../../assets/mobilemoviedetail/arrow-b.svg";
import { ThemeDiv } from "src/styled-components/generalTags";
import PassportLogo from "../../../../assets/payment/passport-logo.png";
import { getProfileVoucher, getSplashData } from "src/services/OtherServices";
import GoogleWallet from "src/views/common/googlewallet/GoogleWallet";
import { formatDateOnProfile } from "src/global-functions/globalFunctions";
import CinemasessionSkeleton from "src/skeletons/CinemasessionSkeleton";
import FlexiHowTouse from "src/views/payment/flexipay/FlexiHowTouse";
import { format, isValid, parse } from "date-fns";
import SweetAlertUtility from "src/views/food-and-beverage/component/SweetAlertUtility";
import { getResponsiveClasses } from "src/views/food-and-beverage/component/ResponsiveClasses";
import FnbProfileBookingSkeleton from "src/skeletons/FnbProfileBookingSkeleton";
import VoucherHistory from "src/views/profile/pages/FamilyBundle/VoucherHistory";

const ProfileMyBooking = () => {
    const navigate = useNavigate();
    const theme = localStorage.getItem("theme");
    const isMobile = window.matchMedia("(max-width: 575px)").matches;
    const [visibleRight, setVisibleRight] = useState(false);
    const [sidebarViewMode, setSidebarViewMode] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const [pastBooking, setPastBooking] = useState([]);
    const [upcomingBooking, setUpcomingBooking] = useState([]);
    const [giftCards, setGiftCards] = useState([]);
    const [vouchers, setVouchers] = useState([]);
    const [spalsh, setSpalsh] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showMoreLoading, setShowMoreLoading] = useState(false);
    const [histLoading, setHistLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [finalTicket, setFinalTicket] = useState(null);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [selectedTabId, setSelectedTabId] = useState("11");
    const [showflexihowitwork, setShowflexiHowitwork] = useState(false);
    const [flexiContent, setFlexiContent] = useState(false);
    const [voucherHistory, setVoucherHistory] = useState(false);
    const [voucherNumber, setVoucherNumber] = useState(null);
    const [fnbHistoryData, setFnbHistoryData] = useState();
    const [pageErrMsg, setPageErrMsg] = useState(false);
    const [activeButton, setActiveButton] = useState(0);
    const [index, setIndex] = useState(0)
    // const [currentIndex, setCurrentIndex] = useState(0);
    const [hasMore, setHasMore] = useState(false);

    const [alertDetails, setAlertDetails] = useState({
        show: false,
        type: "",
        title: "",
        message: "",
    });

    const { currentClasses, getWindowWidth } = getResponsiveClasses();
    const cityName = localStorage.getItem("city") || "";
    const userInfoData = JSON.parse(localStorage.getItem("loginInfo")) || "";
    const authToken = "Bearer " + localStorage.getItem("token") || " ";
    const location = useLocation();


    const filterButtons = ["All", "Tickets", "Food", "Tickets & Food"];

    const preferenceTabList = [
        { id: "11", name: "PVR INOX" },
        { id: "12", name: "Partner" },
        // { id: "13", name: "Languages" },
    ];
    useEffect(() => {
        myBookingRequest();
    }, [selectedTabId]);

    useEffect(() => {
        // myBookingRequest();
        giftCardRequest();
        myVoucherList();
        setActiveIndex(+localStorage.getItem("myBooking_viewMode"));
        setTimeout(() => {
            splashData();
        }, 100);
        // hitGa("my_bookings_previous_food")
    }, []);


    const splashData = async () => {
        try {
            getSplashData().then((res) => {
                const dt = res.data;
                // setBookingLoading(false);
                if (dt.result === "success") {
                    const ndt = dt.output;
                    setSpalsh(ndt);
                    // console.log(ndt, "spalsh");
                }
            });
        } catch (error) {
            // console.log(error);
        }
    };

    const hitGa = (event) => {
        let dataLayer = window["dataLayer"] || [];
        dataLayer.push({
            event: event,
        });
    };

    const myVoucherList = async () => {
        try {
            getProfileVoucher().then((res) => {
                const dt = res.data;
                // setBookingLoading(false);
                if (dt.result === "success") {
                    const ndt = dt.output;
                    setVouchers(ndt);
                    console.log(ndt, "vouchers");
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    const filterOrders = (ftrIndex) => {
        setActiveButton(ftrIndex);
        navigate(location.pathname, { replace: true, state: { showModal: true, filterIndex: null } });
        myBookingRequest(ftrIndex, 0);
        setIndex(0);
    }

    //===== ticket bokking history service ============
    const myBookingRequest = async (filterIndex, showMoreIndex) => {
        if (showMoreIndex === undefined || showMoreIndex === 0) {
            setLoading(true);
            setUpcomingBooking([]);
            setPastBooking([]);
        } else {
            setShowMoreLoading(true);
        }

        const postData = {
            orderId: "",
            cityName: localStorage.getItem("city"),
            past: false,
            bookType: filterButtons[filterIndex] || "All",
            page: showMoreIndex || 0,
        };

        const endpoint = selectedTabId == 11 ? "v1/history/customOrders" : "v1/history/partner/orders";

        try {
            const response = await axios.post(
                `${appConstants.base_url}${endpoint}`,
                postData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                        chain: theme === "inoxTheme" ? "INOX" : "PVR",
                        city: cityName,
                        appVersion: appConstants.av,
                        platform: appConstants.pt,
                        country: appConstants.cname,
                    }
                }
            );

            const dt = response.data;
            if (dt.result === "success") {
                const { content, c: currentBookings, p: pastBookings } = dt.output || {};

                setFlexiContent(content);

                setUpcomingBooking((prev) => [...prev, ...currentBookings]);
                setPastBooking((prev) => [...prev, ...pastBookings]);

                const combineBookingData = [...currentBookings, ...pastBookings]

                setFnbHistoryData(combineBookingData)
                const shouldShowMore = combineBookingData.length > 9;

                setHasMore(shouldShowMore);

                localStorage.removeItem("myBooking_viewMode");
            }
        } catch (error) {
            console.error("Error fetching booking data:", error);
        } finally {
            setLoading(false);
            setShowMoreLoading(false);
        }
    };
    //===== gift card history service ============
    const giftCardRequest = async () => {
        const postData = {
            orderId: "",
            bookType: "GIFTCARD",
            cityName: cityName,
            past: true,
        };
        try {
            await axios
                .post(appConstants.base_url + "v1/history/giftcards", postData, {
                    headers: appConstants.apiHeader,
                })
                .then((res) => {
                    const dt = res.data;
                    if (dt.result === "success") {
                        setGiftCards(dt.output.gc);
                        localStorage.removeItem("myBooking_viewMode");
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };

    //===== gift card history service ============
    const resendBookingInfo = async (bookin_id, booking_type) => {
        const postData = {
            bookingId: bookin_id,
            bookType: booking_type,
        };
        try {
            await axios
                .post(`${appConstants.base_url}v1/history/resend`, postData, {
                    headers: appConstants.apiHeader,
                })
                .then((res) => {
                    const dt = res.data;
                    if (dt.result === "success") {
                        swal({
                            text: "Record successfully sent",
                            timer: 2000,
                            icon: "success",
                            buttons: false,
                        });
                        // setGiftCards(dt.output.gc);
                        // localStorage.removeItem("myBooking_viewMode");
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };

    const filmFoodPoster = (item) => {
        let imgUrl;
        if (item?.bookType === "FOOD") {
            imgUrl = foodImg;
        } else {
            imgUrl = item?.orderFilmCinema?.posterVert
                ? item?.orderFilmCinema?.posterVert
                : theme === "inoxTheme"
                    ? miv_inox
                    : miv_pvr;
        }
        return imgUrl;
    };

    // const fnbHistoryAPI = async () => {
    //   setHistLoading(true);
    //   const formData = {
    //     orderId: "",
    //     bookType: "FOOD",
    //     cityName: cityName,
    //     past: false,
    //   };

    //   try {
    //     const response = await axios.post(
    //       `${appConstants.base_url}v1/history/orders`,
    //       formData,
    //       {
    //         headers: {
    //           "Content-Type": "application/json",
    //           Authorization: authToken,
    //           chain:
    //             localStorage.getItem("theme") === "inoxTheme" ? "INOX" : "PVR",
    //           appVersion: appConstants.av,
    //           platform: appConstants.pt,
    //           country: appConstants.cname,
    //         },
    //       }
    //     );
    //     // debugger
    //     if (response.data.result === "success") {
    //       // const combinedData = [
    //       //   ...response.data.output.p,
    //       //   ...response.data.output.c,
    //       // ].sort((a, b) => {
    //       //   const dateA = new Date(a.bookingTime || a.date);
    //       //   const dateB = new Date(b.bookingTime || b.date);
    //       //   return dateA - dateB;
    //       // });
    //       // setFnbHistoryData(combinedData);
    //       debugger
    //       setFnbHistoryData(response.data.output.p);
    //       console.log(response.data.output.p, "fnbhistory");
    //     } else {
    //       console.error(response.data.msg);
    //       setPageErrMsg(true);
    //     }
    //   } catch (error) {
    //     sweetAlertError("Something went wrong!");
    //     console.error("Failed to fetch seat details:", error);
    //   } finally {
    //     setHistLoading(false);
    //   }
    // };

    const handleImageError = (e) => {
        e.target.src = noImage; // Set fallback image if the original image fails
    };

    const handleHistory = (orderIdEx, filterIndex) => {
        if (orderIdEx) {
            const historyIdData = fnbHistoryData?.filter(
                (items) => items.orderIdEx === orderIdEx
            );
            navigate("/foodbooking", { state: { historyIdData: historyIdData, filterIndex: filterIndex } });
        }
    };

    const sweetAlertError = (msg) => {
        setAlertDetails({
            show: true,
            type: "error",
            title: "Error!",
            message: msg,
        });
    };

    const hideAlert = () => {
        setAlertDetails({ show: false });
    };

    const handleDirection = (e, latitude, longitude) => {
        e.stopPropagation();
        window.open(
            `https://www.google.com/maps?q=${latitude},${longitude}`,
            "_blank"
        );
    };

    useEffect(() => {
        if (location.state?.showModal && isMobile) {
            setVisibleRight(true);
            setActiveIndex(0);
        }

        if (location?.state?.filterIndex !== null && location?.state?.filterIndex !== undefined) {
            setActiveButton(location.state.filterIndex);
        }
    }, [location.state]);


    // Handle scroll event
    const handleShowMore = () => {
        setIndex((prevIndex) => {
            const newIndex = prevIndex + 1;
            myBookingRequest(activeButton, newIndex);
            return newIndex;
        });
    };

    return (
        <>
            <div
                className="profile-img border-line-profile"
                onClick={() => {
                    setVisibleRight(true);
                    setSidebarViewMode(0);
                }}
            >
                <div className="profile-text profile-account">
                    <h4>
                        <span className="avtar">
                            <img src={booking} alt="" className="" />
                        </span>
                    </h4>
                    <div className="profile-text-info">
                        <h2>My Bookings</h2>
                    </div>
                </div>
                <div className="img-arrow">
                    <img src={arrow} alt="" className="" />
                </div>
            </div>
            <div>
                <Sidebar
                    visible={visibleRight}
                    showCloseIcon={false}
                    style={{ width: "100vw" }}
                    position={"right"}
                    className="Mybookinghistory-mobile"
                    onHide={() => {
                        setVisibleRight(false);
                    }}
                    dismissable={false}
                    fullScreen
                    blockScroll={true}
                >
                    <>
                        <div className="Account-view-show-mobile">
                            <div
                                className="account-angle-btn"
                                onClick={() => setVisibleRight(false)}
                            >
                                <h4>
                                    <img src={angle_left} alt="" className="" /> &nbsp; Booking
                                    History
                                </h4>
                            </div>
                        </div>
                        <div className="luxe-divider" style={{ margin: "3px 0" }}></div>
                        <div className="">
                            <div className="information-personal">
                                <div className="mobile-show-booking-profile">
                                    <div
                                        className={
                                            theme === "inoxTheme"
                                                ? "now-showing-filter-inox"
                                                : "now-showing-filter-pvr"
                                        }
                                    >
                                        <TabView
                                            activeIndex={activeIndex}
                                            onTabChange={(e) => {
                                                setActiveIndex(e.index);
                                                e.index == 1
                                                    ? hitGa("my_bookings_previous_gift_card")
                                                    : hitGa("my_bookings_previous_food");
                                            }}
                                        >
                                            <TabPanel header="Tickets & Food">
                                                {loading ? (
                                                    <CinemasessionSkeleton />
                                                ) : (
                                                    <>
                                                        <div className="flter-container">
                                                            <div className="flterinr">
                                                                {filterButtons.map((filterButton, filterIndex) => (
                                                                    <button
                                                                        key={filterButton}
                                                                        className={`flter-button ${activeButton === filterIndex ? "active" : ""}`}
                                                                        onClick={() => filterOrders(filterIndex)}
                                                                    >
                                                                        {filterButton}
                                                                    </button>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <div className="bookingFilter">
                                                            {spalsh?.history == "true" && (
                                                                <div className="d-flex justify-content-center">
                                                                    {preferenceTabList?.map((item, idx) => (
                                                                        <div
                                                                            className={
                                                                                selectedTabId === item.id &&
                                                                                    theme === "inoxTheme"
                                                                                    ? "preference-active-tab-inox tab-view"
                                                                                    : selectedTabId === item.id &&
                                                                                        theme === "pvrTheme"
                                                                                        ? "preference-active-tab-pvr tab-view"
                                                                                        : "preference-inactive-tab tab-view"
                                                                            }
                                                                            key={idx}
                                                                            onClick={() => setSelectedTabId(item.id)}
                                                                        >
                                                                            {item.name}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}
                                                            <div className="upcoming-booking">
                                                                {upcomingBooking.length > 0 && (
                                                                    <div className="upcoming">
                                                                        <p
                                                                            className="my-2"
                                                                            onClick={() => hitGa("my_bookings_previous_food")}
                                                                        >
                                                                            <b>Upcoming Bookings</b>
                                                                        </p>
                                                                    </div>
                                                                )}

                                                                {upcomingBooking.length === 0 ? (
                                                                    <div className="food-ticket-profile">
                                                                        <div className="nobooking-available">
                                                                            <img
                                                                                src={nobooking}
                                                                                alt="nobooking"
                                                                                className="img-fluid"
                                                                            />
                                                                            <h6>No Upcoming Booking Available </h6>
                                                                            <p>
                                                                                Movie bookings & food orders will appear
                                                                                here
                                                                            </p>
                                                                            <DarkButton
                                                                                className="my-book-no"
                                                                                type="submit"
                                                                                onClick={() => navigate("/")}
                                                                            >
                                                                                Book Tickets
                                                                            </DarkButton>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    upcomingBooking?.map((item, idx) => {

                                                                        // const pickUpTime = item?.orderFood?.pickUpTime;
                                                                        // const showTimeStr = item?.showTimeStr;
                                                                        // const parsedDateTime = item?.deliveryMode === "ONSEAT" ? parse(pickUpTime, "yyyy-MM-dd'T'hh:mm a", new Date()) : parse(showTime, "EEE, MMM dd, hh:mm a", new Date());
                                                                        // const parsedDateTime = parse(showTimeStr, "EEE, dd MMM, hh:mm a", new Date());
                                                                        // const formattedDate = parsedDateTime && isValid(parsedDateTime) ? format(parsedDateTime, "eee, MMM dd") : "";
                                                                        // const formattedTime = parsedDateTime && isValid(parsedDateTime) ? format(parsedDateTime, "hh:mm a") : "";
                                                                        // const displayDateTime = parsedDateTime;
                                                                        return (
                                                                            <>
                                                                                {item?.orderTicket?.flexiApply && (
                                                                                    <div
                                                                                        className="flexi-booking"
                                                                                        style={
                                                                                            theme === "inoxTheme"
                                                                                                ? { backgroundColor: "#DCE2EF" }
                                                                                                : { backgroundColor: "#FFF0D6" }
                                                                                        }
                                                                                    >
                                                                                        {/* <p><span className="on-time"><img src={OnTime} alt="" className="img-fluid" /></span> Flexi Ticket <span className="info-flexi" onClick={()=>setShowflexiHowitwork(true)}><img src={InfoFlexi} alt="" className="img-fluid" /></span></p> */}
                                                                                        <p className="flexi-refund-main">
                                                                                            <div className="flexi-time-end">
                                                                                                <span className="on-time">
                                                                                                    <img
                                                                                                        src={OnTime}
                                                                                                        alt=""
                                                                                                        className="img-fluid"
                                                                                                    />
                                                                                                </span>{" "}
                                                                                                <span
                                                                                                    className="info-flexi"
                                                                                                    onClick={() =>
                                                                                                        setShowflexiHowitwork(true)
                                                                                                    }
                                                                                                >
                                                                                                    <img
                                                                                                        src={InfoFlexi}
                                                                                                        alt=""
                                                                                                        className="img-fluid"
                                                                                                    />
                                                                                                </span>{" "}
                                                                                            </div>
                                                                                            {item?.orderTicket
                                                                                                ?.ticketsRefunded > 0 ? (
                                                                                                <div className="refunded-amt">{`${item?.orderTicket
                                                                                                    ?.ticketsRefunded
                                                                                                    } ${item?.orderTicket
                                                                                                        ?.ticketsRefunded > 1
                                                                                                        ? "Tickets"
                                                                                                        : "Ticket"
                                                                                                    }  Refunded!`}</div>
                                                                                            ) : (
                                                                                                ""
                                                                                            )}
                                                                                        </p>
                                                                                    </div>
                                                                                )}

                                                                                {item?.bookType === "FOOD" &&
                                                                                    <div id={item?.bookingId} className="fbhist">
                                                                                        <div onClick={() => handleHistory(item?.orderIdEx, activeButton)}>
                                                                                            {(item?.orderFilmCinema?.language ||
                                                                                                item?.orderFilmCinema?.genre ||
                                                                                                item?.orderFilmCinema?.filmName ||
                                                                                                item?.orderFilmCinema?.posterVert) && (
                                                                                                    <div className="moviedetails">
                                                                                                        {item?.orderFilmCinema?.filmName && (
                                                                                                            <div className="movieImg">
                                                                                                                <img
                                                                                                                    src={
                                                                                                                        item?.orderFilmCinema
                                                                                                                            ?.posterVert || noImage
                                                                                                                    }
                                                                                                                    width={getWindowWidth ? 100 : 60}
                                                                                                                    alt={
                                                                                                                        item?.orderFilmCinema
                                                                                                                            ?.filmName ||
                                                                                                                        "No image available"
                                                                                                                    }
                                                                                                                    onError={handleImageError}
                                                                                                                />
                                                                                                            </div>
                                                                                                        )}
                                                                                                        <div className="movdets">
                                                                                                            {item?.orderFilmCinema?.filmName && (
                                                                                                                <h3 className="movTitle">
                                                                                                                    {item?.orderFilmCinema?.filmName}
                                                                                                                </h3>
                                                                                                            )}
                                                                                                            <ul className="movTags">
                                                                                                                {item?.orderFilmCinema
                                                                                                                    ?.certificate && (
                                                                                                                        <li>
                                                                                                                            {item?.orderFilmCinema
                                                                                                                                ?.certificate || ""}
                                                                                                                        </li>
                                                                                                                    )}
                                                                                                                {item?.orderFilmCinema?.genre && (
                                                                                                                    <li>
                                                                                                                        {item?.orderFilmCinema
                                                                                                                            ?.genre || ""}
                                                                                                                    </li>
                                                                                                                )}
                                                                                                                {item?.orderFilmCinema
                                                                                                                    ?.language && (
                                                                                                                        <li>
                                                                                                                            {
                                                                                                                                item?.orderFilmCinema
                                                                                                                                    ?.language
                                                                                                                            }
                                                                                                                        </li>
                                                                                                                    )}
                                                                                                                {item?.orderFilmCinema?.format && (
                                                                                                                    <li>{item?.orderFilmCinema?.format}</li>
                                                                                                                )}
                                                                                                            </ul>

                                                                                                            {item?.orderTicket?.showTime && (
                                                                                                                <p className="cmnfnt movDtTm">
                                                                                                                    {item?.showTimeStr}
                                                                                                                </p>
                                                                                                            )}
                                                                                                            <div className="addflx">
                                                                                                                {item?.orderFilmCinema
                                                                                                                    ?.theaterName && (
                                                                                                                        <p className="cmnfnt movAdds">
                                                                                                                            {item?.orderFilmCinema?.theaterName}, {item?.orderFilmCinema?.cityName}
                                                                                                                        </p>
                                                                                                                    )}
                                                                                                                <button
                                                                                                                    className="movDirection"
                                                                                                                    onClick={(e) =>
                                                                                                                        handleDirection(
                                                                                                                            e,
                                                                                                                            item?.ltd,
                                                                                                                            item?.lngt
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    <svg
                                                                                                                        width="18"
                                                                                                                        height="18"
                                                                                                                        viewBox="0 0 18 18"
                                                                                                                        fill="none"
                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                    >
                                                                                                                        <path
                                                                                                                            d="M16.8227 7.94196L10.0652 1.18446C9.50266 0.621958 8.51266 0.614458 7.94266 1.18446L1.19266 7.93446C0.607656 8.51946 0.607656 9.46446 1.19266 10.0495L7.94266 16.7995C8.23516 17.092 8.61766 17.2345 9.00016 17.2345C9.38266 17.2345 9.76516 17.092 10.0577 16.7995L16.8002 10.057C17.3927 9.48696 17.4002 8.54196 16.8227 7.94196ZM9.00766 15.742L2.25766 8.99196L9.00766 2.24196L15.7577 8.99196L9.00766 15.742ZM6.00016 8.24946V11.2495H7.50016V8.99946H10.5002V10.8745L13.1252 8.24946L10.5002 5.62446V7.49946H6.75016C6.33766 7.49946 6.00016 7.83696 6.00016 8.24946Z"
                                                                                                                            fill="black"
                                                                                                                        />
                                                                                                                    </svg>
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            }

                                                                                            <div className="moviedetails FoodItems">
                                                                                                <div className="movieImg foodIcon*">
                                                                                                    <img
                                                                                                        src={foodIcon || noImage}
                                                                                                        width={getWindowWidth ? 100 : 60}
                                                                                                        alt={item?.orderFilmCinema?.filmName || "No image available"}
                                                                                                        onError={handleImageError}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="movdets">
                                                                                                    {item?.bookingId && (
                                                                                                        <h3 className="movTitle">
                                                                                                            Booking ID: {item?.bookingId}
                                                                                                        </h3>
                                                                                                    )}
                                                                                                    {item?.orderTicket?.showTime && (
                                                                                                        <p className="cmnfnt movDtTm">
                                                                                                            Order placed on {item?.orderTicket?.showTime}
                                                                                                        </p>
                                                                                                    )}
                                                                                                    {item?.orderFilmCinema
                                                                                                        ?.theaterName && (
                                                                                                            <p className="cmnfnt movAdds">
                                                                                                                {item?.orderFilmCinema?.theaterName}, {item?.orderFilmCinema?.cityName}
                                                                                                            </p>
                                                                                                        )}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="fditm">
                                                                                                {item?.orderFood?.foods?.[0] && (
                                                                                                    <div className="fditems">
                                                                                                        <div className="fdTitle">
                                                                                                            {item?.orderFood?.foods[0]?.veg ?
                                                                                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                    <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#149752" />
                                                                                                                    <circle cx="6.99984" cy="6.99935" r="4.08333" fill="#149752" />
                                                                                                                </svg>
                                                                                                                :
                                                                                                                <svg width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                    <rect x="0.961426" y="0.964844" width="10.0769" height="10.0769" rx="1.5" stroke="#87001B" />
                                                                                                                    <path d="M9 8.80886H3L6.00011 3.18945L9 8.80886Z" fill="#87001B" />
                                                                                                                    <clipPath id="clip0_3452_1183">
                                                                                                                        <rect width="6" height="6" fill="#87001B" transform="translate(3 3)" />
                                                                                                                    </clipPath>
                                                                                                                </svg>
                                                                                                            }
                                                                                                            <div className="titleHist">
                                                                                                                <h3 className="fdName">{item?.orderFood?.foods[0]?.name?.toLowerCase()}</h3>
                                                                                                                {/* {item.deliveryMode ===
                                                                                                                "PICKUP" && (
                                                                                                                    <>
                                                                                                                        <p className="pdate">
                                                                                                                            Pick-up time: {item?.orderTicket?.showTime}
                                                                                                                        </p>
                                                                                                                        <p className="ptheatre">
                                                                                                                            {
                                                                                                                                item?.orderFilmCinema
                                                                                                                                    ?.theaterName
                                                                                                                            }
                                                                                                                        </p>
                                                                                                                    </>
                                                                                                                )} */}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <p className="itmcount">X{item?.orderFood?.foods[0]?.quantity}</p>
                                                                                                    </div>
                                                                                                )}
                                                                                                {item?.orderFood?.foods
                                                                                                    ?.slice(1, 2)
                                                                                                    .map((fooditems) => (
                                                                                                        <div className="fditems">
                                                                                                            <div className="fdTitle">
                                                                                                                {fooditems?.veg ?
                                                                                                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                        <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#149752" />
                                                                                                                        <circle cx="6.99984" cy="6.99935" r="4.08333" fill="#149752" />
                                                                                                                    </svg>
                                                                                                                    :
                                                                                                                    <svg width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                        <rect x="0.961426" y="0.964844" width="10.0769" height="10.0769" rx="1.5" stroke="#87001B" />
                                                                                                                        <path d="M9 8.80886H3L6.00011 3.18945L9 8.80886Z" fill="#87001B" />
                                                                                                                        <clipPath id="clip0_3452_1183">
                                                                                                                            <rect width="6" height="6" fill="#87001B" transform="translate(3 3)" />
                                                                                                                        </clipPath>
                                                                                                                    </svg>
                                                                                                                }
                                                                                                                <div className="titleHist">
                                                                                                                    <h3 className="fdName">
                                                                                                                        {fooditems?.name?.toLowerCase()}
                                                                                                                    </h3>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <p className="itmcount">
                                                                                                                X{fooditems?.quantity}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                {item?.orderFood?.foods?.length > 2 && (
                                                                                                    <div className="fditems fdName">
                                                                                                        {`+${item?.orderFood?.foods?.length - 2
                                                                                                            } more`}
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                            <div className="pckupstatus">
                                                                                                {item?.deliveryMode !== "PICKUP"
                                                                                                    ? "Deliver to Seat"
                                                                                                    : "Picked up from Counter"}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="orderdetails">
                                                                                            <Link to={item?.tu} className="fddwninvce" target="_blank" download="Invoice.pdf">
                                                                                                <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M0 12.666H12V13.9993H0V12.666ZM6.66667 5.99935H11.3333L6 11.3327L0.666667 5.99935H5.33333V0.666016H6.66667V5.99935Z" fill="black" />
                                                                                                </svg>
                                                                                                Download
                                                                                            </Link>

                                                                                            <button onClick={(e) => {
                                                                                                e.stopPropagation();
                                                                                                navigate(`/order-status?bookingid=${item.en_bid}`, { state: { mobHistory: "mobHistory", filterIndex: activeButton } });
                                                                                            }} className="ordstatus">
                                                                                                View Status
                                                                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M8.7809 5.33327L5.2049 1.75726L6.1477 0.814453L11.3332 5.99993L6.1477 11.1853L5.2049 10.2425L8.7809 6.6666H0.666504V5.33327H8.7809Z" fill="black" />
                                                                                                </svg>

                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                {item?.bookType === "BOOKING" &&
                                                                                    <div id={item?.bookingId}
                                                                                        className="fbhist"
                                                                                        key={idx}
                                                                                        onClick={() => {
                                                                                            if (isMobile) {
                                                                                                navigate(
                                                                                                    `/m.booking-detail/${item.bookType}/${item.orderIdEx}/${item?.orderFilmCinema?.theaterId}`
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <div className="moviedetails">
                                                                                            {item?.bookType !== "FOOD" && (
                                                                                                <div className="movieImg">
                                                                                                    <img
                                                                                                        src={filmFoodPoster(item)}
                                                                                                        onError={handleMivImageError}
                                                                                                        alt=""
                                                                                                        className="img-fluid rounded"
                                                                                                        width={getWindowWidth ? 100 : 60}
                                                                                                    />
                                                                                                </div>
                                                                                            )}
                                                                                            <div className="movdets">
                                                                                                {item?.bookType !== "FOOD" && (
                                                                                                    <h3 className="movTitle">
                                                                                                        {item?.orderFilmCinema?.filmName}
                                                                                                    </h3>
                                                                                                )}
                                                                                                {item?.bookType !== "FOOD" && (
                                                                                                    <ul className="movTags">
                                                                                                        {item?.orderFilmCinema
                                                                                                            ?.certificate && (
                                                                                                                <li>
                                                                                                                    {item?.orderFilmCinema
                                                                                                                        ?.certificate || ""}
                                                                                                                </li>
                                                                                                            )}
                                                                                                        {item?.orderFilmCinema?.genre && (
                                                                                                            <li>
                                                                                                                {item?.orderFilmCinema
                                                                                                                    ?.genre || ""}
                                                                                                            </li>
                                                                                                        )}
                                                                                                        {item?.orderFilmCinema
                                                                                                            ?.language && (
                                                                                                                <li>
                                                                                                                    {
                                                                                                                        item?.orderFilmCinema
                                                                                                                            ?.language
                                                                                                                    }
                                                                                                                </li>
                                                                                                            )}
                                                                                                        {item?.orderFilmCinema?.format && (
                                                                                                            <li>{item?.orderFilmCinema?.format}</li>
                                                                                                        )}
                                                                                                    </ul>
                                                                                                )}
                                                                                                {item?.showTimeStr &&
                                                                                                    <div className="cmnfnt movDtTm">
                                                                                                        {item?.showTimeStr}
                                                                                                    </div>
                                                                                                }

                                                                                                <div className="addflx">
                                                                                                    {item?.orderFilmCinema
                                                                                                        ?.theaterName && (
                                                                                                            <p className="cmnfnt movAdds">
                                                                                                                {item?.orderFilmCinema?.theaterName}, {item?.orderFilmCinema?.cityName}
                                                                                                            </p>
                                                                                                        )}
                                                                                                    <button
                                                                                                        className="movDirection"
                                                                                                        onClick={(e) =>
                                                                                                            handleDirection(
                                                                                                                e,
                                                                                                                item?.ltd,
                                                                                                                item?.lngt
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <svg
                                                                                                            width="18"
                                                                                                            height="18"
                                                                                                            viewBox="0 0 18 18"
                                                                                                            fill="none"
                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                        >
                                                                                                            <path
                                                                                                                d="M16.8227 7.94196L10.0652 1.18446C9.50266 0.621958 8.51266 0.614458 7.94266 1.18446L1.19266 7.93446C0.607656 8.51946 0.607656 9.46446 1.19266 10.0495L7.94266 16.7995C8.23516 17.092 8.61766 17.2345 9.00016 17.2345C9.38266 17.2345 9.76516 17.092 10.0577 16.7995L16.8002 10.057C17.3927 9.48696 17.4002 8.54196 16.8227 7.94196ZM9.00766 15.742L2.25766 8.99196L9.00766 2.24196L15.7577 8.99196L9.00766 15.742ZM6.00016 8.24946V11.2495H7.50016V8.99946H10.5002V10.8745L13.1252 8.24946L10.5002 5.62446V7.49946H6.75016C6.33766 7.49946 6.00016 7.83696 6.00016 8.24946Z"
                                                                                                                fill="black"
                                                                                                            />
                                                                                                        </svg>
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {item?.orderFood?.foods?.length > 0 &&
                                                                                            <div className="moviedetails FoodItems">
                                                                                                <div className="movieImg foodIcon*">
                                                                                                    <img
                                                                                                        src={foodIcon || noImage}
                                                                                                        width={getWindowWidth ? 100 : 60}
                                                                                                        alt={item?.orderFilmCinema?.filmName || "No image available"}
                                                                                                        onError={handleImageError}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="movdets">
                                                                                                    {item?.bookingId && (
                                                                                                        <h3 className="movTitle">
                                                                                                            Booking ID: {item?.bookingId}
                                                                                                        </h3>
                                                                                                    )}
                                                                                                    {item?.orderTicket?.showTime && (
                                                                                                        <p className="cmnfnt movDtTm">
                                                                                                            Order placed on {item?.orderTicket?.showTime}
                                                                                                        </p>
                                                                                                    )}
                                                                                                    {item?.orderFilmCinema
                                                                                                        ?.theaterName && (
                                                                                                            <p className="cmnfnt movAdds">
                                                                                                                {item?.orderFilmCinema?.theaterName}, {item?.orderFilmCinema?.cityName}
                                                                                                            </p>
                                                                                                        )}
                                                                                                </div>
                                                                                            </div>
                                                                                        }

                                                                                        <div className="fditm">
                                                                                            {item?.orderFood?.foods?.[0] && (
                                                                                                <div className="fditems">
                                                                                                    <div className="fdTitle">
                                                                                                        {item?.orderFood?.foods[0]?.veg ?
                                                                                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#149752" />
                                                                                                                <circle cx="6.99984" cy="6.99935" r="4.08333" fill="#149752" />
                                                                                                            </svg>
                                                                                                            :
                                                                                                            <svg width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                <rect x="0.961426" y="0.964844" width="10.0769" height="10.0769" rx="1.5" stroke="#87001B" />
                                                                                                                <path d="M9 8.80886H3L6.00011 3.18945L9 8.80886Z" fill="#87001B" />
                                                                                                                <clipPath id="clip0_3452_1183">
                                                                                                                    <rect width="6" height="6" fill="#87001B" transform="translate(3 3)" />
                                                                                                                </clipPath>
                                                                                                            </svg>
                                                                                                        }
                                                                                                        <div className="titleHist">
                                                                                                            <h3 className="fdName">{item?.orderFood?.foods[0]?.name?.toLowerCase()}</h3>
                                                                                                            {/* {item.deliveryMode ===
                                                                                                                "PICKUP" && (
                                                                                                                    <>
                                                                                                                        <p className="pdate">
                                                                                                                            Pick-up time: {item?.orderTicket?.showTime}
                                                                                                                        </p>
                                                                                                                        <p className="ptheatre">
                                                                                                                            {
                                                                                                                                item?.orderFilmCinema
                                                                                                                                    ?.theaterName
                                                                                                                            }
                                                                                                                        </p>
                                                                                                                    </>
                                                                                                                )} */}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <p className="itmcount">X{item?.orderFood?.foods[0]?.quantity}</p>
                                                                                                </div>
                                                                                            )}
                                                                                            {item?.orderFood?.foods?.slice(1, 2).map((fooditems) => (
                                                                                                <div className="fditems">
                                                                                                    <div className="fdTitle">
                                                                                                        {fooditems?.veg ?
                                                                                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#149752" />
                                                                                                                <circle cx="6.99984" cy="6.99935" r="4.08333" fill="#149752" />
                                                                                                            </svg>
                                                                                                            :
                                                                                                            <svg width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                <rect x="0.961426" y="0.964844" width="10.0769" height="10.0769" rx="1.5" stroke="#87001B" />
                                                                                                                <path d="M9 8.80886H3L6.00011 3.18945L9 8.80886Z" fill="#87001B" />
                                                                                                                <clipPath id="clip0_3452_1183">
                                                                                                                    <rect width="6" height="6" fill="#87001B" transform="translate(3 3)" />
                                                                                                                </clipPath>
                                                                                                            </svg>
                                                                                                        }
                                                                                                        <div className="titleHist">
                                                                                                            <h3 className="fdName">
                                                                                                                {fooditems?.name?.toLowerCase()}
                                                                                                            </h3>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <p className="itmcount">
                                                                                                        X{fooditems?.quantity}
                                                                                                    </p>
                                                                                                </div>
                                                                                            ))}
                                                                                            {item?.orderFood?.foods?.length > 2 && (
                                                                                                <div className="fditems fdName">
                                                                                                    {`+${item?.orderFood?.foods?.length - 2
                                                                                                        } more`}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                        {item?.bookType === "FOOD" || selectedTabId == 12 ? (
                                                                                            ""
                                                                                        ) : item.fa ? (
                                                                                            <div className="py-3 px-2">
                                                                                                <LightButton
                                                                                                    className="w-100 py-2 border-0 rounded add-food-history-mobile"
                                                                                                    onClick={(e) => {
                                                                                                        e.stopPropagation();
                                                                                                        // console.log("addFoodBtn", item);
                                                                                                        const url = `/food/${localStorage.getItem(
                                                                                                            "city"
                                                                                                        )}/${item?.orderFilmCinema
                                                                                                            ?.theaterName
                                                                                                            }/${item?.orderFilmCinema
                                                                                                                ?.theaterId
                                                                                                            }`;

                                                                                                        let url2 = url
                                                                                                            .replace(/\s/g, "-")
                                                                                                            .toLocaleLowerCase();
                                                                                                        navigate(url);
                                                                                                        localStorage.setItem(
                                                                                                            "booking_type",
                                                                                                            "FOOD"
                                                                                                        );
                                                                                                        localStorage.setItem(
                                                                                                            "bookingid",
                                                                                                            item?.orderIdEx
                                                                                                        );
                                                                                                        localStorage.setItem(
                                                                                                            "theaterId",
                                                                                                            item?.orderFilmCinema
                                                                                                                ?.theaterId
                                                                                                        );
                                                                                                        localStorage.setItem(
                                                                                                            "transid",
                                                                                                            "0"
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    Add Food
                                                                                                </LightButton>
                                                                                            </div>
                                                                                        ) : ""}
                                                                                        <div
                                                                                            className="luxe-divider"
                                                                                            style={{ margin: "0px 0" }}
                                                                                        ></div>
                                                                                        <div className="d-flex justify-content-between py-2 px-1 bg-muted">
                                                                                            <div className="text-muted">
                                                                                                Booking ID: {item?.bookingId}
                                                                                            </div>
                                                                                            {selectedTabId == 11 ? (
                                                                                                <div
                                                                                                    onClick={(e) => {
                                                                                                        e.stopPropagation();
                                                                                                        resendBookingInfo(
                                                                                                            item?.orderIdEx,
                                                                                                            "BOOKING"
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    <b>
                                                                                                        Resend
                                                                                                        <i className="pi pi-angle-right" />{" "}
                                                                                                    </b>
                                                                                                </div>
                                                                                            ) : (
                                                                                                ""
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                        );
                                                                    })
                                                                )}
                                                            </div>
                                                            <div className="past-booking-mobile">
                                                                <div className="upcoming">
                                                                    {pastBooking.length === 0 ? (
                                                                        ""
                                                                    ) : (
                                                                        <p className="my-1">
                                                                            <b>Past Bookings</b>
                                                                        </p>
                                                                    )}
                                                                </div>
                                                                {pastBooking.length === 0 ? (
                                                                    <div className="food-ticket-profile">
                                                                        <div className="nobooking-available">
                                                                            <img
                                                                                src={nobooking}
                                                                                alt="nobooking"
                                                                                className="img-fluid"
                                                                            />
                                                                            <h6>No Past Booking Available </h6>
                                                                            <p>
                                                                                Movie bookings & food orders will appear
                                                                                here
                                                                            </p>
                                                                            <DarkButton
                                                                                className="my-book-no"
                                                                                type="submit"
                                                                                onClick={() => navigate("/")}
                                                                            >
                                                                                Book
                                                                            </DarkButton>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    pastBooking?.map((item, idx) => {
                                                                        // const pickUpTime = item?.orderFood?.pickUpTime;
                                                                        // const showTime = item?.orderTicket?.showTime;
                                                                        // const parsedDateTime = item?.deliveryMode === "ONSEAT" ? parse(pickUpTime, "yyyy-MM-dd'T'hh:mm a", new Date()) : parse(showTime, "EEE, MMM dd, hh:mm a", new Date());
                                                                        // const formattedDate = parsedDateTime && isValid(parsedDateTime) ? format(parsedDateTime, "eee, MMM dd") : "";
                                                                        // const formattedTime = parsedDateTime && isValid(parsedDateTime) ? format(parsedDateTime, "hh:mm a") : "";
                                                                        // const displayDateTime = `${formattedDate}${formattedTime ? `, ${formattedTime}` : ""}`;
                                                                        return (
                                                                            <>
                                                                                {item?.orderTicket?.flexiApply && (
                                                                                    <div
                                                                                        className="flexi-booking"
                                                                                        style={
                                                                                            theme === "inoxTheme"
                                                                                                ? { backgroundColor: "#DCE2EF" }
                                                                                                : { backgroundColor: "#FFF0D6" }
                                                                                        }
                                                                                    >
                                                                                        {/* <p><span className="on-time"><img src={OnTime} alt="" className="img-fluid" /></span> Flexi Ticket <span className="info-flexi" onClick={()=>setShowflexiHowitwork(true)}><img src={InfoFlexi} alt="" className="img-fluid" /></span></p> */}
                                                                                        <p className="flexi-refund-main">
                                                                                            <div className="flexi-time-end">
                                                                                                <span className="on-time">
                                                                                                    <img
                                                                                                        src={OnTime}
                                                                                                        alt=""
                                                                                                        className="img-fluid"
                                                                                                    />
                                                                                                </span>{" "}
                                                                                                <span
                                                                                                    className="info-flexi"
                                                                                                    onClick={() =>
                                                                                                        setShowflexiHowitwork(true)
                                                                                                    }
                                                                                                >
                                                                                                    <img
                                                                                                        src={InfoFlexi}
                                                                                                        alt=""
                                                                                                        className="img-fluid"
                                                                                                    />
                                                                                                </span>{" "}
                                                                                            </div>
                                                                                            {item?.orderTicket
                                                                                                ?.ticketsRefunded > 0 ? (
                                                                                                <div className="refunded-amt">{`${item?.orderTicket
                                                                                                    ?.ticketsRefunded
                                                                                                    } ${item?.orderTicket
                                                                                                        ?.ticketsRefunded > 1
                                                                                                        ? "Tickets"
                                                                                                        : "Ticket"
                                                                                                    }  Refunded!`}</div>
                                                                                            ) : (
                                                                                                ""
                                                                                            )}
                                                                                        </p>
                                                                                    </div>
                                                                                )}
                                                                                {item?.bookType === "FOOD" &&
                                                                                    <div id={item?.bookingId + idx} className="fbhist">
                                                                                        <div onClick={() => handleHistory(item?.orderIdEx, activeButton)}>
                                                                                            {(item?.orderFilmCinema?.language ||
                                                                                                item?.orderFilmCinema?.genre ||
                                                                                                item?.orderFilmCinema?.filmName ||
                                                                                                item?.orderFilmCinema?.posterVert) && (
                                                                                                    <div className="moviedetails">
                                                                                                        {item?.orderFilmCinema?.filmName && (
                                                                                                            <div className="movieImg">
                                                                                                                <img
                                                                                                                    src={
                                                                                                                        item?.orderFilmCinema
                                                                                                                            ?.posterVert || noImage
                                                                                                                    }
                                                                                                                    width={getWindowWidth ? 100 : 60}
                                                                                                                    alt={
                                                                                                                        item?.orderFilmCinema
                                                                                                                            ?.filmName ||
                                                                                                                        "No image available"
                                                                                                                    }
                                                                                                                    onError={handleImageError}
                                                                                                                />
                                                                                                            </div>
                                                                                                        )}
                                                                                                        <div className="movdets">
                                                                                                            {item?.orderFilmCinema?.filmName && (
                                                                                                                <h3 className="movTitle">
                                                                                                                    {item?.orderFilmCinema?.filmName}
                                                                                                                </h3>
                                                                                                            )}
                                                                                                            <ul className="movTags">
                                                                                                                {item?.orderFilmCinema
                                                                                                                    ?.certificate && (
                                                                                                                        <li>
                                                                                                                            {item?.orderFilmCinema
                                                                                                                                ?.certificate || ""}
                                                                                                                        </li>
                                                                                                                    )}
                                                                                                                {item?.orderFilmCinema?.genre && (
                                                                                                                    <li>
                                                                                                                        {item?.orderFilmCinema
                                                                                                                            ?.genre || ""}
                                                                                                                    </li>
                                                                                                                )}
                                                                                                                {item?.orderFilmCinema
                                                                                                                    ?.language && (
                                                                                                                        <li>
                                                                                                                            {
                                                                                                                                item?.orderFilmCinema
                                                                                                                                    ?.language
                                                                                                                            }
                                                                                                                        </li>
                                                                                                                    )}
                                                                                                                {item?.orderFilmCinema?.format && (
                                                                                                                    <li>{item?.orderFilmCinema?.format}</li>
                                                                                                                )}
                                                                                                            </ul>

                                                                                                            {item?.orderTicket?.showTime && (
                                                                                                                <p className="cmnfnt movDtTm">
                                                                                                                    {item?.showTimeStr}
                                                                                                                </p>
                                                                                                            )}
                                                                                                            <div className="addflx">
                                                                                                                {item?.orderFilmCinema
                                                                                                                    ?.theaterName && (
                                                                                                                        <p className="cmnfnt movAdds">
                                                                                                                            {item?.orderFilmCinema?.theaterName}, {item?.orderFilmCinema?.cityName}
                                                                                                                        </p>
                                                                                                                    )}
                                                                                                                <button
                                                                                                                    className="movDirection"
                                                                                                                    onClick={(e) =>
                                                                                                                        handleDirection(
                                                                                                                            e,
                                                                                                                            item?.ltd,
                                                                                                                            item?.lngt
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    <svg
                                                                                                                        width="18"
                                                                                                                        height="18"
                                                                                                                        viewBox="0 0 18 18"
                                                                                                                        fill="none"
                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                    >
                                                                                                                        <path
                                                                                                                            d="M16.8227 7.94196L10.0652 1.18446C9.50266 0.621958 8.51266 0.614458 7.94266 1.18446L1.19266 7.93446C0.607656 8.51946 0.607656 9.46446 1.19266 10.0495L7.94266 16.7995C8.23516 17.092 8.61766 17.2345 9.00016 17.2345C9.38266 17.2345 9.76516 17.092 10.0577 16.7995L16.8002 10.057C17.3927 9.48696 17.4002 8.54196 16.8227 7.94196ZM9.00766 15.742L2.25766 8.99196L9.00766 2.24196L15.7577 8.99196L9.00766 15.742ZM6.00016 8.24946V11.2495H7.50016V8.99946H10.5002V10.8745L13.1252 8.24946L10.5002 5.62446V7.49946H6.75016C6.33766 7.49946 6.00016 7.83696 6.00016 8.24946Z"
                                                                                                                            fill="black"
                                                                                                                        />
                                                                                                                    </svg>
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            }

                                                                                            <div className="moviedetails FoodItems">
                                                                                                <div className="movieImg foodIcon*">
                                                                                                    <img
                                                                                                        src={foodIcon || noImage}
                                                                                                        width={getWindowWidth ? 100 : 60}
                                                                                                        alt={item?.orderFilmCinema?.filmName || "No image available"}
                                                                                                        onError={handleImageError}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="movdets">
                                                                                                    {item?.bookingId && (
                                                                                                        <h3 className="movTitle">
                                                                                                            Booking ID: {item?.bookingId}
                                                                                                        </h3>
                                                                                                    )}
                                                                                                    {item?.orderTicket?.showTime && (
                                                                                                        <p className="cmnfnt movDtTm">
                                                                                                            Order placed on {item?.orderTicket?.showTime}
                                                                                                        </p>
                                                                                                    )}
                                                                                                    {item?.orderFilmCinema
                                                                                                        ?.theaterName && (
                                                                                                            <p className="cmnfnt movAdds">
                                                                                                                {item?.orderFilmCinema?.theaterName}, {item?.orderFilmCinema?.cityName}
                                                                                                            </p>
                                                                                                        )}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="fditm">
                                                                                                {item?.orderFood?.foods?.[0] && (
                                                                                                    <div className="fditems">
                                                                                                        <div className="fdTitle">
                                                                                                            {item?.orderFood?.foods[0]?.veg ?
                                                                                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                    <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#149752" />
                                                                                                                    <circle cx="6.99984" cy="6.99935" r="4.08333" fill="#149752" />
                                                                                                                </svg>
                                                                                                                :
                                                                                                                <svg width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                    <rect x="0.961426" y="0.964844" width="10.0769" height="10.0769" rx="1.5" stroke="#87001B" />
                                                                                                                    <path d="M9 8.80886H3L6.00011 3.18945L9 8.80886Z" fill="#87001B" />
                                                                                                                    <clipPath id="clip0_3452_1183">
                                                                                                                        <rect width="6" height="6" fill="#87001B" transform="translate(3 3)" />
                                                                                                                    </clipPath>
                                                                                                                </svg>
                                                                                                            }
                                                                                                            <div className="titleHist">
                                                                                                                <h3 className="fdName">{item?.orderFood?.foods[0]?.name?.toLowerCase()}</h3>
                                                                                                                {/* {item.deliveryMode ===
                                                                                                                "PICKUP" && (
                                                                                                                    <>
                                                                                                                        <p className="pdate">
                                                                                                                            Pick-up time: {item?.orderTicket?.showTime}
                                                                                                                        </p>
                                                                                                                        <p className="ptheatre">
                                                                                                                            {
                                                                                                                                item?.orderFilmCinema
                                                                                                                                    ?.theaterName
                                                                                                                            }
                                                                                                                        </p>
                                                                                                                    </>
                                                                                                                )} */}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <p className="itmcount">X{item?.orderFood?.foods[0]?.quantity}</p>
                                                                                                    </div>
                                                                                                )}
                                                                                                {item?.orderFood?.foods
                                                                                                    ?.slice(1, 2)
                                                                                                    .map((fooditems) => (
                                                                                                        <div className="fditems">
                                                                                                            <div className="fdTitle">
                                                                                                                {fooditems?.veg ?
                                                                                                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                        <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#149752" />
                                                                                                                        <circle cx="6.99984" cy="6.99935" r="4.08333" fill="#149752" />
                                                                                                                    </svg>
                                                                                                                    :
                                                                                                                    <svg width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                        <rect x="0.961426" y="0.964844" width="10.0769" height="10.0769" rx="1.5" stroke="#87001B" />
                                                                                                                        <path d="M9 8.80886H3L6.00011 3.18945L9 8.80886Z" fill="#87001B" />
                                                                                                                        <clipPath id="clip0_3452_1183">
                                                                                                                            <rect width="6" height="6" fill="#87001B" transform="translate(3 3)" />
                                                                                                                        </clipPath>
                                                                                                                    </svg>
                                                                                                                }
                                                                                                                <div className="titleHist">
                                                                                                                    <h3 className="fdName">
                                                                                                                        {fooditems?.name?.toLowerCase()}
                                                                                                                    </h3>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <p className="itmcount">
                                                                                                                X{fooditems?.quantity}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                {item?.orderFood?.foods?.length > 2 && (
                                                                                                    <div className="fditems fdName">
                                                                                                        {`+${item?.orderFood?.foods?.length - 2
                                                                                                            } more`}
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                            <div className="pckupstatus">
                                                                                                {item?.deliveryMode !== "PICKUP"
                                                                                                    ? "Deliver to Seat"
                                                                                                    : "Picked up from Counter"}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="orderdetails">
                                                                                            <Link to={item?.tu} className="fddwninvce" target="_blank" download="Invoice.pdf">
                                                                                                <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M0 12.666H12V13.9993H0V12.666ZM6.66667 5.99935H11.3333L6 11.3327L0.666667 5.99935H5.33333V0.666016H6.66667V5.99935Z" fill="black" />
                                                                                                </svg>
                                                                                                Download
                                                                                            </Link>

                                                                                            <button onClick={(e) => {
                                                                                                e.stopPropagation();
                                                                                                navigate(`/order-status?bookingid=${item.en_bid}`, { state: { mobHistory: "mobHistory", filterIndex: activeButton } });
                                                                                            }} className="ordstatus">
                                                                                                View Status
                                                                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M8.7809 5.33327L5.2049 1.75726L6.1477 0.814453L11.3332 5.99993L6.1477 11.1853L5.2049 10.2425L8.7809 6.6666H0.666504V5.33327H8.7809Z" fill="black" />
                                                                                                </svg>

                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                }

                                                                                {item?.bookType === "BOOKING" &&
                                                                                    <div id={item?.bookingId}
                                                                                        className="fbhist"
                                                                                        key={idx}
                                                                                        onClick={() => {
                                                                                            if (isMobile) {
                                                                                                navigate(
                                                                                                    `/m.booking-detail/${item.bookType}/${item.orderIdEx}/${item?.orderFilmCinema?.theaterId}`
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <div className="moviedetails">
                                                                                            {item?.bookType !== "FOOD" && (
                                                                                                <div className="movieImg">
                                                                                                    <img
                                                                                                        src={filmFoodPoster(item)}
                                                                                                        onError={handleMivImageError}
                                                                                                        alt=""
                                                                                                        className="img-fluid rounded"
                                                                                                        width={getWindowWidth ? 100 : 60}
                                                                                                    />
                                                                                                </div>
                                                                                            )}
                                                                                            <div className="movdets">
                                                                                                {item?.bookType !== "FOOD" && (
                                                                                                    <h3 className="movTitle">
                                                                                                        {item?.orderFilmCinema?.filmName}
                                                                                                    </h3>
                                                                                                )}
                                                                                                {item?.bookType !== "FOOD" && (
                                                                                                    <ul className="movTags">
                                                                                                        {item?.orderFilmCinema
                                                                                                            ?.certificate && (
                                                                                                                <li>
                                                                                                                    {item?.orderFilmCinema
                                                                                                                        ?.certificate || ""}
                                                                                                                </li>
                                                                                                            )}
                                                                                                        {item?.orderFilmCinema?.genre && (
                                                                                                            <li>
                                                                                                                {item?.orderFilmCinema
                                                                                                                    ?.genre || ""}
                                                                                                            </li>
                                                                                                        )}
                                                                                                        {item?.orderFilmCinema
                                                                                                            ?.language && (
                                                                                                                <li>
                                                                                                                    {
                                                                                                                        item?.orderFilmCinema
                                                                                                                            ?.language
                                                                                                                    }
                                                                                                                </li>
                                                                                                            )}
                                                                                                        {item?.orderFilmCinema?.format && (
                                                                                                            <li>{item?.orderFilmCinema?.format}</li>
                                                                                                        )}
                                                                                                    </ul>
                                                                                                )}
                                                                                                {item?.showTimeStr &&
                                                                                                    <div className="cmnfnt movDtTm">
                                                                                                        {item?.showTimeStr}
                                                                                                    </div>
                                                                                                }

                                                                                                <div className="addflx">
                                                                                                    {item?.orderFilmCinema
                                                                                                        ?.theaterName && (
                                                                                                            <p className="cmnfnt movAdds">
                                                                                                                {item?.orderFilmCinema?.theaterName}, {item?.orderFilmCinema?.cityName}
                                                                                                            </p>
                                                                                                        )}
                                                                                                    <button
                                                                                                        className="movDirection"
                                                                                                        onClick={(e) =>
                                                                                                            handleDirection(
                                                                                                                e,
                                                                                                                item?.ltd,
                                                                                                                item?.lngt
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <svg
                                                                                                            width="18"
                                                                                                            height="18"
                                                                                                            viewBox="0 0 18 18"
                                                                                                            fill="none"
                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                        >
                                                                                                            <path
                                                                                                                d="M16.8227 7.94196L10.0652 1.18446C9.50266 0.621958 8.51266 0.614458 7.94266 1.18446L1.19266 7.93446C0.607656 8.51946 0.607656 9.46446 1.19266 10.0495L7.94266 16.7995C8.23516 17.092 8.61766 17.2345 9.00016 17.2345C9.38266 17.2345 9.76516 17.092 10.0577 16.7995L16.8002 10.057C17.3927 9.48696 17.4002 8.54196 16.8227 7.94196ZM9.00766 15.742L2.25766 8.99196L9.00766 2.24196L15.7577 8.99196L9.00766 15.742ZM6.00016 8.24946V11.2495H7.50016V8.99946H10.5002V10.8745L13.1252 8.24946L10.5002 5.62446V7.49946H6.75016C6.33766 7.49946 6.00016 7.83696 6.00016 8.24946Z"
                                                                                                                fill="black"
                                                                                                            />
                                                                                                        </svg>
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {item?.orderFood?.foods?.length > 0 &&
                                                                                            <div className="moviedetails FoodItems">
                                                                                                <div className="movieImg foodIcon*">
                                                                                                    <img
                                                                                                        src={foodIcon || noImage}
                                                                                                        width={getWindowWidth ? 100 : 60}
                                                                                                        alt={item?.orderFilmCinema?.filmName || "No image available"}
                                                                                                        onError={handleImageError}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="movdets">
                                                                                                    {item?.bookingId && (
                                                                                                        <h3 className="movTitle">
                                                                                                            Booking ID: {item?.bookingId}
                                                                                                        </h3>
                                                                                                    )}
                                                                                                    {item?.orderTicket?.showTime && (
                                                                                                        <p className="cmnfnt movDtTm">
                                                                                                            Order placed on {item?.orderTicket?.showTime}
                                                                                                        </p>
                                                                                                    )}
                                                                                                    {item?.orderFilmCinema
                                                                                                        ?.theaterName && (
                                                                                                            <p className="cmnfnt movAdds">
                                                                                                                {item?.orderFilmCinema?.theaterName}, {item?.orderFilmCinema?.cityName}
                                                                                                            </p>
                                                                                                        )}
                                                                                                </div>
                                                                                            </div>
                                                                                        }

                                                                                        <div className="fditm">
                                                                                            {item?.orderFood?.foods?.[0] && (
                                                                                                <div className="fditems">
                                                                                                    <div className="fdTitle">
                                                                                                        {item?.orderFood?.foods[0]?.veg ?
                                                                                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#149752" />
                                                                                                                <circle cx="6.99984" cy="6.99935" r="4.08333" fill="#149752" />
                                                                                                            </svg>
                                                                                                            :
                                                                                                            <svg width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                <rect x="0.961426" y="0.964844" width="10.0769" height="10.0769" rx="1.5" stroke="#87001B" />
                                                                                                                <path d="M9 8.80886H3L6.00011 3.18945L9 8.80886Z" fill="#87001B" />
                                                                                                                <clipPath id="clip0_3452_1183">
                                                                                                                    <rect width="6" height="6" fill="#87001B" transform="translate(3 3)" />
                                                                                                                </clipPath>
                                                                                                            </svg>
                                                                                                        }
                                                                                                        <div className="titleHist">
                                                                                                            <h3 className="fdName">{item?.orderFood?.foods[0]?.name?.toLowerCase()}</h3>
                                                                                                            {/* {item.deliveryMode ===
                                                                                                                "PICKUP" && (
                                                                                                                    <>
                                                                                                                        <p className="pdate">
                                                                                                                            Pick-up time: {item?.orderTicket?.showTime}
                                                                                                                        </p>
                                                                                                                        <p className="ptheatre">
                                                                                                                            {
                                                                                                                                item?.orderFilmCinema
                                                                                                                                    ?.theaterName
                                                                                                                            }
                                                                                                                        </p>
                                                                                                                    </>
                                                                                                                )} */}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <p className="itmcount">X{item?.orderFood?.foods[0]?.quantity}</p>
                                                                                                </div>
                                                                                            )}
                                                                                            {item?.orderFood?.foods?.slice(1, 2).map((fooditems) => (
                                                                                                <div className="fditems">
                                                                                                    <div className="fdTitle">
                                                                                                        {fooditems?.veg ?
                                                                                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#149752" />
                                                                                                                <circle cx="6.99984" cy="6.99935" r="4.08333" fill="#149752" />
                                                                                                            </svg>
                                                                                                            :
                                                                                                            <svg width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                <rect x="0.961426" y="0.964844" width="10.0769" height="10.0769" rx="1.5" stroke="#87001B" />
                                                                                                                <path d="M9 8.80886H3L6.00011 3.18945L9 8.80886Z" fill="#87001B" />
                                                                                                                <clipPath id="clip0_3452_1183">
                                                                                                                    <rect width="6" height="6" fill="#87001B" transform="translate(3 3)" />
                                                                                                                </clipPath>
                                                                                                            </svg>
                                                                                                        }
                                                                                                        <div className="titleHist">
                                                                                                            <h3 className="fdName">
                                                                                                                {fooditems?.name?.toLowerCase()}
                                                                                                            </h3>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <p className="itmcount">
                                                                                                        X{fooditems?.quantity}
                                                                                                    </p>
                                                                                                </div>
                                                                                            ))}
                                                                                            {item?.orderFood?.foods?.length > 2 && (
                                                                                                <div className="fditems fdName">
                                                                                                    {`+${item?.orderFood?.foods?.length - 2
                                                                                                        } more`}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                        {item?.bookType === "FOOD" || selectedTabId == 12 ? (
                                                                                            ""
                                                                                        ) : item.fa ? (
                                                                                            <div className="py-3 px-2">
                                                                                                <LightButton
                                                                                                    className="w-100 py-2 border-0 rounded add-food-history-mobile"
                                                                                                    onClick={(e) => {
                                                                                                        e.stopPropagation();
                                                                                                        // console.log("addFoodBtn", item);
                                                                                                        // const url = `/food/${localStorage.getItem(
                                                                                                        //     "city"
                                                                                                        // )}/${item?.orderFilmCinema
                                                                                                        //     ?.theaterName
                                                                                                        //     }/${item?.orderFilmCinema
                                                                                                        //         ?.theaterId
                                                                                                        //     }`;
                                                                                                        const url = "/select-food"

                                                                                                        let url2 = url
                                                                                                            .replace(/\s/g, "-")
                                                                                                            .toLocaleLowerCase();
                                                                                                        navigate(url);
                                                                                                        localStorage.setItem(
                                                                                                            "booking_type",
                                                                                                            "FOOD"
                                                                                                        );
                                                                                                        localStorage.setItem(
                                                                                                            "bookingid",
                                                                                                            item?.orderIdEx
                                                                                                        );
                                                                                                        localStorage.setItem(
                                                                                                            "theaterId",
                                                                                                            item?.orderFilmCinema
                                                                                                                ?.theaterId
                                                                                                        );
                                                                                                        localStorage.setItem(
                                                                                                            "transid",
                                                                                                            "0"
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    Add Food
                                                                                                </LightButton>
                                                                                            </div>
                                                                                        ) : ""}
                                                                                        <div
                                                                                            className="luxe-divider"
                                                                                            style={{ margin: "0px 0" }}
                                                                                        ></div>
                                                                                        <div className="d-flex justify-content-between py-2 px-1 bg-muted">
                                                                                            <div className="text-muted">
                                                                                                Booking ID: {item?.bookingId}
                                                                                            </div>
                                                                                            {selectedTabId == 11 ? (
                                                                                                <div
                                                                                                    onClick={(e) => {
                                                                                                        e.stopPropagation();
                                                                                                        resendBookingInfo(
                                                                                                            item?.orderIdEx,
                                                                                                            "BOOKING"
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    <b>
                                                                                                        Resend
                                                                                                        <i className="pi pi-angle-right" />{" "}
                                                                                                    </b>
                                                                                                </div>
                                                                                            ) : (
                                                                                                ""
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                        );
                                                                    })
                                                                )}
                                                            </div>

                                                            {hasMore && (
                                                                <button className="showmorebtn" onClick={handleShowMore} disabled={loading}>
                                                                    {showMoreLoading ? "Loading..." : "Show More"}
                                                                </button>
                                                            )}
                                                        </div>
                                                    </>
                                                )}
                                            </TabPanel>
                                            <TabPanel header="Gift Cards">
                                                {giftCards.length === 0 ? (
                                                    <div className="food-ticket-profile">
                                                        <div className="nobooking-available">
                                                            <img
                                                                src={nobooking}
                                                                alt="nobooking"
                                                                className="img-fluid"
                                                            />
                                                            <h6>No Gift Card Available </h6>
                                                            <p>Gift card will appear here</p>
                                                            <DarkButton
                                                                className="my-book-no"
                                                                type="submit"
                                                                onClick={() => navigate("/gift-cards")}
                                                            >
                                                                Buy Gift Cards
                                                            </DarkButton>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="row">
                                                        {giftCards &&
                                                            giftCards.map((item, idx) => {
                                                                return (
                                                                    <>
                                                                        <div key={idx}>
                                                                            {item?.cardList?.map((cItem, cIdx) => (
                                                                                <div className="col-md-4" key={cIdx}>
                                                                                    <div
                                                                                        className="mobile-gift-voucher my-3 border rounded"
                                                                                        key={cIdx}
                                                                                        onClick={() => {
                                                                                            console.log("gifCardClicked");
                                                                                        }}
                                                                                    >
                                                                                        <img
                                                                                            src={item.img}
                                                                                            alt={cItem.orderId}
                                                                                            className="img-fluid rounded-top"
                                                                                        />
                                                                                        <div className="book-history-date-m">
                                                                                            <div>Date/Time</div>
                                                                                            <div>{item?.orderDate}</div>
                                                                                        </div>
                                                                                        <div
                                                                                            className="luxe-divider"
                                                                                            style={{ margin: "4px 0" }}
                                                                                        ></div>
                                                                                        <div className="book-history-date-m bg-muted">
                                                                                            <div>Order Id: {item?.id}</div>
                                                                                            <div
                                                                                                onClick={() =>
                                                                                                    resendBookingInfo(
                                                                                                        item?.id,
                                                                                                        "GIFTCARD"
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <b>
                                                                                                    {/* Resend
                                                  <i className="pi pi-angle-right" />{" "} */}
                                                                                                </b>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </>
                                                                );
                                                            })}
                                                    </div>
                                                )}
                                            </TabPanel>
                                            {spalsh && spalsh?.google_Wallet == "true" ? (
                                                <TabPanel header="Voucher">
                                                    <div className="row">
                                                        {vouchers &&
                                                            vouchers.map((item, idx) => {
                                                                return (
                                                                    <>
                                                                        {item?.voucherType == "Food" ||
                                                                            item?.voucherType == "Voucher" ? (
                                                                            <>
                                                                                <div className="col-md-12 col-sm-12" onClick={() => { setVoucherNumber(item?.voucher); setVoucherHistory(true) }}>
                                                                                    <div className="google-wallet-privilege-voucher my-3">
                                                                                        <div className="google-wallet-privilege-voucher-show-details">
                                                                                            <div className={`vail-date-privi ${item?.status == "Redeemed" ? "redeem-icon" : ""}`} >
                                                                                                <div className="google-wallet-privilege-id">
                                                                                                    {item?.description?.includes(
                                                                                                        "Google Wallet"
                                                                                                    ) ? (
                                                                                                        <>
                                                                                                            <span>
                                                                                                                {"GOOGLE WALLET"}
                                                                                                            </span>
                                                                                                            <span className="mb-3">
                                                                                                                {"FREE POPCORN [50 Gm]"}
                                                                                                            </span>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            <span style={{
                                                                                                                visibility: "hidden",
                                                                                                            }}>
                                                                                                                {item?.description}
                                                                                                            </span>
                                                                                                            <span
                                                                                                                className="mb-3"
                                                                                                            >
                                                                                                                {item?.description}
                                                                                                            </span>
                                                                                                        </>
                                                                                                    )}
                                                                                                    <h6>Voucher Number</h6>
                                                                                                    <p>{item?.voucher}</p>
                                                                                                    <h6>Valid Till</h6>
                                                                                                    <p>{item?.validityTo}</p>
                                                                                                    {item?.status != "Redeemed" ?
                                                                                                        <p>Balance Amount: ₹{item?.balAmount}</p>
                                                                                                        : <p style={{ visibility: "hidden" }}>Balance Amount</p>}
                                                                                                    <img src={qricon} className="voucher-qr-icon" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        ) : item?.voucherType == "Passport" ? (
                                                                            <>
                                                                                <div className="col-md-12 col-sm-12">
                                                                                    <div className="google-wallet-passport-card my-3">
                                                                                        <div className="row">
                                                                                            <div className="col-md-12 col-12">
                                                                                                <div className="passport-google-detail">
                                                                                                    <h6>{item?.description}</h6>
                                                                                                    <p></p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-12">
                                                                                                <div className="passport-google-wallet">
                                                                                                    <img
                                                                                                        src={PassportLogo}
                                                                                                        alt=""
                                                                                                        className="img-fluid"
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-6 col-6">
                                                                                                <div className="passport-google-details">
                                                                                                    <h6>Passport Number</h6>
                                                                                                    <p>{item?.voucher}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-md-6 col-6">
                                                                                                <div className="passport-google-details mobi-right">
                                                                                                    <h6>Vaild Till</h6>
                                                                                                    <p>{item?.validityTo}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </>
                                                                );
                                                            })}
                                                    </div>
                                                </TabPanel>
                                            ) : (
                                                ""
                                            )}
                                        </TabView>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </Sidebar>
            </div>
            <FlexiHowTouse
                visible={showflexihowitwork}
                content={flexiContent}
                setVisible={setShowflexiHowitwork}
            />
            <VoucherHistory visible={voucherHistory} content={voucherNumber} setVisible={setVoucherHistory} />
        </>
    );
};

export default ProfileMyBooking;
