import React, { useEffect, useState } from "react";
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import RemoveCricle from "../../../assets/static-page/remove-cricle.svg";
import { DonateButton } from "../paymentTags";
import SweetAlert from "react-bootstrap-sweetalert";
import { applycoupon } from "src/services/CouponPayment";
import { InputText } from "primereact/inputtext";
const Starpass = ({ ticketSummary, sendDataForPayment }) => {
  const theme = localStorage.getItem("theme");
  const isMobile = window.matchMedia("(max-width: 575px)").matches;
  const [numOfSeats, setTotalSeat] = useState(1);
  const [formValues, setFormValues] = useState([{ code: "" }]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [outputData, setOutputData] = useState(null)
  const [couponForm, setCouponForm] = useState([]);
  const [selectedCouponCode, setSelectedCouponCode] = useState(null);

  // useEffect(() => {
  //   if (ticketSummary && ticketSummary.orderTicket) {
  //     setTotalSeat(ticketSummary.orderTicket.numOfSeats);
  //   }
  // }, [ticketSummary]);

  useEffect(() => {
    if (ticketSummary && ticketSummary.orderTicket) {
      const ticketNum = ticketSummary.orderTicket.numOfSeats;
      setTotalSeat(ticketNum);
      const totalValidCoupons = Array.from({ length: ticketNum }, (_, idx) => ({
        id: `couponCode${idx + 1}`,
        code: "",
        applied: false,
      }));
      setCouponForm(totalValidCoupons);
    }
  }, [ticketSummary]);
  let handleChange = (i, e) => {
    console.log(i, e);
    let newFormValues = [...formValues];
    newFormValues[i]["code"] = e.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    if(newFormValues[i]["code"]){
      setFormValues(newFormValues);
    }else{
      setFormValues(newFormValues);
    }
  };

  const handleCouponFormChange = (idx, evnt) => {
    // console.log("handleCouponFormChange", idx);
    let newFormValues = [...couponForm];
    newFormValues[idx]["code"] = evnt.target.value
      .replace(/[^a-zA-Z0-9]/g, "")
      .toUpperCase();
      setCouponForm(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, { code: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    if (newFormValues.length > 1) {
      newFormValues.splice(i, 1);
      setFormValues(newFormValues);
    }
  };

  let handleSubmit = () => {
    let arrayval = [];
    let sendVal;
    formValues.map((element, index) => {
      arrayval.push(element.code);
    });
    sendVal = arrayval.toString();
    if (!sendVal) {
      setErrorMsg("Please enter voucher code");
      setShowAlert(true);
      return "";
    }
    getApply(sendVal);
  };

  const handleCouponFormSubmit = (idx, codeval) => {
    getApply(idx, codeval);
  };

  const getApply = async (idx, couponval) => {
    setLoading(true);
    const postData = {
      ccode: localStorage.getItem("theaterId"),
      bookingId: localStorage.getItem("bookingid"),
      transId: localStorage.getItem("transid"),
      bookType: localStorage.getItem("booking_type")
        ? localStorage.getItem("booking_type")
        : "BOOKING",      
      coupon: couponval,
      couponId: 0,
      couponType: "STARPASS",
      qr: localStorage.getItem("isQR") && isMobile ? 'YES':  "NO",
      card: "",
      mobile: "",
    };
    applycoupon(postData)
      .then((res) => {
        const dt = res.data;
        if(dt.result === 'success'){
          setOutputData(dt)
          let newFormValues = [...couponForm];
          newFormValues[idx]["applied"] = true;
          setCouponForm(newFormValues);
        }
        dt.voucheName = "STAR-PASS";
        sendDataForPayment(dt);
        localStorage.setItem('isOfferAppplied', true)
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });


      
  };
  return (
    <>
      <SweetAlert
        error
        show={showAlert}
        title={errorMsg}
        onConfirm={() => {
          setShowAlert(false);
          // console.log('confirm')
          //navigate('/')
        }}
        onCancel={() => setShowAlert(false)}
        confirmBtnCssClass={
          theme === "inoxTheme"
            ? "confirm-btn-inox border-0"
            : "confirm-btn-pvr border-0"
        }
      >
        {errorMsg}
      </SweetAlert>
      <div className="">
        <div className="payment-form">
          <div className="paywithdebit">
            <div className="debit-card show-in-desktop-view">
              <h6>Star Pass</h6>
            </div>
            <div className="to-paid">
              {/* <p>
                To be Paid:
                <span className="price-cross">₹8888</span>{" "}
                <span className="price-blod">₹{ticketSummary.}</span>
              </p> */}
            </div>
          </div>
          <div className="payment-debitcard">
            <form>
              <div className="row">
                <div className="col-md-12">
                  <div className="noofcoupon">
                    <div className="coupon-no">
                      <h6>No. of Coupons {numOfSeats}</h6>
                    </div>
                    {/* <div className="coupon-add">
                      <div className="coupon-add">
                        <div className={outputData ?  "incre-decre  border rounded btn-opacity" : "incre-decre  border rounded"}>
                          <button
                            className="subtract border-0 bg-white"
                            type="button"
                            disabled={formValues.length <= 1}
                            onClick={() =>
                              formValues.length > 1
                                ? removeFormFields(formValues.length - 1)
                                : ""
                            }
                          >
                            <FaMinus />
                          </button>
                          <span className="text-feilded border-0 borderless">
                            {formValues.length}
                          </span>
                          <button
                            className="plus border-0 bg-white"
                            type="button"
                            disabled={formValues.length >= numOfSeats}
                            // onClick={() => addFoods(item)}
                            onClick={() =>
                              formValues.length < numOfSeats
                                ? addFormFields()
                                : ""
                            }
                          >
                            <FaPlus />
                          </button>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="col-md-12">
                  {couponForm.map((item, idx) => (
                    <div className="row" key={idx}>
                      <div className="col-9 col-md-10">
                        <div className="payment-feild">
                          <span className="p-float-label">
                            <InputText
                              type="text"
                              maxLength={18}
                              id={item.id}
                              className="offer-control"
                              value={item.code.replace(/ /g, '') || ""}                              
                              onChange={(e) => handleCouponFormChange(idx, e)}
                              disabled={item.applied}
                            />
                            <label htmlFor="chips ssn_input">
                              {`Enter Voucher Code ${idx + 1}`}
                            </label>
                          </span>
                        </div>
                      </div>
                      <div className="col-3 col-md-2">
                        <div className="verfiy-pay mt-1">
                          <DonateButton
                            className={
                              !loading && item?.code && !item.applied
                                ? "verfiy-payment"
                                : "verfiy-payment btn-opacity"
                            }
                            type="button"
                            onClick={() => {
                              setSelectedCouponCode(item);
                              handleCouponFormSubmit(idx, item?.code);
                            }}
                          >
                            {loading && selectedCouponCode?.id === item.id ? (
                              <i
                                className="pi pi-spin pi-spinner mx-1"
                                style={{ fontSize: "1.5em" }}
                              ></i>
                            ) : item.applied ? (
                              <i
                                className="pi pi-check-circle mx-1"
                                style={{ fontSize: "1.5em" }}
                              ></i>
                            ) : (
                              ""
                            )}
                            {item.applied ? "Applied" : "Apply"}
                          </DonateButton>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {/* <div className="col-md-12">
                  {formValues.map((element, index) => (
                    <div className="payment-feild">
                      <span className="p-float-label">
                        <Chips
                        id="chips"
                        value= {element.name || ""} 
                        onChange= {e => handleChange(index, e)} 
                      />
                        <InputText
                          type="text"
                          maxlength="18"
                          id="chips"
                          className="offer-control"
                          value={element.code.replace(/ /g, '') || ""}
                          onChange={(e) => handleChange(index, e)}
                        />
                        <label htmlFor="chips">Enter Voucher Code</label>
                      </span>
                      {index > 0 ? (
                        <div className="remove-cricle">
                          <img
                            src={RemoveCricle}
                            alt=""
                            onClick={() => removeFormFields(index)}
                            className="img-fluid"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </div> */}

                {/* <div className="col-md-12">
                  <div className="payment-feild">
                    <span className="p-float-label">
                      <Chips
                        id="chips"
                        value={value6}
                        onChange={(e) => setValue6(e.value)}
                      />
                      <label htmlFor="chips">Enter Voucher Code</label>
                    </span>
                    <div className="remove-cricle">
                    <img src={RemoveCricle} alt="" className="img-fluid"/>
                  </div>
                  </div>
                </div> */}
                <div className="col-md-12">
                  <div className="gyftr-box">
                    <p>Star Pass will not be refunded for cancellation</p>
                    <div className="divider-gyftr"></div>
                    <p>
                      *If there is an internet connectivity problem while booking
                      the free tickets using your coupon codes and the tickets
                      are not issued please wait for 30 minutes before trying to
                      use the Coupon Codes again.
                    </p>
                  </div>
                </div>
                {/* <div className="col-md-12">
                  <div className="verfiy-pay">
                    <DonateButton
                      className={
                        !loading && formValues[0]?.code
                          ? "verfiy-payment"
                          : "verfiy-payment btn-opacity"
                      }
                      type="button"
                      onClick={handleSubmit}
                    >
                      Apply
                      {loading ? (
                        <i
                          className="pi pi-spin pi-spinner"
                          style={{ fontSize: "1.5em" }}
                        ></i>
                      ) : (
                        ""
                      )}
                    </DonateButton>
                  </div>
                </div> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Starpass;
  