import React, { useState } from "react";
import googlestore from "../../../assets/images/Google Play.svg";
import appstore from "../../../assets/images/App Store.svg";
import certificateImg1 from "../../../assets/brand/certificate1.png";
import certificateImg2 from "../../../assets/brand/certificate2.png";
import facebook from "../../../assets/brand/fbimg.svg";
import instagram from "../../../assets/brand/instagramimg.svg";
import youtube from "../../../assets/brand/youtubeimg.svg";
import twittergrey from "../../../assets/brand/twittergrey.svg";
import linkinked from "../../../assets/brand/linkedinimg.svg";
import { Link } from "react-router-dom";
import MobileFooter from "src/views/mobile-views/mobile-footer/MobileFooter";

const Footer = () => {
  const theme = localStorage.getItem("theme");
  const date = new Date();
  const year = date.getFullYear();
  const hitGa = (event) => {
    let dataLayer = window["dataLayer"] || [];
    dataLayer.push({
      event: event,
    });
  };

  const hitDynamicGa = (event, social) => {
    let dataLayer = window["dataLayer"] || [];

    dataLayer.push({
      event: event,
      social_link: social,
    });
  };
  return (
    <>
      <div className="footer-m">
        <div
          className="d-flex justify-content-center footer-bg"
          style={{ height: "10rem" }}
        >
          <div style={{ width: "90%" }}>
            <div className="d-flex justify-content-between mt-4 align-items-center">
              <div className=" text-white fs-5 footer-policy">
                {/* Certification by: */}
                <a
                        title="Certification by panaceainfosec"
                        href='https://seal.panaceainfosec.com/index.php?certid=CERT9EE7E85FBE'
                        target="_blank"
                        className="text-dark" 
                      >
                <img
                  src={certificateImg1}
                  alt="certificate image"
                  height={55}
                  className="rounded mx-0"
                />
                </a>
                <a
                      title="Certification by norton"
                        href='https://in.norton.com/?form_file=fdf/splash.fdf&dn=www.pvrcinemas.com&lang=en'
                        target="_blank"
                        className="text-dark" 
                      >
                <img
                  src={certificateImg2}
                  alt=""
                  height={55}
                  className="rounded mx-2"
                />
                </a>
              </div>
              <div className="text-secondary media">
                <ul>
                  <li>
                    <a
                      href={
                        theme === "inoxTheme"
                          ? "https://www.facebook.com/INOXLEISURE"
                          : "https://www.facebook.com/moviesatpvr"
                      }
                      target="_blank"
                      onClick={() =>
                        hitDynamicGa("footer_social_link", "facebook")
                      }
                      title="follow us on facebook"
                    >
                      <img
                        src={facebook}
                        alt=""
                        className="img-fluid new-logo"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        theme === "inoxTheme"
                          ? "https://www.instagram.com/inoxmovies"
                          : "https://www.instagram.com/pvrcinemas_official"
                      }
                      target="_blank"
                      onClick={() =>
                        hitDynamicGa("footer_social_link", "instagram")
                      }
                      title="follow us on instagram"
                    >
                      <img
                        src={instagram}
                        alt=""
                        className="img-fluid new-logo"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        theme === "inoxTheme"
                          ? "https://www.youtube.com/user/moviesatINOX"
                          : "https://www.youtube.com/user/PVRChannel"
                      }
                      target="_blank"
                      onClick={() =>
                        hitDynamicGa("footer_social_link", "youtube")
                      }
                      title="follow us on youtube"
                    >
                      <img
                        src={youtube}
                        alt=""
                        className="img-fluid new-logo"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        theme === "inoxTheme"
                          ? "https://twitter.com/INOXMovies"
                          : "https://twitter.com/_PVRCinemas"
                      }
                      onClick={() =>
                        hitDynamicGa("footer_social_link", "twitter")
                      }
                      target="_blank"
                      title="follow us on twitter"
                    >
                      <img
                        src={twittergrey}
                        alt=""
                        className="img-fluid new-logo"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        theme === "inoxTheme"
                          ? "https://www.linkedin.com/company/inox-leisure-ltd/?originalSubdomain=in"
                          : "https://www.linkedin.com/company/pvr-limited/"
                      }
                      target="_blank"
                      title="follow us on linkedin"
                      onClick={() =>
                        hitDynamicGa("footer_social_link", "linkedin")
                      }
                    >
                      <img
                        src={linkinked}
                        alt=""
                        className="img-fluid new-logo"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="app-store">
                <a
                  href={
                    theme === "inoxTheme"
                      ? "https://play.google.com/store/apps/details?id=com.bt.inx"
                      : "https://play.google.com/store/apps/details?id=com.net.pvr"
                  }
                  target="_blank"
                  title="follow us on google play store"
                  onClick={() => hitGa("footer_google_play")}
                >
                  <img
                    src={googlestore}
                    alt="play-store"
                    height={35}
                    className="store-gp"
                  />
                </a>
                <a
                  href={
                    theme === "inoxTheme"
                      ? "https://apps.apple.com/in/app/inox/id474776230"
                      : "https://apps.apple.com/in/app/pvr-cinemas-movie-tickets/id878127874"
                  }
                  target="_blank"
                  title="follow us on app store"
                  onClick={() => hitGa("footer_app_store")}
                >
                  {" "}
                  <img src={appstore} alt="play-store" height={35} />
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="media-tabview">
                  <ul>
                    <li>
                      <a
                        title="follow us on google facebook"
                        href={
                          theme === "inoxTheme"
                            ? "https://www.facebook.com/INOXLEISURE"
                            : "https://www.facebook.com/moviesatpvr"
                        }
                        target="_blank"
                        onClick={() =>
                          hitDynamicGa("footer_social_link", "facebook")
                        }
                      >
                        <img
                          src={facebook}
                          alt=""
                          className="img-fluid  new-logo"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        title="follow us on google instagram"
                        href={
                          theme === "inoxTheme"
                            ? "https://www.instagram.com/inoxmovies"
                            : "https://www.instagram.com/pvrcinemas_official"
                        }
                        target="_blank"
                        onClick={() =>
                          hitDynamicGa("footer_social_link", "instagram")
                        }
                      >
                        <img
                          src={instagram}
                          alt=""
                          className="img-fluid  new-logo"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        title="follow us on youtube"
                        href={
                          theme === "inoxTheme"
                            ? "https://www.youtube.com/user/moviesatINOX"
                            : "https://www.youtube.com/user/PVRChannel"
                        }
                        target="_blank"
                        onClick={() =>
                          hitDynamicGa("footer_social_link", "youtube")
                        }
                      >
                        <img
                          src={youtube}
                          alt=""
                          className="img-fluid  new-logo"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        title="follow us on X"
                        href={
                          theme === "inoxTheme"
                            ? "https://twitter.com/INOXMovies"
                            : "https://twitter.com/_PVRCinemas"
                        }
                        target="_blank"
                        onClick={() =>
                          hitDynamicGa("footer_social_link", "twitter")
                        }
                      >
                        <img
                          src={twittergrey}
                          alt=""
                          className="img-fluid new-logo"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        title="follow us on linkedin"
                        href={
                          theme === "inoxTheme"
                            ? "https://www.linkedin.com/company/inox-leisure-ltd/?originalSubdomain=in"
                            : "https://www.linkedin.com/company/pvr-limited/"
                        }
                        target="_blank"
                        onClick={() =>
                          hitDynamicGa("footer_social_link", "linkinked")
                        }
                      >
                        <img
                          src={linkinked}
                          alt=""
                          className="img-fluid  new-logo"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between text-white mt-0 copy-right">
              <div className="footer-policy">
                {" "}
                &copy; {year} All rights reserved
              </div>
              <div className="footer-policy">
                <Link
                  to="/privacy-policy"
                  onClick={() => hitGa("footer_privacy_policy")}
                >
                  Privacy Policy{" "}
                </Link>
                &emsp;{" "}
                <Link
                  to="/terms-conditions/booking"
                  onClick={() => hitGa("footer_term&condition")}
                >
                  Terms & Conditions
                </Link>{" "}
                &emsp;
                <Link
                  to="/terms-use"
                  onClick={() => hitGa("footer_term_of_use")}
                >
                  {" "}
                  Terms of Use
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MobileFooter />
    </>
  );
};

export default Footer;
