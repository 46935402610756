import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import pvrinoxlogo from "../../assets/food/pvrinoxlogo.svg";
import pvrinoxlogowhite from "../../assets/food/inoxPVRwhite.svg";
import atcinema from "../../assets/food/atcinema.svg";
import notatcinema from "../../assets/food/notatcinema.svg";
import enablelocation from "../../assets/food/enablelocation.svg";
import backbtn from "../../assets/food/backBtnn.svg";
import backbtnwhite from "../../assets/food/backwhite.svg";
import SweetAlertUtility from "./component/SweetAlertUtility";
import { getResponsiveClasses } from "./component/ResponsiveClasses";

const CinemaInOut = () => {
	const [showLocationModal, setShowLocationModal] = useState(false);
	const [loadingAtCinema, setLoadingAtCinema] = useState(false);
	const [loadingNotAtCinema, setLoadingNotAtCinema] = useState(false);
	const [alertDetails, setAlertDetails] = useState({ show: false, type: '', title: '', message: '' });

	const { currentClasses, getWindowWidth } = getResponsiveClasses();
	const navigate = useNavigate();
	const theme = localStorage.getItem("theme");

	// Check location permission
	const checkLocationPermission = useCallback(() => {
		if (navigator.permissions) {
			navigator.permissions.query({ name: 'geolocation' })
				.then((result) => {
					if (result.state === 'denied') {
						setShowLocationModal(true);
					} else if (result.state === 'granted') {
						requestLocationPermission();
					} else {
						setShowLocationModal(true);
					}
				})
				.catch(() => {
					setShowLocationModal(true);
				});
		} else {
			setShowLocationModal(true);
		}
	}, []);

	// Request location permission
	const requestLocationPermission = useCallback(() => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude, longitude } = position.coords;
					setShowLocationModal(false);
					localStorage.setItem("userLocation", JSON.stringify({ latitude, longitude }));
					localStorage.setItem("lat", latitude);
					localStorage.setItem("long", longitude);
					navigate("/select-cinema-for-fnb");
				},
				(error) => {
					handleLocationError(error);
					// navigate("/select-cinema-for-fnb");
				}
			);
		} else {
			sweetAlertError("Geolocation is not supported by this browser.");
			navigate("/select-cinema-for-fnb");
		}
	}, [navigate]);

	// Handle location errors
	const handleLocationError = (error) => {
		let errorMessage = "An unknown error occurred.";
		if (error.code === 1) {
			errorMessage = "Location access denied. Please enable location permissions.";
		} else if (error.code === 2) {
			errorMessage = "Location information is unavailable.";
		} else if (error.code === 3) {
			errorMessage = "The request to get user location timed out.";
		}
		setAlertDetails({
			show: true,
			type: 'error',
			title: 'Error!',
			message: errorMessage,
		});
		setLoadingAtCinema(false);
		setLoadingNotAtCinema(false);
	};

	// Handle card click based on cinema type
	const handleCinemaSelection = (type) => {
		localStorage.setItem("TransactionType", type);
		if (type === "InCinemaFB") {
			setLoadingAtCinema(true);
		} else {
			setLoadingNotAtCinema(true);
		}
		checkLocationPermission();
	};

	// Handle modal close and redirection on skip
	const handleLocationModalClose = useCallback((skipbtn) => {
		setShowLocationModal(false);
		setLoadingAtCinema(false);
		setLoadingNotAtCinema(false);
		if (skipbtn === "skipbtn") {
			localStorage.removeItem("userLocation");
			navigate("/select-cinema-for-fnb");
		}
	}, [navigate]);

	// Display error alert
	const sweetAlertError = (msg) => {
		setAlertDetails({
			show: true,
			type: 'error',
			title: 'Error!',
			message: msg,
		});
	};

	// Hide error alert
	const hideAlert = () => {
		setAlertDetails({ show: false });
	};

	return (
		<>
			<div className="cinemainout">
				<div className="fnbinout-back">
					<img
						onClick={() => navigate("/?tab=food")}
						src={theme === "pvrTheme" ? backbtn : backbtnwhite}
						alt="Back"
					/>
				</div>
				<div className="pvrlogo">
					<img
						src={theme === "pvrTheme" ? pvrinoxlogo : pvrinoxlogowhite}
						height={38}
						alt="PVR Inox Logo"
					/>
				</div>

				<div className="cinmacard" onClick={() => handleCinemaSelection("InCinemaFB")}>
					<div className="icons">
						<img src={atcinema} width={80} height={80} alt="At Cinema Icon" />
					</div>
					<p>{loadingAtCinema ? "Loading..." : "I'm at Cinema"}</p>
				</div>

				<div className="cinmacard" onClick={() => handleCinemaSelection("OnlyFB")}>
					<div className="icons">
						<img src={notatcinema} width={80} height={80} alt="Not at Cinema Icon" />
					</div>
					<p>{loadingNotAtCinema ? "Loading..." : "I'm not at Cinema"}</p>
				</div>
			</div>

			{/* Location Modal */}
			<Modal
				className={currentClasses.locationModal}
				show={showLocationModal}
				onHide={() => handleLocationModalClose()}
				animation={false}
				keyboard={false}
				style={{ backgroundColor: "rgba(0,0,0,0.7)", overflow: "hidden" }}
			>
				<img src={enablelocation} alt="Location" />
				<h3>
					Turn on precise location to help us give you the best experience
				</h3>
				<p>We will keep the battery usage to a minimum</p>
				<div className="lcflx">
					<button
						className="cmnbtn"
						onClick={() => requestLocationPermission()}
					>
						Enable Location
					</button>
					<button
						className="cmnbtn skipbtn"
						onClick={() => handleLocationModalClose("skipbtn")}
					>
						Skip
					</button>
				</div>
			</Modal>

			{/* Error Alert */}
			<SweetAlertUtility
				type={alertDetails.type}
				title={alertDetails.title}
				message={alertDetails.message}
				show={alertDetails.show}
				onConfirm={hideAlert}
			/>
		</>
	);
};

export default CinemaInOut;
