import React, { useEffect, useRef, useState } from 'react'
import pen from '../../assets/food/pen.svg'
import refresh from '../../assets/food/refresh.svg'
import cooking from '../../assets/food/cooking.gif'
import backBtn from '../../assets/food/backBtnn.svg'
import EllGreen from '../../assets/food/EllipseGreen.svg'
import EllYellow from "../../assets/food/yellowellipse.svg";
import EllEmpty from "../../assets/food/ElliEmpty.svg";
import emoji from "../../assets/food/smileEmo.svg";
import vegIcon from "../../assets/food/vegcheckout.svg";
import nonvegcarticon from "../../assets/food/nonvegStatus.svg";
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import * as appConstants from "../../constants/index";
import expandIcon from "../../assets/food/expandSmall.svg";
import dassadLine from "../../assets/food/dass-line.svg";
import Navbar from '../common/navbar/Navbar'
import FbHeader from './component/FbHeader'
import { getResponsiveClasses } from './component/ResponsiveClasses'
import Footer from '../common/footer/Footer'


function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { weekday: "short", day: "2-digit", month: "short" };
  return date.toLocaleDateString("en-US", options);
}

function formatTime(timeString) {
  const date = new Date(timeString); // Use timeString passed as an argument
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12;
  const formattedHours = hours < 10 ? "0" + hours : hours;
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;

  return formattedTime; // Return the formatted time
}

const OrderStatus = () => {
  const [loading, setLoading] = useState(false);
  const [orderStatusLoading, setOrderStatusLoading] = useState(false);
  const [fnbData, setFnbData] = useState([]);
  const [pageErrMsg, setPageErrMsg] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  // const initialLoad = useRef(true);
  // const [timerExpired, setTimerExpired] = useState(false);
  const [remainingTime, setRemainingTime] = useState({
    minutes: 0,
    seconds: 0,
  });
  const [expand, setExpand] = useState(false);
  const navigate = useNavigate();

  const authToken = "Bearer " + (localStorage.getItem("token") || " ");
  const location = useLocation();

  let [searchParams, setSearchParams] = useSearchParams();

  const { currentClasses, getWindowWidth } = getResponsiveClasses();
  localStorage.removeItem("orid");
  const handleExpand = () => {
    setExpand(!expand);
  };

  //   const getOrderTargetTime = (bookingId) => {
  //     const orders =
  //       JSON.parse(localStorage.getItem("prepareMultiOrderStatus")) || [];
  //     const order = orders.find((o) => o.en_bid === bookingId);
  //     return order ? order.targetTime : null;
  //   };

  useEffect(() => {
    const handleBackNavigation = () => {
      if (location.pathname !== "/") {
        navigate("/?tab=food", { replace: true });
      }
    };

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    window.addEventListener("popstate", handleBackNavigation);
    window.history.pushState(null, "", window.location.href);
    return () => {
      window.removeEventListener("popstate", handleBackNavigation);
    };


  }, [navigate, location.pathname]);

  useEffect(() => {
    const { orderTakeOn, statusEndTime } = fnbData?.deliveryStatus || {};

    const targetTimeFromStorage = localStorage.getItem("targetTime");

    const parsedOrderTakeOn = orderTakeOn ? new Date(orderTakeOn).getTime() : null;
    const parsedStatusEndTime = targetTimeFromStorage || (statusEndTime ? new Date(statusEndTime).getTime() : null);

    if (!parsedStatusEndTime || (parsedOrderTakeOn && parsedOrderTakeOn >= parsedStatusEndTime)) {
      setRemainingTime({ minutes: 0, seconds: 0 });
      return;
    }

    if (!targetTimeFromStorage) {
      localStorage.setItem("targetTime", new Date(parseInt(parsedStatusEndTime)));
    }

    const updateRemainingTime = () => {
      const currentTime = new Date().getTime();
      const difference = new Date(parsedStatusEndTime).getTime() - currentTime;

      if (difference <= 0) {
        setRemainingTime({ minutes: 0, seconds: 0 });
        localStorage.setItem("remainingTime", JSON.stringify({ minutes: 0, seconds: 0 }));
        localStorage.removeItem("targetTime");
        clearInterval(intervalId);
      } else {
        const minutes = Math.floor(difference / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
        setRemainingTime({ minutes, seconds });
        localStorage.setItem("remainingTime", JSON.stringify({ minutes, seconds }));
      }
    };

    const intervalId = setInterval(updateRemainingTime, 1000);
    updateRemainingTime();

    return () => clearInterval(intervalId);
  }, [fnbData]);

  useEffect(() => {
    const bookingId = searchParams.get("bookingid");

    if (!bookingId || !fnbData?.deliveryStatus?.orderStatus) return;

    const orders =
      JSON.parse(localStorage.getItem("prepareMultiOrderStatus")) || [];

    const updatedOrders = orders.map((order) =>
      order.en_bid === bookingId
        ? {
          ...order,
          currentStatus: fnbData.deliveryStatus.orderStatus,
          deliveryMode: fnbData.otherDetails?.deliveryMode || order.deliveryMode,
        }
        : order
    );

    localStorage.setItem("prepareMultiOrderStatus", JSON.stringify(updatedOrders));
  }, [
    searchParams,
    fnbData?.deliveryStatus?.orderStatus,
    fnbData?.otherDetails?.deliveryMode,
  ]);


  const steps = [
    { id: "order received", label: "Order Received" },
    { id: "order confirmed", label: "Order Confirmed" },
    { id: "preparing your order", label: "Preparing Your Order" },
    { id: "ready for pickup", label: "Ready for Pickup" },
    { id: "delivered", label: "Delivered" },
  ];

  localStorage.setItem("remainingTime", JSON.stringify(remainingTime));
  const autoRefreshTime = fnbData?.deliveryStatus ? fnbData?.deliveryStatus?.autoRefreshTime + "000" : 45000;
  const orderGetitems = JSON.parse(localStorage.getItem("prepareMultiOrderStatus")) || [];
  const onSeat = fnbData?.otherDetails?.deliveryMode === "ONSEAT";

  const handleRefreshClick = async (event) => {
    event.preventDefault();
    if (onSeat && !isDisabled && fnbData?.deliveryStatus?.orderStatus !== "Delivered" && orderGetitems.length > 0) {
      setIsRefreshing(true);
      setIsDisabled(true);
      try {
        await OrderStatusFnB();
      } catch (error) {
        console.error("Error refreshing order status:", error);
      } finally {
        setTimeout(() => setIsRefreshing(false), 400);
        setTimeout(() => setIsDisabled(false), autoRefreshTime);
      }
    }
  };

  const OrderStatusFnB = async () => {
    try {
      setOrderStatusLoading(true);
      const bookingId = searchParams.get("bookingid");
      const orderId = searchParams.get("code");
      const response = await axios.post(`${appConstants.base_url}v1/fnb/fnbDeliveryStatus`,
        {
          bookingId,
          code: orderId,
        },
        {
          headers: {
            Authorization: authToken,
            "Content-Type": "application/json",
            chain: localStorage.getItem("theme") === "inoxTheme" ? "INOX" : "PVR",
            appVersion: appConstants.av,
            platform: appConstants.pt,
            country: appConstants.cname,
          },
        }
      );
      if (response.data.result === "success") {
        setFnbData(response.data.output);
      } else {
        console.error(response.data.msg);
        setPageErrMsg("Booking Not Found!");
      }
    } catch (error) {
      console.error("Error fetching FnB data:", error);
    } finally {
      setOrderStatusLoading(false);
    }
  };

  // Clean up expired orders
  useEffect(() => {
    const cleanupOrders = () => {
      const currentTime = new Date().getTime();
      const orders =
        JSON.parse(localStorage.getItem("prepareMultiOrderStatus")) || [];
      const validOrders = orders.filter((order) => {
        const targetTime = new Date(order.targetTime).getTime();
        return targetTime > currentTime;
      });

      localStorage.setItem(
        "prepareMultiOrderStatus",
        JSON.stringify(validOrders)
      );
    };

    cleanupOrders(); // Initial cleanup
    const cleanupInterval = setInterval(cleanupOrders, 60000);
    return () => clearInterval(cleanupInterval);
  }, []);

  // after 45sec api call
  useEffect(() => {
    let interval;
    const fetchData = async () => {
      const currentStatus = fnbData?.deliveryStatus && fnbData?.deliveryStatus?.orderStatus?.toLowerCase();
      const onSeat = fnbData?.otherDetails && fnbData?.otherDetails?.deliveryMode === "ONSEAT";
      localStorage.setItem("onSeatType", onSeat);

      if (currentStatus !== "delivered" && onSeat && orderGetitems.length > 0) {
        await OrderStatusFnB();
        interval = setInterval(() => {
          OrderStatusFnB();
        }, autoRefreshTime);
      }
    };

    fetchData();

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [fnbData?.deliveryStatus?.orderStatus, fnbData?.otherDetails?.deliveryMode, fnbData?.deliveryStatus?.autoRefreshTime]);

  useEffect(() => {
    const bookingId = searchParams.get("bookingid");
    if (!bookingId) return;

    // const initializeTimer = () => {
    //   const orders = JSON.parse(localStorage.getItem("prepareMultiOrderStatus")) || [];
    //   let targetTime;

    //   // Get orderTakeOn and statusEndTime from fnbData
    //   const { orderTakeOn, statusEndTime } = fnbData?.deliveryStatus || {};

    //   if (!orderTakeOn || !statusEndTime) {
    //     return;
    //   }

    //   const existingOrder = orders.find((order) => order.en_bid === bookingId);

    //   // if (existingOrder) {
    //   //   targetTime = new Date(existingOrder.targetTime);
    //   // }

    //   if (existingOrder) {
    //     // Update the existing order with the latest data from the API
    //     existingOrder.targetTime = targetTime?.toISOString();
    //     existingOrder.orderTaken = new Date(orderTakeOn)?.toISOString();
    //     existingOrder.currentStatus = fnbData?.deliveryStatus?.orderStatus || null;
    //     existingOrder.deliveryMode = fnbData?.otherDetails?.deliveryMode || null;
    //     existingOrder.foodItems = fnbData?.otherDetails?.foods || null;

    //     const updatedOrders = orders.map((order) =>
    //       order.en_bid === bookingId ? existingOrder : order
    //     );
    //     localStorage.setItem("prepareMultiOrderStatus", JSON.stringify(updatedOrders));
    //   } else {
    //     // Parse targetTime from statusEndTime
    //     targetTime = new Date(statusEndTime);

    //     // Save new order to localStorage
    //     const newOrder = {
    //       en_bid: bookingId,
    //       bookingId: fnbData?.otherDetails?.bookingId || null,
    //       targetTime: targetTime.toISOString(),
    //       orderTaken: new Date(orderTakeOn).toISOString(),
    //       currentStatus: fnbData?.deliveryStatus?.orderStatus || null,
    //       deliveryMode: fnbData?.otherDetails?.deliveryMode || null,
    //       foodItems: fnbData?.otherDetails?.foods || null,
    //     };

    //     localStorage.setItem("prepareMultiOrderStatus", JSON.stringify([...orders, newOrder]));
    //   }
    //   let timerInterval;
    //   // Update timer immediately and start interval
    //   const updateTimer = () => {
    //     const currentTime = Date.now();
    //     const timeRemaining = targetTime?.getTime() - currentTime;

    //     if (timeRemaining <= 0) {
    //       setRemainingTime({ minutes: 0, seconds: 0 });

    //       // Remove expired order
    //       const updatedOrders = orders.filter((order) => order.en_bid !== bookingId);
    //       localStorage.setItem("prepareMultiOrderStatus", JSON.stringify(updatedOrders));

    //       clearInterval(timerInterval);
    //     } else {
    //       const minutes = Math.floor(timeRemaining / (1000 * 60));
    //       const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
    //       setRemainingTime({ minutes, seconds });
    //     }
    //   };

    //   // Start the timer
    //   updateTimer();
    //   timerInterval = setInterval(updateTimer, 1000);

    //   // Cleanup interval on unmount
    //   return () => clearInterval(timerInterval);
    // };

    const initializeTimer = () => {
      const orders = JSON.parse(localStorage.getItem("prepareMultiOrderStatus")) || [];
      let targetTime;

      // Get orderTakeOn and statusEndTime from fnbData
      const { orderTakeOn, statusEndTime } = fnbData?.deliveryStatus || {};

      if (!orderTakeOn || !statusEndTime) {
        console.error("Missing orderTakeOn or statusEndTime in fnbData.");
        return;
      }

      const existingOrder = orders.find((order) => order.en_bid === bookingId);

      if (existingOrder) {
        // Update the existing order with the latest data from the API
        targetTime = new Date(statusEndTime); // Always get fresh targetTime from API
        existingOrder.targetTime = targetTime.toISOString();
        existingOrder.orderTaken = new Date(orderTakeOn).toISOString();
        existingOrder.currentStatus = fnbData?.deliveryStatus?.orderStatus || null;
        existingOrder.deliveryMode = fnbData?.otherDetails?.deliveryMode || null;
        existingOrder.foodItems = fnbData?.otherDetails?.foods || null;

        // Update the order in localStorage
        const updatedOrders = orders.map((order) =>
          order.en_bid === bookingId ? existingOrder : order
        );
        localStorage.setItem("prepareMultiOrderStatus", JSON.stringify(updatedOrders));
      } else {
        // Parse targetTime from statusEndTime
        targetTime = new Date(statusEndTime);

        // Save new order to localStorage
        const newOrder = {
          en_bid: bookingId,
          bookingId: fnbData?.otherDetails?.bookingId || null,
          targetTime: targetTime.toISOString(),
          orderTaken: new Date(orderTakeOn).toISOString(),
          currentStatus: fnbData?.deliveryStatus?.orderStatus || null,
          deliveryMode: fnbData?.otherDetails?.deliveryMode || null,
          foodItems: fnbData?.otherDetails?.foods || null,
        };

        localStorage.setItem("prepareMultiOrderStatus", JSON.stringify([...orders, newOrder]));
      }

      let timerInterval;

      // Update timer immediately and start interval
      const updateTimer = () => {
        if (!targetTime || isNaN(targetTime.getTime())) {
          console.error("Invalid targetTime:", targetTime);
          clearInterval(timerInterval);
          setRemainingTime({ minutes: 0, seconds: 0 });
          return;
        }

        const currentTime = Date.now();
        const timeRemaining = targetTime.getTime() - currentTime;

        if (timeRemaining <= 0) {
          setRemainingTime({ minutes: 0, seconds: 0 });

          // Remove expired order
          const updatedOrders = orders.filter((order) => order.en_bid !== bookingId);
          localStorage.setItem("prepareMultiOrderStatus", JSON.stringify(updatedOrders));

          clearInterval(timerInterval);
        } else {
          const minutes = Math.floor(timeRemaining / (1000 * 60));
          const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
          setRemainingTime({ minutes, seconds });
        }
      };

      // Start the timer
      updateTimer();
      timerInterval = setInterval(updateTimer, 1000);

      // Cleanup interval on unmount
      return () => clearInterval(timerInterval);
    };
    const cleanup = initializeTimer();
    return () => {
      if (cleanup) cleanup();
    };
  }, [searchParams, fnbData]);

  useEffect(() => {
    const initialData = async () => {
      setLoading(true);
      try {
        await OrderStatusFnB();
      } catch (error) {
        console.error("Error fetching order status:", error);
      } finally {
        setLoading(false);
      }
    };

    initialData();
  }, []);  // 
  const getCurrentStepIndex = () => {
    const currentStatus = fnbData?.deliveryStatus?.orderStatus?.toLowerCase();
    const index = steps.findIndex((step) => step.id === currentStatus);

    if (index === -1) return 0;
    // If current status is 'order confirmed', return the next step index
    // return currentStatus === "order confirmed"
    return currentStatus
      ? Math.min(index + 1, steps.length - 1)
      : index;
  };
  const getCompletedStepIndex = () => {
    const currentStatus = fnbData?.deliveryStatus?.orderStatus?.toLowerCase();
    return steps.findIndex((step) => step.id === currentStatus);
  };

  const getStepIcon = (index) => {
    const currentIndex = getCurrentStepIndex();
    const completedIndex = getCompletedStepIndex();

    if (index <= completedIndex) return EllGreen;
    if (index === currentIndex) return EllYellow;
    return EllEmpty;
  };

  const isStepActive = (index) => {
    const currentIndex = getCurrentStepIndex();
    const completedIndex = getCompletedStepIndex();
    return index <= Math.max(currentIndex, completedIndex);
  };

  const getCurrentStatusText = () => {
    const currentIndex = getCurrentStepIndex();
    return steps[currentIndex].label;
  };

  const getStepTime = (index) => {
    if (!fnbData?.deliveryStatus) return "";
    const { orderTakeOn, startedOn, readyOn, deliveredOn } = fnbData.deliveryStatus;

    switch (index) {
      case 0:
      case 1:
        return formatTime(orderTakeOn);
      case 2:
        return startedOn ? formatTime(startedOn) : "";
      case 3:
        return readyOn ? formatTime(readyOn) : "";
      case 4:
        return deliveredOn ? formatTime(deliveredOn) : "";
      default:
        return "";
    }
  };

  useEffect(() => {
    const handlePopState = (event) => {
      if (!window.location.search.includes("?tab=food")) {
        navigate("/?tab=food", { replace: true });
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  return (
    <>
      {getWindowWidth &&
        <div className="bg-white show-in-desktop-view" style={{ position: "sticky", top: "0", zIndex: "999" }}>
          <Navbar />
        </div>
      }
      <div className={currentClasses.fnbdeskdiv}>
        <div className={currentClasses.fnbdeskdivInn}>
          <div className="fb-os-main">
            <style>
              {`
								@keyframes spin {
									0% { transform: rotate(0deg); }
									100% { transform: rotate(360deg); }
								}
								.refreshing {
									animation: spin 1s linear infinite;								
								}
								.refresh-complete {
									animation: spin 0.4s linear 1;
								}
								.disabled {
									opacity: 0.5;
									cursor: not-allowed;
								}
							`}
            </style>
            {loading ? (
              <div className="loading-messagefnb">
                <h2>Loading...</h2>
              </div>
            ) : (
              <>
                <FbHeader headname="Order Status" addressShow={false} filterIndex={location?.state?.filterIndex} />
                <div className="fb-os-inn">
                  <div className="fb-os-coIDiv">
                    <img className="fb-os-coImg" src={cooking} alt="" />
                  </div>
                  <div className="fb-os-about">
                    {pageErrMsg ? (
                      <div className="nodatamsg">{pageErrMsg}</div>
                    ) : fnbData?.otherDetails ? (
                      <>
                        <p className="fb-os-oi">
                          Booking ID:
                          <span> {fnbData?.otherDetails?.bookingId}</span>
                        </p>
                        {
                          <>
                            {/* {isRefreshing && <p>Refreshing order status...</p>} */}
                            <div
                              className={`fb-os-ref ${isDisabled ? "disabled" : ""
                                }`}
                              style={{
                                cursor: isDisabled ? "default" : "pointer",
                              }}
                              onClick={handleRefreshClick}
                            >
                              {onSeat ? (
                                <p>Deliver to Seat</p>
                              ) : (
                                <p>Pickup from Counter</p>
                              )}
                              {onSeat && fnbData?.deliveryStatus?.orderStatus !== "Delivered" && localStorage.getItem("targetTime") && (
                                <img
                                  src={refresh}
                                  alt="Refresh"
                                  className={
                                    isRefreshing
                                      ? "refreshing"
                                      : "refresh-complete"
                                  }
                                />
                              )}
                            </div>

                            {onSeat ? (

                              <>
                                <div className="fb-os-seat">
                                  <p className="fb-os-audi">
                                    Audi Name:{" "}
                                    <span>{fnbData?.otherDetails?.audi}</span>
                                  </p>
                                  <p className="fb-os-audi">
                                    Seat No:{" "}
                                    <span>{fnbData?.otherDetails?.seats}</span>
                                  </p>
                                </div>
                                <div className="fb-os-seat">
                                  <p className="fb-os-pickup">
                                    Order Date: <br />
                                    <span>
                                      {formatDate(fnbData?.otherDetails?.showTime)}
                                    </span>
                                  </p>
                                  <p className="fb-os-pickup" style={{ textAlign: "right" }}>
                                    Order Time:<br />
                                    <span>
                                      {formatTime(
                                        fnbData?.otherDetails?.showTime
                                      )}
                                    </span>
                                  </p>
                                </div>
                              </>
                            ) : (
                              <div className="fb-os-seat">
                                <p className="fb-os-pickup">
                                  Pick-up Date: <br />
                                  <span>
                                    {formatDate(fnbData?.otherDetails?.showTime)}
                                  </span>
                                </p>
                                <p className="fb-os-pickup" style={{ textAlign: "right" }}>
                                  Pick-up Time:<br />
                                  <span>
                                    {formatTime(
                                      fnbData?.otherDetails?.showTime
                                    )}
                                  </span>
                                </p>
                              </div>
                            )}
                            {onSeat && fnbData?.deliveryStatus?.orderStatus !== "Delivered" && localStorage.getItem("targetTime") && (
                              <>
                                <div className="preparTime">
                                  <div className="fb-os-pt-img">
                                    <img src={pen} alt="" />
                                    <p className="fb-pyo">
                                      {getCurrentStatusText()}
                                    </p>
                                  </div>
                                  <div className="rem-time">
                                    <p>
                                      {remainingTime.minutes
                                        .toString()
                                        .padStart(2, "0")}
                                      :
                                      {remainingTime.seconds
                                        .toString()
                                        .padStart(2, "0")}
                                    </p>
                                  </div>
                                </div>
                                {orderStatusLoading ? (
                                  <div className="loading-section">
                                    <h2>Loading...</h2>
                                  </div>
                                ) : (
                                  <div className="ste-fb-dyn">
                                    <div className="vertical-stepper">
                                      {steps.map((step, index) => (
                                        <div
                                          key={index}
                                          className={`step ${isStepActive(index) ? "active" : ""
                                            }`}
                                        >
                                          <div className="Fnbstep-icon">
                                            <img
                                              src={getStepIcon(index)}
                                              alt=""
                                            />
                                          </div>
                                          <div
                                            style={{
                                              color: isStepActive(index)
                                                ? "#000"
                                                : "#888",
                                            }}
                                            className={`step-label ${isStepActive(index)
                                              ? "active"
                                              : ""
                                              }`}
                                          >
                                            {step.label}
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                    <div className="step-times">
                                      {steps.map((_, index) => (
                                        <p
                                          style={{
                                            color: isStepActive(index)
                                              ? "#000"
                                              : "#888",
                                            fontWeight: isStepActive(index)
                                              ? "500"
                                              : "normal",
                                          }}
                                          key={index}
                                        >
                                          {getStepTime(index)}
                                        </p>
                                      ))}
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        }

                        <div className="fb-os-item">
                          <p
                            style={{ marginBottom: "10px" }}
                            className="fb-os-i-cinIP"
                          >
                            ORDER Summary
                          </p>
                          {fnbData?.otherDetails?.foods?.map((item) => (
                            <React.Fragment key={item.itemId}>
                              <div className="fb-os-i-outer">
                                <div className="fb-os-i-innstatus">
                                  {item?.veg ? (
                                    <svg
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#149752" />
                                      <circle cx="6.99984" cy="6.99935" r="4.08333" fill="#149752" />
                                    </svg>
                                  ) : (
                                    <svg
                                      width="14"
                                      height="14"
                                      viewBox="0 0 12 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect x="0.961426" y="0.964844" width="10.0769" height="10.0769" rx="1.5" stroke="#87001B" />
                                      <path d="M9 8.80886H3L6.00011 3.18945L9 8.80886Z" fill="#87001B" />
                                    </svg>
                                  )}

                                  <div>
                                    <p className="fnbsta-iname">
                                      {item?.name?.toLowerCase()} x {item?.quantity}
                                    </p>

                                    {item?.addons?.length > 0 && (
                                      <em className="fnb-c-addons">
                                        Add ons:
                                        {item?.addons?.map((addon, index) => (
                                          <p className="cart-addonP" key={`addon-${index}`}>
                                            <span>
                                              {addon?.name?.toLowerCase()} x {addon?.quantity}
                                            </span>
                                            <span className="os-statusAdd">
                                              ₹{((Number(addon?.price) * addon?.quantity) / 100).toFixed(2)}
                                            </span>
                                          </p>
                                        ))}
                                      </em>
                                    )}
                                  </div>
                                </div>

                                <div className="os-itemprice">
                                  ₹{((item?.price * item?.quantity) / 100).toFixed(2)}
                                </div>
                              </div>

                              {/* Upsell Items */}
                              {/* {item?.upsell?.map((upsell, index) => (
                                <div key={`upsell-${item.itemId}-${index}`} className="fb-os-i-outer">
                                  <div className="fb-os-i-innstatus">
                                    {item?.veg ? (
                                      <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke="#149752" />
                                        <circle cx="6.99984" cy="6.99935" r="4.08333" fill="#149752" />
                                      </svg>
                                    ) : (
                                      <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 12 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect x="0.961426" y="0.964844" width="10.0769" height="10.0769" rx="1.5" stroke="#87001B" />
                                        <path d="M9 8.80886H3L6.00011 3.18945L9 8.80886Z" fill="#87001B" />
                                      </svg>
                                    )}

                                    <div>
                                      <p className="fnbsta-iname">
                                        {upsell?.name} x {upsell?.quantity}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="os-itemprice">
                                    ₹{((Number(upsell?.price) * upsell?.quantity) / 100).toFixed(2)}
                                  </div>
                                </div>
                              ))} */}
                            </React.Fragment>
                          ))}



                          <img
                            // style={{ marginTop: "10px" }}
                            src={dassadLine}
                            alt=""
                          />
                          {/* <div
                            style={{ marginTop: "5px" }}
                            className="fb-stdivI"
                          >
                            <p
                              style={{
                                textDecoration: "underline",
                                textUnderlineOffset: "3px",
                                margin: "3px 0px",
                              }}
                              onClick={handleExpand}
                              className="fb-stp"
                            >
                              Taxes & Fees{" "}
                              <img
                                style={{
                                  transform:
                                    expand === true
                                      ? "rotate(180deg)"
                                      : "rotate(0deg)",
                                  transition: "ease 0.3s",
                                }}
                                src={expandIcon}
                              />
                            </p>
                            <p style={{ margin: "3px 0px" }} className="fb-stp">
                              {fnbData
                                ? `₹${(
                                  (parseInt(fnbData?.otherDetails?.cgst) +
                                    parseInt(fnbData?.otherDetails?.sgst)) /
                                  100
                                )?.toFixed(2)}`
                                : "-"}
                            </p>
                          </div>

                          {expand && (
                            <div className="fb-stdivI">
                              <div>
                                <p
                                  style={{ marginBottom: "8px" }}
                                  className="fb-stpExp"
                                >
                                  CGST :
                                </p>
                                <p
                                  style={{ marginBottom: "8px" }}
                                  className="fb-stpExp"
                                >
                                  SGST :
                                </p>
                              </div>
                              <div>
                                <p className="fb-stpExp">
                                  {fnbData
                                    ? `₹${(
                                      Number(fnbData?.otherDetails?.cgst) /
                                      100
                                    )?.toFixed(2)}`
                                    : "-"}
                                </p>
                                <p className="fb-stpExp">
                                  {fnbData
                                    ? `₹${(
                                      Number(fnbData?.otherDetails?.sgst) /
                                      100
                                    )?.toFixed(2)}`
                                    : "-"}
                                </p>
                              </div>
                            </div>
                          )} */}
                          <div
                            style={{ paddingTop: 5, marginTop: 10 }}
                            className="fb-os-i-outer"
                          >
                            <p className="fb-os-i-subamt">Sub Total</p>
                            <p
                              style={{ fontWeight: 600 }}
                              className="fb-os-i-subamt"
                            >
                              ₹{((fnbData?.otherDetails?.fbTotalPrice + fnbData?.otherDetails?.discount) / 100)?.toFixed(2)}
                            </p>
                          </div>
                          {fnbData?.otherDetails?.discount > 0 && (
                            <div className="fb-stdiv4 b1">
                              <p className="fb-stpII">Discount</p>
                              <p className="fb-stpII2">
                                -₹{(fnbData?.otherDetails?.discount / 100).toFixed(2)}
                              </p>
                            </div>
                          )}
                          <div
                            style={{
                              paddingBottom: 5,
                              marginBottom: 10,
                            }}
                            className="fb-os-i-outer"
                          >
                            <p className="fb-os-i-amt">Amount Paid</p>
                            <p
                              style={{ fontWeight: 600 }}
                              className="fb-os-i-amt"
                            >
                              ₹{(fnbData?.otherDetails?.fbTotalPrice / 100)?.toFixed(2)}
                            </p>
                          </div>
                        </div>
                        <div className="fb-os-i-cin">
                          <div className="fb-os-i-cinInn">
                            <p className="fb-os-i-cinIP">Cinema</p>
                            <p className="fb-os-i-cinIAdd">
                              {fnbData?.otherDetails?.theaterName}
                            </p>
                          </div>
                          <div className="fb-os-i-cinIOut">
                            <div
                              style={{ border: "none" }}
                              className="fb-os-i-cin"
                            >
                              <p className="fb-os-i-cinIP">Payment Mode</p>
                              <p className="fb-os-i-cinIAdd">
                                {
                                  fnbData?.otherDetails?.payMode
                                    ?.split(",")
                                    .pop()
                                    .split(":")[0]
                                }
                              </p>
                            </div>
                            <div
                              className="fb-os-i-cin"
                              style={{ border: "none" }}
                            >
                              <p
                                style={{ textAlign: "right" }}
                                className="fb-os-i-cinIP"
                              >
                                Date
                              </p>
                              <p className="fb-os-i-cinIAdd">
                                {formatDate(fnbData?.otherDetails?.createdAt)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="nodatamsg">Booking Not Found!</div>
                    )}
                  </div>
                </div>
                <div className="fb-pno-btns">
                  <button
                    onClick={() => navigate("/?tab=food")}
                    className="fb-pno-btn"
                  >
                    Place New Order
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {getWindowWidth &&
        <Footer />
      }
    </>
  );
}

export default OrderStatus