import React, { useEffect, useRef, useCallback, useState } from "react";
import jsQR from "jsqr";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FbHeader from "./component/FbHeader";
import * as appConstants from "../../constants/index";
import backBtn from "../../assets/food/backBtnn.svg";
import axios from "axios";
import debounce from "lodash.debounce";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { setLoginViewMode } from "src/redux/actions/loginViewAction";
import SweetAlertUtility from "./component/SweetAlertUtility";
import Login from "../common/login/Login";
import { Dialog } from "primereact/dialog";

const QrScanner = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const streamRef = useRef(null); // Store the stream to close it easily
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [alertDetails, setAlertDetails] = useState({
    show: false,
    type: "",
    title: "",
    message: "",
  });

  const authToken = localStorage.getItem("token") || " ";
  const loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
  const dispatch = useDispatch();
  const capitalizeFirstLetter = (string) => string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : "";
  localStorage.removeItem("cartItems");

  const getdata = async (url) => {
    try {
      if (!navigator.onLine) {
        sweetAlertError("No internet connection. Please check your network.");
        return;
      }
      url = url; //"https://www.pvrcinemas.com/food/mumbai/qr/200-N-433-A-1";

      const urlParts = url.split("/");
      if (urlParts.length !== 7) {
        sweetAlertError("Invalid QR code, Please try again");
        return;
      }

      const urlCity = capitalizeFirstLetter(urlParts[4]) || "";

      if (urlCity !== localStorage.getItem("city")) {
        localStorage.setItem("city", urlCity);
      }

      let lastPart;

      if (urlParts[6].includes("?")) {
        lastPart = urlParts[6].split("?")[0].split("-");
      } else {
        lastPart = urlParts[6].split("-");
      }

      if (!lastPart || lastPart.length === 0) {
        sweetAlertError("Failed to scan QR code");
        return;
      }
      if (loading) return;

      setLoading(true);
      const requestBody = {
        city: urlCity,
        cid: lastPart[0] || "",
        lat: localStorage.getItem("lat") || "0.00",
        lng: localStorage.getItem("long") || "0.00",
        dated: "NA",
        qr: "YES",
        cineType: "",
        cineTypeQR: "",
      };

      const response = await axios.post(
        `${appConstants.base_url}v1/booking/content/csessions`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
            chain:
              localStorage.getItem("theme") === "inoxTheme" ? "INOX" : "PVR",
            country: appConstants.cname,
            appVersion: appConstants.av,
            platform: appConstants.pt,
            city: urlCity,
          },
        }
      );

      const resData = response?.data?.output;
      if (response?.data?.result !== "success" || !resData?.cinemaRe) {
        sweetAlertError("Something went wrong!");
        console.error("API not working!");
        return;
      }

      const cinemaExp = resData.cinemaMovieSessions.flatMap(
        (item) =>
          item.experienceSessions?.flatMap((expritems) =>
            Array.isArray(expritems?.shows)
              ? expritems.shows.flatMap((screenitem) => screenitem)
              : []
          ) || []
      );

      const movieExp = resData.cinemaMovieSessions.flatMap(
        (item) => item.movieRe || []
      );
      //experienceSessions json
      const currentTime = new Date().getTime();
      // currentTime.setHours(15, 0, 0, 0);
      const cinemaExpData = cinemaExp?.find((item) =>
        currentTime > item.showTimeStamp &&
        currentTime < item.endTimeStamp &&
        item.screenId?.toString() === lastPart[2]
      );

      // Extract screenName and filmId from the found cinema experience
      const screenName = cinemaExpData?.screenName || null;
      const filmId = cinemaExpData?.movieId || null;

      // Find the corresponding movie data using the filmId
      const movieIdData = movieExp?.find((item) =>
        item.filmIds?.some((filmIdValue) => filmIdValue?.toString() === filmId)
      );

      // Extract filmName and imgUrl from the movie data
      const filmName = movieIdData?.filmName || null;
      const imgUrl = movieIdData?.miv || null;

      const queryPart = urlParts[6].split("?")[1]?.split("=")[1];
      const mainPart = lastPart[1];
      const screenType =
        (queryPart &&
          (queryPart.includes("GOLD") ||
            queryPart.includes("LUXE") ||
            queryPart.includes("INSIGNIA") ||
            queryPart.includes("DIRECTOR")))
          ? queryPart
          : (mainPart &&
            (mainPart.includes("GOLD") ||
              mainPart.includes("LUXE") ||
              mainPart.includes("INSIGNIA") ||
              mainPart.includes("DIRECTOR")))
            ? mainPart
            : "N";

      const TransactionType = "InCinemaFB"; //screenType !== "N" ? "OnlyFB" : "InCinemaFB";
      const qrScanData = {
        filmName: (lastPart[2] && filmName) || "",
        showTime: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        pickUpDate: (cinemaExpData && `${cinemaExpData?.showDate}T${cinemaExpData?.showTime}`) || "",
        movieImageUrl: (lastPart[2] && imgUrl) || "",
        filmId: (lastPart[2] && filmId) || "",
        screenName: (lastPart[2] && screenName) || "",
        screenId: lastPart[2] || "",
        screenType: screenType,
        seatrow: lastPart[3] || "",
        seatno: lastPart[4] || "",
        fnb_qr: "YES",
      };

      if (lastPart.length >= 3) {
        localStorage.setItem("audi", qrScanData.screenName);
      }
      if (lastPart.length >= 5) {
        localStorage.setItem("seatrow", qrScanData.seatrow);
        localStorage.setItem("seatno", qrScanData.seatno);
      }

      localStorage.setItem("theaterId", resData.cinemaRe.theatreId);
      localStorage.setItem("TheatreId", resData.cinemaRe.theatreId);
      localStorage.setItem("name", resData.cinemaRe.name);
      localStorage.setItem("TransactionType", TransactionType);
      localStorage.setItem("fnb_qr", qrScanData.fnb_qr);
      localStorage.setItem("booking_type", "FOOD");
      localStorage.setItem("selectedTheater", JSON.stringify(resData.cinemaRe));
      localStorage.setItem("seatdetails", JSON.stringify(qrScanData));
      localStorage.setItem("Experience", lastPart[1]);

      navigate("/select-food");
    } catch (error) {
      if (error?.response?.status === 403) {
        dispatch(setLoginViewMode({ value: true }));
        setShowLoginModal(true);
      }

      console.error("Error fetching FnB data:", error);
      sweetAlertError("Error occurred while fetching data. Please try again.");
    } finally {
      setLoading(false);
      closeStream(); // Ensure stream is closed upon data fetch completion
    }
  };
  // useEffect(() => {
  // 	getdata();
  // }, [])
  const qrScannerFunc = async (decodedText) => {
    if (!decodedText) {
      sweetAlertError("Invalid QR code");
      return;
    }
    getdata(decodedText);
  };

  const onScanSuccess = useCallback(
    debounce((decodedText) => {
      qrScannerFunc(decodedText);
    }, 100),
    []
  );

  const initScanner = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d", { willReadFrequently: true });

    navigator?.mediaDevices
      ?.getUserMedia({
        video: {
          facingMode: "environment",
          width: 640,
          height: 480,
          frameRate: { ideal: 10 },
        },
      })
      .then((stream) => {
        streamRef.current = stream; // Save the stream reference for cleanup
        video.srcObject = stream;
        video.setAttribute("playsinline", true);
        video.play();
        requestAnimationFrame(tick);
      })
      .catch((err) => {
        console.error("Failed to start camera:", err);
        sweetAlertError("Please allow the app to access your camera to scan the QR Code.");
      });

    let frameCount = 0;

    const tick = () => {
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        if (frameCount % 4 === 0) {
          canvas.height = video.videoHeight;
          canvas.width = video.videoWidth;
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
          const imageData = context.getImageData(
            0,
            0,
            canvas.width,
            canvas.height
          );
          const code = jsQR(imageData.data, imageData.width, imageData.height);

          if (code) onScanSuccess(code.data);
        }
        frameCount++;
      }
      requestAnimationFrame(tick);
    };
  };

  const closeStream = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }
  };
  useEffect(() => {
    if (location.pathname.includes("scan-qr")) {
      initScanner();
    } else {
      qrScannerFunc(window.location.href);
    }

    return () => {
      closeStream(); // Close stream when component unmounts
    };
  }, [dispatch]);

  const sweetAlertSuccess = (msg) => {
    setAlertDetails({
      show: true,
      type: "success",
      title: "Success!",
      message: msg,
    });
  };

  const sweetAlertError = (msg) => {
    setAlertDetails({
      show: true,
      type: "error",
      title: "Error!",
      message: msg,
    });
  };

  const hideAlert = () => {
    setAlertDetails({ show: false });
    navigate("/?tab=food")
  };

  const loginVewMode = useSelector((state) => state.loginViewMode.value);
  return (
    <>
      <FbHeader headname="Scan QR" myAccount={true} cartShow={false} />
      <div className="qrcode-container">
        <div className="qr-box">
          <video ref={videoRef} style={{ display: "none" }} />
          <canvas ref={canvasRef} id="reader" />
        </div>
        <p className="instruction">
          Experience our curated menu tailored just for you with our scan and
          dine service!
        </p>
      </div>

      {showLoginModal && (
        <Dialog
          header=""
          visible={loginVewMode}
          // modal={false}
          showHeader={false}
          showCloseIcon={false}
          style={{ width: "40vw" }}
          onHide={() => dispatch(setLoginViewMode({ value: false }))}
          className="register-mobile"
          blockScroll={true}
        >
          <div className="row">
            <Login />
          </div>
        </Dialog>
      )}
      <style>
        {`
					.qrcode-container {
					background-color: #000;
					color: #fff;
					min-height: 100vh;
					display: flex;
					flex-direction: column;
					align-items: center;
					padding: 20px;
					}

					.qr-box {
					width: 85vw;
					height: 435px;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-bottom: 20px;
					}

					.instruction {
					text-align: center;
					max-width: 90%;
					margin-bottom: 20px;
					font-size: 16px;
					}

					#reader {
					width: 100%;
					height: 100%;
					border: 3px solid silver !important;
					border-radius: 10px !important;
					}
				`}
      </style>

      <SweetAlertUtility
        type={alertDetails.type}
        title={alertDetails.title}
        message={alertDetails.message}
        show={alertDetails.show}
        onConfirm={hideAlert}
      />
    </>
  );
};

export default QrScanner;
